import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { LanguagePicker } from './LanguagePicker'
import { TermsCheckbox } from './TermsCheckbox'
import { authentication, theme } from '../../lib'
import { UserIcon, ArrowRightIcon } from '../icons'
import { Button, CountryPickerButton } from '../shared'
import { setAndSaveAppUrl } from '../../services'
import { WelcomePage_Locations, Common_LogIn, WelcomePage_Subtitle } from '../../translations/messages'

const isTest = window.location.host.includes('test')

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  background-color: var(--colors-secondary);
  padding: var(--spacing-xl);
  margin: 0 auto;

  > * {
    max-width: var(--max-page-width);
  }

  > h2 {
    text-align: center;
  }
`

const Logo = styled.img`
  max-width: 10rem;
  max-height: 6rem;
  margin-top: var(--spacing-xl);
`

const BrandingImage = styled.img`
  height: calc(50px + 20vh);
  margin: var(--spacing-md) 0;
`

const LanguagePickerContainer = styled.div`
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
`

const CountryPickerContainer = styled.div`
  position: absolute;
  top: var(--spacing-md);
  left: var(--spacing-md);
`

const LocationsLink = styled(Link)`
  text-align: center;
  font-weight: bold;
  color: black;
  text-decoration: underline;
  margin-top: var(--spacing-md);
`

export const WelcomePage = () => {
  const [easterEggClicks, setEasterEggClicks] = useState(1)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(Boolean(window.localStorage.getItem('hasAcceptedTerms')))

  const onBrandingImageClick = () => {
    setEasterEggClicks(easterEggClicks + 1)

    if (easterEggClicks % 15 === 0) {
      const isLegacyEnvDomain = !!window.location.host.match(/\w{2}(\.test|\.prod)/gi)

      if (window.confirm(`Switch to ${isTest ? 'prod' : 'test'} environment?`)) {
        if (isTest) {
          isLegacyEnvDomain
            ? setAndSaveAppUrl(window.location.host.replace('test', 'prod'))
            : setAndSaveAppUrl(window.location.host.replace('test.', ''))
        } else {
          isLegacyEnvDomain
            ? setAndSaveAppUrl(window.location.host.replace('prod', 'test'))
            : setAndSaveAppUrl(`test.${window.location.host}`)
        }
      }
    }
  }

  return (
    <Container className="animated fadeIn medium">
      {theme.showCountryPicker && (
        <CountryPickerContainer>
          <CountryPickerButton />
        </CountryPickerContainer>
      )}

      <LanguagePickerContainer>
        <LanguagePicker />
      </LanguagePickerContainer>

      <Logo src={theme.images.welcomePageLogo} alt="Tomra logo" />

      <BrandingImage onClick={onBrandingImageClick} src={theme.images.welcomePageBrandingElement} alt="welcome" />

      <h2>
        <FormattedMessage id={WelcomePage_Subtitle.id} defaultMessage={WelcomePage_Subtitle.defaultMessage} />
      </h2>

      <TermsCheckbox
        checked={hasAcceptedTerms}
        onChange={() => {
          setHasAcceptedTerms(!hasAcceptedTerms)
          window.localStorage.setItem('hasAcceptedTerms', String(!hasAcceptedTerms))
        }}
      />

      <Button disabled={!hasAcceptedTerms} onClick={authentication.login}>
        <UserIcon />
        <FormattedMessage id={Common_LogIn.id} defaultMessage={Common_LogIn.defaultMessage} />
        <ArrowRightIcon />
      </Button>

      <LocationsLink to="/locations">
        <FormattedMessage id={WelcomePage_Locations.id} defaultMessage={WelcomePage_Locations.defaultMessage} />
      </LocationsLink>
    </Container>
  )
}
