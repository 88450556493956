import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useLoaderData, Outlet } from 'react-router-dom'
import { Header } from './header/Header'
import { ContainerScannerButton } from './ContainerScannerButton'
import { ContainerScannerButtonNative } from './ContainerScannerButtonNative'
import { FindLocation } from './favoriteLocation/FindLocation'
import { MyLocation } from './favoriteLocation/MyLocation'
import { AustraliaCampaignCard } from './temporary/AustraliaCampaignCard'
import { ListButton } from '../shared'
import { sendGtagEvent, hasWebCameraScannerSupport } from '../../services'
import { VoucherIcon, RecycleBottleIcon, StarIcon, ArrowRightIcon, HeartIcon } from '../icons'
import { getItemFromWebStorage, VIEWER_COUNTRY_STATE } from '../../lib'
import {
  Common_Achievements,
  Common_Refunds,
  Common_Vouchers,
  Common_Charities,
  HomePage_Other
} from '../../translations/messages'
import { ScheduledDeletePage } from './ScheduledDeletePage'

const Container = styled.div`
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
    max-width: var(--max-page-width);
    margin-bottom: var(--spacing-lg);
  }
`

const SmallHeader = styled.small`
  display: block;
  margin-left: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
`

type LoaderData = {
  profile: ProfileRequestType
  payoutMethods: PayoutMethodsRequestType
}

export const HomePage = () => {
  const navigate = useNavigate()
  const { profile, payoutMethods } = useLoaderData() as LoaderData
  const [favoriteLocationId, setFavoriteLocationId] = useState('')

  useEffect(() => {
    getItemFromWebStorage('favoriteLocationId').then(setFavoriteLocationId)
  }, [])

  if (profile.scheduledDeleteAt) {
    return <ScheduledDeletePage scheduledDeleteAt={profile.scheduledDeleteAt} />
  }

  return (
    <>
      <Header barcode={profile.barcode} />

      <Container>
        {favoriteLocationId ? <MyLocation favoriteLocationId={favoriteLocationId} /> : <FindLocation />}

        <AustraliaCampaignCard />

        <div>
          <SmallHeader>
            <b>
              <FormattedMessage id={HomePage_Other.id} defaultMessage={HomePage_Other.defaultMessage} />
            </b>
          </SmallHeader>

          {payoutMethods?.some(method => method.type === 'DONATION') && (
            <ListButton
              onClick={() => {
                sendGtagEvent('ui_click', 'charity payout button')
                navigate('charities')
              }}
            >
              <div>
                <HeartIcon />
                <span>
                  <FormattedMessage id={Common_Charities.id} defaultMessage={Common_Charities.defaultMessage} />
                </span>
              </div>
              <ArrowRightIcon />
            </ListButton>
          )}

          <ListButton
            label={profile.numberOfUnredeemedVouchers || ''}
            labelColor="var(--colors-green)"
            onClick={() => navigate('vouchers')}
          >
            <div>
              <VoucherIcon />
              <span>
                <FormattedMessage id={Common_Vouchers.id} defaultMessage={Common_Vouchers.defaultMessage} />
              </span>
            </div>
            <ArrowRightIcon />
          </ListButton>

          <ListButton
            onClick={() => navigate('activity')}
            label={profile.hasPayoutError ? '1' : ''}
            labelColor="var(--colors-red)"
          >
            <div>
              <RecycleBottleIcon />
              <span>
                <FormattedMessage id={Common_Refunds.id} defaultMessage={Common_Refunds.defaultMessage} />
              </span>
            </div>
            <ArrowRightIcon />
          </ListButton>

          <ListButton onClick={() => navigate('achievements')}>
            <div>
              <StarIcon />
              <span>
                <FormattedMessage id={Common_Achievements.id} defaultMessage={Common_Achievements.defaultMessage} />
              </span>
            </div>
            <ArrowRightIcon />
          </ListButton>

          {VIEWER_COUNTRY_STATE === 'AU-NSW' && hasWebCameraScannerSupport() ? (
            <ContainerScannerButton />
          ) : (
            <ContainerScannerButtonNative />
          )}
        </div>
      </Container>

      <Outlet />
    </>
  )
}
