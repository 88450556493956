export const commonPasswords = [
  '1234567890',
  'qwertyuiop',
  'azertyuiop',
  'motdepasse',
  '1q2w3e4r5t',
  'alessandro',
  'realmadrid',
  'password123',
  '1234567890',
  '1111111111',
  'valentinchoque',
  'universidad',
  'timosha123',
  'tinkerbell',
  'supervisor',
  'soyhermosa',
  'slipknot666',
  'qosqomanta',
  'pukayaco14',
  'parisdenoia',
  'nomeacuerdo',
  'mypassword',
  'multimedia',
  'miguelangel',
  'liverpool1',
  'jesuschrist',
  'iknowyoucanreadthis',
  'ihavenopass',
  'hollister1',
  'hannover23',
  'elizabeth1',
  'controller',
  'chocolate1',
  'chocolate!',
  'bodhisattva',
  'bisounours',
  'basketball',
  'backupexec',
  'aspateso19',
  'administrator',
  'anthropogenic',
  'andromache',
  'atmosphere',
  'enterprise',
  'lamination',
  'oceanography',
  'polynomial',
  'rachmaninoff',
  'superstage',
  'temptation',
  'topography',
  'williamsburg',
  'yellowstone'
]
