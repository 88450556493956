import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { CharityDonationCard } from './common'
import styled from 'styled-components'
import { pushMessage } from '../AppMessages'
import {
  MyDonationsPage_LoadingUserDataFailed,
  MyDonationsPage_NotDonatedYet,
  MyDonationsPage_PreviousDonations,
  MyDonationsPage_YouHaveDonated
} from '../../translations/messages'
import { fetchMoreCharityUserData } from '../../services'
import { logError, theme } from '../../lib'
import { Currency, FeedbackMessage } from '../shared/common'
import { BrowseCampaignsLoader } from './common/BrowseCampaignsLoader'
import { useOnScrolledToBottom } from '../../hooks/useOnScrolledToBottom'

declare global {
  type Donation = {
    campaignId: string
    campaignName: string
    campaignLogoUrl: string
    charityDisplayName: string
    contributionSum: number
    benefit: string
    endTime: string
    status: 'NEW' | 'ACTIVE' | 'STOPPED' | 'EXPIRED'
  }
}

const Container = styled.div`
  padding: var(--spacing-md) var(--spacing-md) 0 var(--spacing-md);
  display: flex;
  flex-direction: column;
  max-width: var(--max-page-width);
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
`

const List = styled.ul`
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: var(--spacing-sm);
`

const ListHeader = styled.small`
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
  font-weight: bold;
`

const DonationsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-md);

  img {
    margin-right: var(--spacing-md);
    width: 9rem;
    height: 9rem;
  }
`

const DonationSum = styled.div`
  text-align: center;
`

const SmallBoldText = styled.small`
  font-weight: bold;
`

export const MyDonationsPage = () => {
  const {
    donations: initialDonations,
    pageEnd: initialPageEnd,
    totalDonationSum
  } = useLoaderData() as DonationsRequestType

  const [currentPageEnd, setCurrentPageEnd] = useState<number>(initialPageEnd)
  const [compoundedDonations, setCompoundedDonations] = useState(initialDonations)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useOnScrolledToBottom(async () => {
    if (!isLoadingMore && currentPageEnd !== null) {
      setIsLoadingMore(true)

      try {
        const { donations, pageEnd } = await fetchMoreCharityUserData(currentPageEnd).run()
        setCompoundedDonations([...compoundedDonations, ...donations])
        setCurrentPageEnd(pageEnd)
      } catch (error: any) {
        logError(new Error('Failed to fetch more donations'), error)
        pushMessage({
          formattedMessage: MyDonationsPage_LoadingUserDataFailed,
          type: 'danger',
          ttl: 5000
        })
      }

      setIsLoadingMore(false)
    }
  })

  return (
    <Container>
      {compoundedDonations.length > 0 ? (
        <>
          <DonationsContainer>
            <img src={theme.images.donationHeart} alt="A heart with hands holding a dollar bill" />

            <DonationSum>
              <SmallBoldText>
                <FormattedMessage
                  id={MyDonationsPage_YouHaveDonated.id}
                  defaultMessage={MyDonationsPage_YouHaveDonated.defaultMessage}
                />
              </SmallBoldText>
              <h1>
                <Currency amount={totalDonationSum || 0} currency={'AUD'} />
              </h1>
            </DonationSum>
          </DonationsContainer>

          <ListHeader>
            <FormattedMessage
              id={MyDonationsPage_PreviousDonations.id}
              defaultMessage={MyDonationsPage_PreviousDonations.defaultMessage}
            />
          </ListHeader>

          <List>
            <>
              {compoundedDonations.map((donation, i) => (
                <ListItem key={`don-${i}`}>
                  <CharityDonationCard donation={donation} />
                </ListItem>
              ))}
              {isLoadingMore && <BrowseCampaignsLoader style={{ marginTop: 'var(--spacing-lg)' }} count={1} />}
            </>
          </List>
        </>
      ) : (
        <FeedbackMessage className="centerAbsolute animated fadeIn medium">
          <FormattedMessage
            id={MyDonationsPage_NotDonatedYet.id}
            defaultMessage={MyDonationsPage_NotDonatedYet.defaultMessage}
          />
        </FeedbackMessage>
      )}
    </Container>
  )
}
