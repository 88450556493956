import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { IconButton, Button, Drawer } from '../../shared'
import { openNativeCamera } from '../../../services'
import { API_HOST, STAGE, authentication, intl, theme } from '../../../lib'
import { BagIcon, CheckmarkCircleIcon } from '../../icons'
import {
  Common_Bagdrop,
  Common_Close,
  BagdropCard_SuccessDialog_Description,
  BagdropCard_SuccessDialog_NumOfRegisteredBags,
  NativeTranslations_RegisterBagdrop_AlreadyRegisteredByUser,
  NativeTranslations_RegisterBagdrop_AlreadyRegisteredToAnotherUser,
  NativeTranslations_RegisterBagdrop_Description,
  NativeTranslations_RegisterBagdrop_EnterSerialButton,
  NativeTranslations_RegisterBagdrop_InvalidBagtag,
  NativeTranslations_RegisterBagdrop_Success,
  Common_InternetConnectionIssues,
  Common_GotIt,
  Common_SessionExpired,
  Common_TechnicalIssues,
  Common_Done
} from '../../../translations/messages'

const resultMessages = {
  '200': intl.formatMessage(NativeTranslations_RegisterBagdrop_Success),
  '400': intl.formatMessage(NativeTranslations_RegisterBagdrop_InvalidBagtag),
  '401': intl.formatMessage(Common_SessionExpired),
  '409': intl.formatMessage(NativeTranslations_RegisterBagdrop_AlreadyRegisteredByUser),
  '412': intl.formatMessage(NativeTranslations_RegisterBagdrop_AlreadyRegisteredToAnotherUser),
  '504': intl.formatMessage(Common_InternetConnectionIssues),
  genericError: intl.formatMessage(Common_TechnicalIssues)
}

export const nativeTranslationMessages = {
  doneButton: intl.formatMessage(Common_Done),
  errorButton: intl.formatMessage(Common_GotIt),
  description: intl.formatMessage(NativeTranslations_RegisterBagdrop_Description),
  enterSerialButton: intl.formatMessage(NativeTranslations_RegisterBagdrop_EnterSerialButton),
  registerResult: resultMessages,
  registerBagdropResult: resultMessages // Deprecated and to be removed when native app v2.14 gets > 95% install base
}

const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 3px solid black;
  border-radius: 100px;
  min-width: 100px;
  min-height: 100px;
  color: black;

  > small {
    margin-top: var(--spacing-xs);
  }
`

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--max-page-width);

  > *:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
`

type Props = {
  countryStateFeatures: CountryType['features']
}

export const BagdropButtonNative = ({ countryStateFeatures }: Props) => {
  const [logoutAfterSuccessDialog, setLogoutAfterSuccessDialog] = useState(false)

  const [numOfRegisteredBags, setNumOfRegisteredBags] = useState(0)

  useEffect(() => {
    // need to use window.addEventListener on iOS and document.addEventListener for android
    window.addEventListener('message', handleBagtagMessage)
    document.addEventListener('message', handleBagtagMessage)
    return () => {
      window.removeEventListener('message', handleBagtagMessage)
      document.removeEventListener('message', handleBagtagMessage)
    }
  })

  const handleBagtagMessage = (nativeAction: any) => {
    try {
      const { type } = typeof nativeAction.data === 'object' ? nativeAction.data : JSON.parse(nativeAction.data)

      if (type === 'BAGTAG_REGISTERED') {
        incrementNumOfRegisteredBags()
      }

      if (type === 'LOGIN_REQUIRED') {
        // If there are any successfully scanned bags -> wait with logout until success info dialog is closed
        if (numOfRegisteredBags !== 0) setLogoutAfterSuccessDialog(true)
        else authentication.logout()
      }
    } catch (error) {
      // Don't care
    }
  }

  const incrementNumOfRegisteredBags = () => {
    setNumOfRegisteredBags(numOfRegisteredBags + 1)
  }

  const resetNumOfRegisteredBags = () => {
    setNumOfRegisteredBags(0)
    if (logoutAfterSuccessDialog) authentication.logout()
  }

  const openNativeCameraWithPayload = () => {
    const fetchConfig = {
      apiHost: `${API_HOST}/mytomra`,
      accessToken: authentication.getToken()
    }

    openNativeCamera({ fetchConfig, messages: nativeTranslationMessages, cameraType: 'BAGDROP' })
  }

  return STAGE === 'test' || countryStateFeatures.HAS_BAGDROP ? (
    <React.Fragment>
      <StyledIconButton onClick={openNativeCameraWithPayload}>
        <BagIcon color="black" size="3rem" />
        <small>
          <FormattedMessage id={Common_Bagdrop.id} defaultMessage={Common_Bagdrop.defaultMessage} />
        </small>
      </StyledIconButton>

      <Drawer visible={numOfRegisteredBags !== 0} onClose={resetNumOfRegisteredBags}>
        <DrawerContainer>
          <CheckmarkCircleIcon size="30%" fillColor="var(--colors-primary)" />

          <h1>
            <FormattedMessage
              id={BagdropCard_SuccessDialog_NumOfRegisteredBags.id}
              defaultMessage={BagdropCard_SuccessDialog_NumOfRegisteredBags.defaultMessage}
              values={{ numOfRegisteredBags }}
            />
          </h1>

          <p>
            <FormattedMessage
              id={BagdropCard_SuccessDialog_Description.id}
              defaultMessage={BagdropCard_SuccessDialog_Description.defaultMessage}
              values={{ appName: theme.name }}
            />
          </p>

          <Button onClick={resetNumOfRegisteredBags}>
            <FormattedMessage id={Common_Close.id} defaultMessage={Common_Close.defaultMessage} />
          </Button>
        </DrawerContainer>
      </Drawer>
    </React.Fragment>
  ) : null
}
