import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { TextField_InvalidInput, TextField_RequiredField } from '../../../translations/messages'
import { WarningIcon } from '../../icons/WarningIcon'

const noOp = () => {}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.3333333333rem;
  padding-top: 1rem;
`

const Label = styled.label<{ textFieldHasValue: boolean }>`
  font-weight: ${props => (props.textFieldHasValue ? 'bold' : 'normal')};
  position: absolute;
  top: 30%;
  transform: ${props => (props.textFieldHasValue ? 'translate(-10%, -100%) scale(0.8)' : 'initial')};
  transition: transform 200ms;
  opacity: ${props => (props.textFieldHasValue ? 1 : 0.6)};
  pointer-events: none;
`

export const Input = styled.input<{ valid: boolean; touched: boolean }>`
  background-color: transparent;
  padding: var(--spacing-sm) 0;
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  transition: border-bottom-color 200ms;
  border-bottom: ${({ valid, touched }) => (!valid && touched ? '2px solid var(--colors-red)' : '1px solid black')};
  color: ${({ valid, touched }) => (!valid && touched ? 'var(--colors-red)' : 'initial')};
  width: 100%;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'initial')};
`

export const ErrorMessage = styled.div`
  width: 100%;
  position: absolute;
  top: 70%;
  left: 0;
  font-weight: bold;
  color: var(--colors-red);
`

export const ErrorIconContainer = styled.div`
  position: absolute;
  top: 1.3rem;
  left: -2rem;
`

type Props = {
  onChange: (value: string, valid: boolean) => void
  value: string
  valid: boolean
  label: string
  autocomplete?: string
  pattern?: string | RegExp
  errorMsg?: string
  required?: boolean
  style?: Object
  type?: string
  className?: string
  ignoreWhitespace?: boolean
  children?: React.ReactNode
  errorMessageDelay?: number
  ariaLabel?: string
}

type State = {
  touched: boolean
}

export class TextField extends React.Component<Props, State> {
  _timeoutRef: number = 0

  static defaultProps = {
    valid: true,
    onChange: () => {},
    errorMessageDelay: 1000
  }

  state = {
    touched: false
  }

  _delayErrorMessage = () => {
    clearTimeout(this._timeoutRef)

    this._timeoutRef = window.setTimeout(() => {
      this.setState({ touched: true })
    }, this.props.errorMessageDelay)
  }

  _onChange = evt => {
    this.setState({ touched: false })
    const { onChange, pattern, required } = this.props
    const newValue = evt.target.value

    let errors: string[] = []

    if (pattern) {
      const isValid = !!new RegExp(pattern).exec(newValue)

      if (!isValid) {
        errors = [...errors, 'pattern']
      }
    }

    if (required && newValue.length === 0) {
      errors = [...errors, 'required']
    }

    this._delayErrorMessage()

    onChange(newValue, errors.length === 0)
  }

  _onBlur = evt => {
    this.setState({ touched: true })
    evt.target.value = evt.target.value.trim()
    this._onChange(evt)
  }

  _ignoreWhiteSpaceInput = evt => {
    if (evt.key === ' ') evt.preventDefault()
  }

  render() {
    const {
      value,
      valid,
      label,
      autocomplete,
      errorMsg,
      required,
      style,
      type,
      className,
      ignoreWhitespace,
      children,
      ariaLabel
    } = this.props

    const showErrorMessage = !valid && this.state.touched

    const defaultMsg = this.props.pattern ? (
      <FormattedMessage id={TextField_InvalidInput.id} defaultMessage={TextField_InvalidInput.defaultMessage} />
    ) : (
      <FormattedMessage id={TextField_RequiredField.id} defaultMessage={TextField_RequiredField.defaultMessage} />
    )

    return (
      <Container style={style} className={className}>
        <Label htmlFor={label} textFieldHasValue={!!value}>
          {label}
        </Label>

        <Input
          id={label}
          onChange={this._onChange}
          onBlur={this._onBlur}
          value={value}
          valid={valid}
          required={required}
          type={type}
          touched={this.state.touched}
          onKeyDown={ignoreWhitespace ? this._ignoreWhiteSpaceInput : noOp}
          // @ts-ignore
          autocomplete={autocomplete}
          aria-label={ariaLabel}
          aria-invalid={showErrorMessage}
          aria-errormessage={`${label}-error`}
        />

        {children}

        {showErrorMessage && (
          <React.Fragment>
            <ErrorMessage id={`${label}-error`}>{errorMsg || defaultMsg}</ErrorMessage>

            <ErrorIconContainer>
              <WarningIcon size="1.5rem" color="var(--colors-red)" />
            </ErrorIconContainer>
          </React.Fragment>
        )}
      </Container>
    )
  }
}
