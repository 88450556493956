import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Outlet, useLocation, Link } from 'react-router-dom'
import { Tab, TabContainer, BackButton } from '../shared'
import {
  AchievementsPage_AllAchievements,
  Common_Achievements,
  AchievementsPage_YourAchievements
} from '../../translations/messages'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--colors-secondary);
`

const Header = styled.div`
  max-width: var(--max-page-width);
  margin: 0 auto;
  width: 100%;
  padding: var(--spacing-lg);

  > h1 {
    margin-top: 45px;
    margin-bottom: var(--spacing-lg);
  }

  > div {
    justify-content: space-around;
  }
`

export const AchievementsPage = () => {
  const { pathname } = useLocation()

  return (
    <>
      <BackButton to={!!pathname.match(/achievements\/.{4,}/gi) ? '.' : '..'} />

      <HeaderContainer>
        <Header className="animated fadeIn medium">
          <h1>
            <FormattedMessage id={Common_Achievements.id} defaultMessage={Common_Achievements.defaultMessage} />
          </h1>

          <TabContainer role="tabs">
            <Link to=".">
              <Tab active={pathname === '/achievements'}>
                <FormattedMessage
                  id={AchievementsPage_YourAchievements.id}
                  defaultMessage={AchievementsPage_YourAchievements.defaultMessage}
                />
              </Tab>
            </Link>
            <Link to="all">
              <Tab active={pathname === '/achievements/all'}>
                <FormattedMessage
                  id={AchievementsPage_AllAchievements.id}
                  defaultMessage={AchievementsPage_AllAchievements.defaultMessage}
                />
              </Tab>
            </Link>
          </TabContainer>
        </Header>
      </HeaderContainer>

      <Outlet />
    </>
  )
}
