import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const AlertIcon = (props: Props) => (
  <svg
    height={props.size || '0.87rem'}
    viewBox="0 0 24 24"
    width={props.size || '0.87rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeWidth: '1.5px'
    }}
  >
    <g transform="translate(0.75 0.75)">
      <g transform="translate(-207.755 -1907.75)">
        <path d="M219.005,1923.5a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375h0" />
        <path d="M219.005,1920.5v-8.25" />
        <path d="M219.005,1930.25a11.25,11.25,0,1,0-11.25-11.25A11.25,11.25,0,0,0,219.005,1930.25Z" />
      </g>
    </g>
  </svg>
)
