import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IconButton } from '../shared'
import { LocationsContext } from './LocationsContext'
import { InfoIcon } from '../icons'
import { LocationList_Title, LocationList_Subtitle, LocationList_NoLocations } from '../../translations/messages'

const Container = styled.form`
  display: flex;
  flex-direction: column;
`

const LocationsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 90vh;
  margin-top: var(--spacing-xl);
`

const Location = styled.div`
  position: relative;
  font-size: smaller;
  padding: var(--spacing-md) 0;
  border-top: 1px solid var(--colors-grey);

  :last-child {
    border-bottom: 1px solid var(--colors-grey);
  }

  > h2:not(:last-child) {
    width: 90%;
    margin-bottom: var(--spacing-md);
  }
`

const InfoButton = styled(IconButton)`
  background-color: white;
  border-radius: var(--radius-lg);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

export const LocationList = () => {
  const { getLocationsInViewport } = useContext(LocationsContext)
  const numberOfLocations = getLocationsInViewport().length

  return (
    <Container>
      <h1>
        <FormattedMessage id={LocationList_Title.id} defaultMessage={LocationList_Title.defaultMessage} />
      </h1>

      {numberOfLocations > 0 ? (
        <b style={{ color: 'var(--colors-green)' }}>
          <FormattedMessage
            id={LocationList_Subtitle.id}
            defaultMessage={LocationList_Subtitle.defaultMessage}
            values={{ numberOfLocations }}
          />
        </b>
      ) : (
        <b style={{ color: 'var(--colors-red)' }}>
          <FormattedMessage
            id={LocationList_NoLocations.id}
            defaultMessage={LocationList_NoLocations.defaultMessage}
            values={{ numberOfResults: 0 }}
          />
        </b>
      )}

      <LocationsList>
        {getLocationsInViewport().map(({ id, name, address, readableOpeningHours }) => (
          <Location key={id}>
            <h2 className="truncateText">{name}</h2>
            <p>{address}</p>
            <p>{readableOpeningHours}</p>

            <Link to={id}>
              <InfoButton>
                <InfoIcon size="35" />
              </InfoButton>
            </Link>
          </Location>
        ))}
      </LocationsList>
    </Container>
  )
}
