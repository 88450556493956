import {
  SystemErrors_BankTransfer_DEFAULT,
  SystemErrors_Voucher_DEFAULT,
  UserErrors_PayPal_ACCOUNT_RESTRICTED,
  UserErrors_PayPal_CURRENCY_COMPLIANCE,
  UserErrors_PayPal_DEFAULT,
  UserErrors_PayPal_NO_PAYOUT,
  UserErrors_PayPal_RECEIVER_ACCOUNT_INVALID,
  UserErrors_PayPal_RECEIVER_ACCOUNT_LOCKED,
  UserErrors_PayPal_RECEIVER_COUNTRY_NOT_ALLOWED,
  UserErrors_PayPal_RECEIVING_LIMIT_EXCEEDED,
  UserErrors_PayPal_REVERSED_PAYPAL,
  UserErrors_PayPal_RISK_DECLINE
} from '../../translations/messages'

const paypalMessages = {
  RISK_DECLINE: UserErrors_PayPal_RISK_DECLINE,
  ACCOUNT_RESTRICTED: UserErrors_PayPal_ACCOUNT_RESTRICTED,
  CURRENCY_COMPLIANCE: UserErrors_PayPal_CURRENCY_COMPLIANCE,
  NO_PAYOUT: UserErrors_PayPal_NO_PAYOUT,
  RECEIVER_ACCOUNT_INVALID: UserErrors_PayPal_RECEIVER_ACCOUNT_INVALID,
  RECEIVER_ACCOUNT_LOCKED: UserErrors_PayPal_RECEIVER_ACCOUNT_LOCKED,
  RECEIVER_COUNTRY_NOT_ALLOWED: UserErrors_PayPal_RECEIVER_COUNTRY_NOT_ALLOWED,
  RECEIVING_LIMIT_EXCEEDED: UserErrors_PayPal_RECEIVING_LIMIT_EXCEEDED,
  REVERSED_PAYPAL: UserErrors_PayPal_REVERSED_PAYPAL,
  RECEIVER_ACCOUNT_LIMITATION: UserErrors_PayPal_ACCOUNT_RESTRICTED,
  RECEIVER_UNCONFIRMED: UserErrors_PayPal_ACCOUNT_RESTRICTED,
  PENDING_RECIPIENT_NON_HOLDING_CURRENCY_PAYMENT_PREFERENCE: UserErrors_PayPal_ACCOUNT_RESTRICTED,
  DEFAULT: UserErrors_PayPal_DEFAULT
}

const voucherMessages = {
  DEFAULT: SystemErrors_Voucher_DEFAULT
}

const bankTransferMessages = {
  DEFAULT: SystemErrors_BankTransfer_DEFAULT
}

const payoutTypeToMessagesMap = {
  PAYPAL: paypalMessages,
  E_VOUCHER: voucherMessages,
  BANK_TRANSFER: bankTransferMessages
}

export default payoutTypeToMessagesMap
