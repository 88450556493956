import React from 'react'
import { FormattedMessage } from 'react-intl'
import { theme } from '../../lib'
import { VoucherIcon, HeartIcon, SavingBankIcon } from '../icons'
import {
  PayoutSettings_BankTransfer_Description,
  Common_BankTransfer,
  Common_PayPal,
  PayoutSettings_PayPal_Description,
  Common_Donation,
  PayoutSettings_Donation_Description,
  Common_DigitalVoucher,
  PayoutSettings_Voucher_Description,
  PayoutSettings_Voucher_Disclaimer
} from '../../translations/messages'

export const payoutLogoAndTranslationMap = {
  PAYPAL: {
    logo: <img src={theme.images.paypalLogo} alt="paypal" height="20px" />,
    name: <FormattedMessage id={Common_PayPal.id} defaultMessage={Common_PayPal.defaultMessage} />,
    description: (
      <FormattedMessage
        id={PayoutSettings_PayPal_Description.id}
        defaultMessage={PayoutSettings_PayPal_Description.defaultMessage}
      />
    )
  },
  E_VOUCHER: {
    logo: <VoucherIcon />,
    name: <FormattedMessage id={Common_DigitalVoucher.id} defaultMessage={Common_DigitalVoucher.defaultMessage} />,
    description: (
      <React.Fragment>
        <FormattedMessage
          id={PayoutSettings_Voucher_Description.id}
          defaultMessage={PayoutSettings_Voucher_Description.defaultMessage}
        />{' '}
        <FormattedMessage
          id={PayoutSettings_Voucher_Disclaimer.id}
          defaultMessage={PayoutSettings_Voucher_Disclaimer.defaultMessage}
        />
      </React.Fragment>
    )
  },
  DONATION: {
    logo: <HeartIcon />,
    name: <FormattedMessage id={Common_Donation.id} defaultMessage={Common_Donation.defaultMessage} />,
    description: (
      <FormattedMessage
        id={PayoutSettings_Donation_Description.id}
        defaultMessage={PayoutSettings_Donation_Description.defaultMessage}
      />
    )
  },
  BANK_TRANSFER: {
    logo: <SavingBankIcon />,
    name: <FormattedMessage id={Common_BankTransfer.id} defaultMessage={Common_BankTransfer.defaultMessage} />,
    description: (
      <FormattedMessage
        id={PayoutSettings_BankTransfer_Description.id}
        defaultMessage={PayoutSettings_BankTransfer_Description.defaultMessage}
      />
    )
  }
}
