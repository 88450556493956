import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { theme } from '../../lib'
import {
  CollectionPointTypes_ACP_Title,
  CollectionPointTypes_APS_Title,
  CollectionPointTypes_MCP_Title,
  CollectionPointTypes_DCP_Title
} from '../../translations/messages'

const Container = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 100%;
    max-width: 2rem;
    height: auto;
    margin-right: var(--spacing-sm);
  }
`

const CPTypeToIconAndTitleMap = {
  ACP: {
    icon: <img src={theme.images.cpTypes.ACP.icon} alt="ACP icon" />,
    title: (
      <FormattedMessage
        id={CollectionPointTypes_ACP_Title.id}
        defaultMessage={CollectionPointTypes_ACP_Title.defaultMessage}
      />
    )
  },
  APS: {
    icon: <img src={theme.images.cpTypes.APS.icon} alt="APS icon" />,
    title: (
      <FormattedMessage
        id={CollectionPointTypes_APS_Title.id}
        defaultMessage={CollectionPointTypes_APS_Title.defaultMessage}
      />
    )
  },
  MCP: {
    icon: <img src={theme.images.cpTypes.MCP.icon} alt="MCP icon" />,
    title: (
      <FormattedMessage
        id={CollectionPointTypes_MCP_Title.id}
        defaultMessage={CollectionPointTypes_MCP_Title.defaultMessage}
      />
    )
  },
  DCP: {
    icon: <img src={theme.images.cpTypes.DCP.icon} alt="DCP icon" />,
    title: (
      <FormattedMessage
        id={CollectionPointTypes_DCP_Title.id}
        defaultMessage={CollectionPointTypes_DCP_Title.defaultMessage}
      />
    )
  }
}

type Props = {
  locationType: MapLocationType['locationType']
}

export const CPType = ({ locationType }: Props) => {
  const { icon, title } = CPTypeToIconAndTitleMap[locationType]

  return (
    <Container>
      {icon}
      <b>{title}</b>
    </Container>
  )
}
