import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { intl, languages, langHasIntlSupport } from '../../lib'
import { LanguageIcon } from '../icons'
import { LanguagePicker_Language } from '../../translations/messages'

const supportedLangs = Object.keys(languages).filter(langHasIntlSupport)

const Container = styled.div<{ reverseIconAndLabelOrder?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  > b {
    line-height: 1;
    margin-right: ${props => (props.reverseIconAndLabelOrder ? 0 : 'var(--spacing-md)')};
    margin-left: ${props => (props.reverseIconAndLabelOrder ? 'var(--spacing-md)' : 0)};
    order: ${props => (props.reverseIconAndLabelOrder ? 1 : 0)};
  }

  > *:not(:nth-child(2)) {
    pointer-events: none;
  }
`

const StyledSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: none;
  font-weight: bold;
  appearance: none;
  padding: var(--spacing-sm) var(--spacing-xl) var(--spacing-sm) var(--spacing-md);
  border: none;
  cursor: pointer;
  color: transparent;

  > option {
    font-weight: normal;
    color: initial;
  }
`

type Props = {
  reverseIconAndLabelOrder?: boolean
}

export const LanguagePicker = ({ reverseIconAndLabelOrder }: Props) => {
  const setNewLanguage = async (newLang: string) => {
    if (newLang) {
      try {
        window.localStorage.setItem('activeLanguage', newLang)
      } catch (error) {
        // Don't care
      }

      window.location.reload()
    }
  }

  return supportedLangs.length > 0 ? (
    <Container reverseIconAndLabelOrder={reverseIconAndLabelOrder}>
      <b>
        <FormattedMessage id={LanguagePicker_Language.id} defaultMessage={LanguagePicker_Language.defaultMessage} />
      </b>
      <StyledSelect
        aria-label={intl.formatMessage(LanguagePicker_Language)}
        value={intl.locale}
        onChange={evt => setNewLanguage(evt.target.value)}
      >
        {supportedLangs.map(langCode => (
          <option key={langCode} value={langCode}>
            {intl.formatMessage(languages[langCode].nameTranslation)}
          </option>
        ))}
      </StyledSelect>

      <LanguageIcon />
    </Container>
  ) : null
}
