import React from 'react'
import styled from 'styled-components'
import { ActivityRow } from './ActivityRow'

type Props = {
  title: string
  activities: RefundType[]
}

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: var(--spacing-xl);
`

const Line = styled.div`
  height: 2px;
  background-color: black;
  flex-grow: 1;
  margin-left: var(--spacing-md);
`

const Title = styled.h2`
  color: black;
`

const ActivitiesSection = ({ title, activities }: Props) => (
  <div className="animated fadeInRight medium">
    <Header>
      <Title>{title}</Title>
      <Line />
    </Header>

    {activities.map(activity => (
      <ActivityRow activity={activity} key={activity.id} />
    ))}
  </div>
)

export default ActivitiesSection
