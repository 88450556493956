import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { LocationStatus } from '../../locations/LocationStatus'
import { fetchLocation } from '../../../services/location-service'
import { logError, removeItemFromWebStorage, theme } from '../../../lib'
import { ArrowRightIcon } from '../../icons'
import { Card } from '../../shared/common'

const everyTwentySeconds = 20 * 1000

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-md);
  cursor: pointer;
  color: initial;
  border-bottom: 1px solid var(--colors-grey);
`

const NameAndHours = styled.small`
  margin: 0 var(--spacing-md);
`

const LoadingPlaceholder = () => {
  return (
    <ContentLoader
      height="8rem"
      width="100%"
      viewBox="0 0 21rem 3.5rem"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="2rem" cy="30%" r="1.2rem" />
      <rect x="30%" y="20%" rx="3" ry="3" width="12rem" height="1.4rem" />
      <rect x="0" y="4rem" rx="3" ry="3" width="100%" height="0.1rem" />
      <rect x="10%" y="70%" rx="3" ry="3" width="80%" height="1.4rem" />
    </ContentLoader>
  )
}

type Props = {
  favoriteLocationId: string
}

type State = {
  location: MapLocationType | null
}

export class MyLocation extends React.Component<Props, State> {
  _abortFetchLocation = () => {}
  _pollingInterval: number = 0

  state: State = {
    location: null
  }

  _fetchLocation = async () => {
    try {
      const { run, abort } = fetchLocation(this.props.favoriteLocationId)
      this._abortFetchLocation = abort
      const location = await run()
      this.setState({ location })
    } catch (error: any) {
      if (error.status === 404) {
        // To handle previously saved locations that no longer exist
        removeItemFromWebStorage('favoriteLocationId')
        logError(new Error('Non-existant favorite location - resetting'), error)
      } else if (error.name !== 'AbortError') {
        logError(new Error('Failed to fetch favorite location'), error)
      }
    }
  }

  _setupStatusPoller = () => {
    this._pollingInterval = window.setInterval(() => {
      this._fetchLocation()
    }, everyTwentySeconds)
  }

  componentDidMount() {
    this._fetchLocation()
    this._setupStatusPoller()
  }

  componentWillUnmount() {
    window.clearInterval(this._pollingInterval)
    this._abortFetchLocation()
  }

  render() {
    const { location } = this.state

    return (
      <Link to={`locations/${this.props.favoriteLocationId}`}>
        <Card className="animated fadeIn medium">
          {location === null ? (
            <LoadingPlaceholder />
          ) : (
            <React.Fragment>
              <Container>
                <img src={theme.images.mapFavorite} alt="favorite location" height="30" />

                <NameAndHours>
                  <b>{location.name}</b>
                  <p>{location.readableOpeningHours}</p>
                </NameAndHours>

                <ArrowRightIcon />
              </Container>

              {location.status && <LocationStatus locationStatuses={location.status} />}
            </React.Fragment>
          )}
        </Card>
      </Link>
    )
  }
}
