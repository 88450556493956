import React, { useState } from 'react'
import styled from 'styled-components'
import { Drawer } from '../shared'
import { BankTransfer, Donation, PayPal, Voucher } from './options'
import { CheckmarkCircleIcon } from '../icons'
import { payoutLogoAndTranslationMap } from './payoutLogoAndTranslationMap'
import { FormattedMessage } from 'react-intl'
import { PayoutSettings_Selected } from '../../translations/messages'

const noOp = () => {}

const CardButton = styled.button<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  background-color: white;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-sm);
  text-align: left;
  outline: 0;
  color: black;
  cursor: pointer;
  border: ${props => (props.active ? '2px solid var(--colors-green)' : 'none')};
  line-height: 1.5;

  > * {
    width: 100%;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);

  b {
    margin-left: var(--spacing-md);
  }
`

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--max-page-width);
  margin: 0 auto;

  > *:not(h1) {
    margin-top: var(--spacing-lg);
    margin-bottom: 0;
  }
`

export const PayoutOptionCard = ({ active, savedConfiguration, type, logoUrl }: PayoutMethodType) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const { logo, name, description } = payoutLogoAndTranslationMap[type]
  const isVoucherAndActive = type === 'E_VOUCHER' && active

  const onDrawerClose = () => {
    setShowDrawer(false)
  }

  const componentProps = {
    savedConfiguration,
    active
  }

  const typeToComponentMap = {
    BANK_TRANSFER: <BankTransfer {...componentProps} />,
    DONATION: <Donation {...componentProps} />,
    PAYPAL: <PayPal {...componentProps} />,
    E_VOUCHER: <Voucher />
  }

  return (
    <>
      <CardButton active={active} onClick={isVoucherAndActive ? noOp : () => setShowDrawer(true)}>
        <Header>
          <span>
            {logoUrl ? <img aria-hidden={true} src={logoUrl} alt="charity-logo" height="28px" /> : logo}
            <b>{name}</b>
          </span>

          {active && (
            <>
              <CheckmarkCircleIcon fillColor="var(--colors-green)" />
              <span className="visually-hidden">
                <FormattedMessage
                  id={PayoutSettings_Selected.id}
                  defaultMessage={PayoutSettings_Selected.defaultMessage}
                />
              </span>
            </>
          )}
        </Header>

        {savedConfiguration ? (
          <>
            <p>{savedConfiguration[Object.keys(savedConfiguration)[0]]}</p>
            <small>
              <b>{savedConfiguration[Object.keys(savedConfiguration)[1]]}</b>
            </small>
          </>
        ) : (
          <p>{description}</p>
        )}
      </CardButton>

      <Drawer onClose={onDrawerClose} visible={showDrawer}>
        <DrawerContent>{typeToComponentMap[type] || null}</DrawerContent>
      </Drawer>
    </>
  )
}
