import React from 'react'
import styled from 'styled-components'
import { intl, theme } from '../../lib'
import { Checkbox } from '../shared'
import { Common_ReadAndAgreeTos, Common_TermsAndConditions, Common_PrivacyPolicy } from '../../translations/messages'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: var(--spacing-lg) 0;

  > label {
    margin-left: var(--spacing-md);
  }
`

const StyledAnchor = styled.a`
  color: black;
  font-weight: bold;
  text-decoration: underline;
`

type Props = {
  checked: boolean
  onChange: () => void
}

export const TermsCheckbox = ({ checked, onChange }: Props) => {
  return (
    <Container>
      <Checkbox id="terms" aria-label="Accept terms" checked={checked} onChange={onChange} />

      <label htmlFor="terms">
        <small>
          {intl.formatMessage(Common_ReadAndAgreeTos, {
            ppLink: (
              <StyledAnchor href={theme.privacyPolicy} target="_blank">
                {intl.formatMessage(Common_PrivacyPolicy)}
              </StyledAnchor>
            ),
            tosLink: (
              <StyledAnchor href={theme.termsOfService} target="_blank">
                {intl.formatMessage(Common_TermsAndConditions)}
              </StyledAnchor>
            )
          })}
        </small>
      </label>
    </Container>
  )
}
