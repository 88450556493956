import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Link, useRouteLoaderData } from 'react-router-dom'
import { Card } from '../shared'
import { FindLocation } from '../home/favoriteLocation/FindLocation'
import { theme } from '../../lib'
import { Achievement } from './common/Achievement'
import {
  Common_Achievements,
  MyAchievements_NotRecycledYet,
  MyAchievements_RecycledContainers
} from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  max-width: var(--max-page-width);
  margin: 0 auto;
  min-height: 75vh;
`

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  text-align: center;
  padding: var(--spacing-md);

  > *:last-child {
    width: 100%;
    margin-top: var(--spacing-xl);
  }
`

const StatisticsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--spacing-md) 0;
`

const Statistics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  :first-child {
    margin-left: var(--spacing-xl);
  }

  :last-child {
    margin-right: var(--spacing-xl);
  }

  > small {
    margin-top: var(--spacing-md);
    margin-bottom: -var(--spacing-xs);
  }
`

const StyledCard = styled(Card)`
  margin-bottom: var(--spacing-sm);
  padding: var(--spacing-xl) var(--spacing-lg);
`

const existingInTheme = (achievement: AchievementType) => {
  return Object.keys(theme.achievements).some(name => name.includes(achievement.achievementName))
}

export const MyAchievements = () => {
  const { profile, allAchievements } = useRouteLoaderData('achievements-root') as AchievementsRequestType
  const completedAchievements = allAchievements.filter(existingInTheme).filter(({ currentLevel }) => currentLevel > 0)

  const completedAchievementLevels = completedAchievements.reduce(
    (acc, achievement) => acc + achievement.currentLevel,
    0
  )

  const totalAchievementLevels = allAchievements.filter(existingInTheme).reduce(acc => acc + 4, 0)

  return (
    <Container className="animated fadeInLeft medium">
      <StatisticsContainer>
        <Statistics>
          <img src={theme.images.achievementContainers} alt="bottles" height="70px" />
          <small>
            <b>
              <FormattedMessage
                id={MyAchievements_RecycledContainers.id}
                defaultMessage={MyAchievements_RecycledContainers.defaultMessage}
              />
            </b>
          </small>
          <h1>{profile.totalRecycledContainers}</h1>
        </Statistics>

        <Statistics>
          <img src={theme.images.achievementReward} alt="reward" height="70px" />
          <small>
            <b>
              <FormattedMessage id={Common_Achievements.id} defaultMessage={Common_Achievements.defaultMessage} />
            </b>
          </small>
          <h1>{`${completedAchievementLevels}/${totalAchievementLevels}`}</h1>
        </Statistics>
      </StatisticsContainer>

      {profile.totalRecycledContainers ? (
        completedAchievements.map(achievement => {
          const previousLevelAchievement = { ...achievement, currentLevel: achievement.currentLevel - 1 }

          return (
            <Link to={achievement.achievementName} key={achievement.achievementName + achievement.currentLevel}>
              <StyledCard>
                <Achievement achievement={previousLevelAchievement} />
              </StyledCard>
            </Link>
          )
        })
      ) : (
        <FeedbackContainer>
          <h1>
            <FormattedMessage
              id={MyAchievements_NotRecycledYet.id}
              defaultMessage={MyAchievements_NotRecycledYet.defaultMessage}
            />
          </h1>

          <FindLocation />
        </FeedbackContainer>
      )}
    </Container>
  )
}
