import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { CPTInfo } from './CPTInfo'
import { Card, Checkbox } from '../shared'
import { theme } from '../../lib'
import { LocationsContext } from './LocationsContext'
import {
  LocationSearch_FilterOnReturnPoint,
  CollectionPointTypes_ACP_Title,
  CollectionPointTypes_APS_Title,
  CollectionPointTypes_MCP_Title,
  CollectionPointTypes_DCP_Title
} from '../../translations/messages'

const Container = styled.div`
  > label {
    display: block;
  }

  > *:not(:last-child) {
    margin-bottom: var(--spacing-md);
  }
`

const FilterCard = styled(Card)`
  cursor: pointer;
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 2.5rem;
  }
`

const Title = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LocationFilter = () => {
  const { activeFilters, filterMarkers } = useContext(LocationsContext)

  return (
    <Container>
      <Title>
        <FormattedMessage
          id={LocationSearch_FilterOnReturnPoint.id}
          defaultMessage={LocationSearch_FilterOnReturnPoint.defaultMessage}
        />

        <CPTInfo />
      </Title>

      <label htmlFor="ACP">
        <FilterCard>
          <img src={theme.images.cpTypes.ACP.icon} alt="ACP icon" />

          <span>
            <FormattedMessage
              id={CollectionPointTypes_ACP_Title.id}
              defaultMessage={CollectionPointTypes_ACP_Title.defaultMessage}
            />
          </span>

          <Checkbox
            id="ACP"
            aria-label="Filter by reverse vending machines"
            onChange={() => filterMarkers('ACP')}
            checked={activeFilters.includes('ACP')}
          />
        </FilterCard>
      </label>

      <label htmlFor="APS">
        <FilterCard>
          <img src={theme.images.cpTypes.APS.icon} alt="APS icon" />

          <span>
            <FormattedMessage
              id={CollectionPointTypes_APS_Title.id}
              defaultMessage={CollectionPointTypes_APS_Title.defaultMessage}
            />
          </span>

          <Checkbox
            id="APS"
            aria-label="Filter by automated depot"
            onChange={() => filterMarkers('APS')}
            checked={activeFilters.includes('APS')}
          />
        </FilterCard>
      </label>

      <label htmlFor="MCP">
        <FilterCard>
          <img src={theme.images.cpTypes.MCP.icon} alt="MCP icon" />

          <span>
            <FormattedMessage
              id={CollectionPointTypes_MCP_Title.id}
              defaultMessage={CollectionPointTypes_MCP_Title.defaultMessage}
            />
          </span>

          <Checkbox
            id="MCP"
            aria-label="Filter by over the counter"
            onChange={() => filterMarkers('MCP')}
            checked={activeFilters.includes('MCP')}
          />
        </FilterCard>
      </label>

      <label htmlFor="DCP">
        <FilterCard>
          <img src={theme.images.cpTypes.DCP.icon} alt="DCP icon" />

          <span>
            <FormattedMessage
              id={CollectionPointTypes_DCP_Title.id}
              defaultMessage={CollectionPointTypes_DCP_Title.defaultMessage}
            />
          </span>

          <Checkbox
            id="DCP"
            aria-label="Filter by donation station"
            onChange={() => filterMarkers('DCP')}
            checked={activeFilters.includes('DCP')}
          />
        </FilterCard>
      </label>
    </Container>
  )
}
