import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFetcher } from 'react-router-dom'
import { Button, Loading } from '../../shared'
import { authenticateZeptoPaymentUser } from '../../../services/zepto-broker-service'
import {
  Common_Continue,
  PayoutSettings_BankTransfer_Description,
  PayoutSettings_VoucherDisclaimer,
  Common_EditPayout,
  Common_UseSavedAccount,
  Common_DeleteSaved
} from '../../../translations/messages'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
}

export const BankTransfer = ({ savedConfiguration, active }: Props) => {
  const { Form, state } = useFetcher()
  const isLoading = state === 'submitting' || state === 'loading'

  return (
    <>
      <h1>
        <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
      </h1>

      <span>
        <FormattedMessage
          id={PayoutSettings_BankTransfer_Description.id}
          defaultMessage={PayoutSettings_BankTransfer_Description.defaultMessage}
        />
      </span>

      <span>
        <FormattedMessage
          id={PayoutSettings_VoucherDisclaimer.id}
          defaultMessage={PayoutSettings_VoucherDisclaimer.defaultMessage}
          values={{ payoutMethod: '' }}
        />
      </span>

      {savedConfiguration ? (
        <>
          {!active && (
            <Form method="put">
              <Button
                background="colored"
                aria-disabled={isLoading}
                disabled={isLoading}
                name="payoutType"
                value="BANK_TRANSFER"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <span>
                    <FormattedMessage
                      id={Common_UseSavedAccount.id}
                      defaultMessage={Common_UseSavedAccount.defaultMessage}
                    />
                    <p>
                      <small>({savedConfiguration.maskedAccountNumber})</small>
                    </p>
                  </span>
                )}
              </Button>
            </Form>
          )}

          <Form method="delete">
            <Button aria-disabled={isLoading} disabled={isLoading} name="payoutType" value="BANK_TRANSFER" border>
              {isLoading ? (
                <Loading />
              ) : (
                <FormattedMessage id={Common_DeleteSaved.id} defaultMessage={Common_DeleteSaved.defaultMessage} />
              )}
            </Button>
          </Form>
        </>
      ) : (
        <Button onClick={authenticateZeptoPaymentUser}>
          <FormattedMessage id={Common_Continue.id} defaultMessage={Common_Continue.defaultMessage} />
        </Button>
      )}
    </>
  )
}
