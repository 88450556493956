import React from 'react'
import styled from 'styled-components'
import { useRouteError } from 'react-router-dom'
import { ErrorIcon } from './icons'
import { Common_GeneralError, AppUpdatedDialog_Button } from '../translations/messages'
import { Button } from './shared'
import { intl } from '../lib'

const Container = styled.div`
  min-height: 100vh;
  background-color: var(--colors-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: var(--spacing-xl);
  text-align: center;

  > * {
    max-width: var(--max-page-width);
  }
`

const ErrorMsg = styled.p`
  padding: var(--spacing-md);
  border-radius: var(--radius-sm);
  background-color: rgba(255, 0, 0, 0.15);
  width: 100%;
  font-size: var(--font-size-sm);
`

export const ErrorPage = () => {
  const error = useRouteError() as Error

  return (
    <Container>
      <ErrorIcon size="100" />
      <p>{intl.formatMessage(Common_GeneralError)}</p>
      <ErrorMsg>{error.message}</ErrorMsg>
      <Button onClick={() => (window.location.href = '/')}>{intl.formatMessage(AppUpdatedDialog_Button)}</Button>
    </Container>
  )
}
