import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Button, TextField, Loading } from '../shared'
import { LocationFilter } from './LocationFilter'
import { LocationsContext } from './LocationsContext'
import { pushMessage, removeMessage } from '../AppMessages'
import { findCoordinatesForKeyword } from '../../services'
import {
  LocationSearch_TextFieldLabel,
  LocationSearch_SubmitSearchButtonText,
  LocationSearch_Title,
  LocationSearch_ErrorMessage,
  LocationSearch_NumberOfResults,
  LocationSearch_NoResultsDisclaimer,
  LocationList_Subtitle,
  LocationSearch_NoLocationsInViewport
} from '../../translations/messages'
import { intl, logError } from '../../lib'

const Container = styled.form`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
`

const ErrorMessage = styled.p`
  color: var(--colors-red);
  font-weight: bold;
`

const NoResults = styled.div`
  margin-top: -1rem;
`

type Props = {
  closeDrawer: () => void
}

export const LocationSearch = ({ closeDrawer }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [requestStatus, setRequestStatus] = useState<RequestStatusType | 'noResults'>('initial')
  const { mapInstance, getLocationsInViewport } = useContext(LocationsContext)

  const performSearch = async (evt: React.FormEvent) => {
    evt.preventDefault()
    removeMessage()

    if (!searchValue) {
      closeDrawer()
    }

    setRequestStatus('loading')

    try {
      let { results, status, error_message } = await findCoordinatesForKeyword(searchValue, mapInstance?.getBounds()!)

      if (status !== 'OK') {
        if (status === 'ZERO_RESULTS') {
          return setRequestStatus('noResults')
        } else {
          throw new Error(error_message)
        }
      }

      const { location, viewport } = results[0].geometry
      mapInstance?.setCenter(location)
      mapInstance?.fitBounds(new google.maps.LatLngBounds(viewport.southwest, viewport.northeast))

      const numberOfLocations = getLocationsInViewport().length

      if (numberOfLocations !== 0) {
        pushMessage({
          type: 'success',
          formattedMessage: {
            ...LocationList_Subtitle,
            values: { numberOfLocations: String(numberOfLocations) }
          },
          ttl: 5000
        })
      } else {
        pushMessage({
          type: 'warning',
          formattedMessage: LocationSearch_NoLocationsInViewport,
          ttl: 5000
        })
      }

      setRequestStatus('success')
      closeDrawer()
    } catch (error: any) {
      setRequestStatus('failed')
      logError(new Error('Failed to perform geocoding search for location'), error)
    }
  }

  return (
    <Container onSubmit={performSearch}>
      <h1>
        <FormattedMessage id={LocationSearch_Title.id} defaultMessage={LocationSearch_Title.defaultMessage} />
      </h1>

      <TextField
        onChange={value => {
          if (value !== searchValue) setRequestStatus('initial')
          setSearchValue(value)
        }}
        autocomplete="off"
        value={searchValue}
        label={intl.formatMessage(LocationSearch_TextFieldLabel)}
        type="text"
      />

      {requestStatus === 'failed' && (
        <ErrorMessage>
          <FormattedMessage
            id={LocationSearch_ErrorMessage.id}
            defaultMessage={LocationSearch_ErrorMessage.defaultMessage}
          />
        </ErrorMessage>
      )}

      {requestStatus === 'noResults' && (
        <NoResults>
          <ErrorMessage>
            <FormattedMessage
              id={LocationSearch_NumberOfResults.id}
              defaultMessage={LocationSearch_NumberOfResults.defaultMessage}
              values={{ numberOfResults: 0, searchValue }}
            />
          </ErrorMessage>

          <small>
            <FormattedMessage
              id={LocationSearch_NoResultsDisclaimer.id}
              defaultMessage={LocationSearch_NoResultsDisclaimer.defaultMessage}
            />
          </small>
        </NoResults>
      )}

      <LocationFilter />

      <Button background="colored" aria-label="Perform search">
        {requestStatus === 'loading' ? (
          <Loading />
        ) : (
          <FormattedMessage
            id={LocationSearch_SubmitSearchButtonText.id}
            defaultMessage={LocationSearch_SubmitSearchButtonText.defaultMessage}
          />
        )}
      </Button>
    </Container>
  )
}
