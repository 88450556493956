import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { CPType } from './CPType'
import {
  CPTInfo_Title,
  CollectionPointTypes_ACP_Description,
  CollectionPointTypes_APS_Description,
  CollectionPointTypes_MCP_Description,
  CollectionPointTypes_DCP_Description
} from '../../translations/messages'
import { InfoIcon } from '../icons'

const Container = styled.div`
  :hover {
    > div {
      display: block;
    }
  }
`

const Tooltip = styled.div`
  position: absolute;
  bottom: var(--spacing-lg);
  left: 50%;
  transform: translateX(-50%);
  display: none;
  background-color: black;
  color: white;
  border-radius: var(--radius-sm);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-lg);
  width: 90%;
  max-width: var(--max-page-width);
  z-index: 100;

  > button {
    position: absolute;
    top: var(--spacing-md);
    right: var(--spacing-md);
  }
`

const Section = styled.section`
  margin-top: var(--spacing-lg);

  > div {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-sm);

    > img {
      width: 100%;
      max-width: 2rem;
      height: auto;
      margin-right: var(--spacing-sm);
    }
  }
`

export const CPTInfo = () => (
  <Container>
    <InfoIcon />

    <Tooltip className="animated fadeIn medium">
      <p>
        <b>
          <FormattedMessage id={CPTInfo_Title.id} defaultMessage={CPTInfo_Title.defaultMessage} />
        </b>
      </p>

      <Section>
        <CPType locationType="ACP" />

        <p>
          <FormattedMessage
            id={CollectionPointTypes_ACP_Description.id}
            defaultMessage={CollectionPointTypes_ACP_Description.defaultMessage}
          />
        </p>
      </Section>

      <Section>
        <CPType locationType="APS" />

        <p>
          <FormattedMessage
            id={CollectionPointTypes_APS_Description.id}
            defaultMessage={CollectionPointTypes_APS_Description.defaultMessage}
          />
        </p>
      </Section>

      <Section>
        <CPType locationType="MCP" />

        <p>
          <FormattedMessage
            id={CollectionPointTypes_MCP_Description.id}
            defaultMessage={CollectionPointTypes_MCP_Description.defaultMessage}
          />
        </p>
      </Section>

      <Section>
        <CPType locationType="DCP" />

        <p>
          <FormattedMessage
            id={CollectionPointTypes_DCP_Description.id}
            defaultMessage={CollectionPointTypes_DCP_Description.defaultMessage}
          />
        </p>
      </Section>
    </Tooltip>
  </Container>
)
