import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { IconButton } from '../../shared'
import { openNativeCamera } from '../../../services'
import { API_HOST, authentication, intl, STAGE } from '../../../lib'
import { QrCodeIcon } from '../../icons'
import {
  Common_Done,
  Common_GotIt,
  Common_InternetConnectionIssues,
  Common_SessionExpired,
  Common_TechnicalIssues,
  Header_QrCode,
  NativeTranslations_RegisterQRCode_Description,
  NativeTranslations_RegisterQRCode_InvalidCode,
  NativeTranslations_RegisterQRCode_LoginFailed,
  NativeTranslations_RegisterQRCode_LoginNotPossible,
  NativeTranslations_RegisterQRCode_Success
} from '../../../translations/messages'

const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 3px solid black;
  border-radius: 100px;
  min-width: 100px;
  min-height: 100px;
  color: black;

  > small {
    margin-top: var(--spacing-xs);
  }
`

type Props = {
  countryStateFeatures: CountryType['features']
}

export const QRCodeButtonNative = ({ countryStateFeatures }: Props) => {
  useEffect(() => {
    // need to use window.addEventListener on iOS and document.addEventListener for android
    window.addEventListener('message', handleLogoutMessage)
    document.addEventListener('message', handleLogoutMessage)
    return () => {
      window.removeEventListener('message', handleLogoutMessage)
      document.removeEventListener('message', handleLogoutMessage)
    }
  })

  const handleLogoutMessage = (nativeAction: any) => {
    try {
      const { type } = typeof nativeAction.data === 'object' ? nativeAction.data : JSON.parse(nativeAction.data)

      if (type === 'LOGIN_REQUIRED') {
        authentication.logout()
      }
    } catch (error) {
      // Don't care
    }
  }

  const openNativeCameraWithPayload = () => {
    const fetchConfig = {
      apiHost: `${API_HOST}/mytomra`,
      accessToken: authentication.getToken()
    }

    const messages = {
      doneButton: intl.formatMessage(Common_Done),
      errorButton: intl.formatMessage(Common_GotIt),
      description: intl.formatMessage(NativeTranslations_RegisterQRCode_Description),
      registerResult: {
        '200': intl.formatMessage(NativeTranslations_RegisterQRCode_Success),
        '400': intl.formatMessage(NativeTranslations_RegisterQRCode_InvalidCode),
        '401': intl.formatMessage(Common_SessionExpired),
        '520': intl.formatMessage(NativeTranslations_RegisterQRCode_LoginFailed),
        '503': intl.formatMessage(NativeTranslations_RegisterQRCode_LoginNotPossible),
        '504': intl.formatMessage(Common_InternetConnectionIssues),
        genericError: intl.formatMessage(Common_TechnicalIssues)
      }
    }

    openNativeCamera({ fetchConfig, messages, cameraType: 'QR_CODE' })
  }

  return STAGE === 'test' || countryStateFeatures.HAS_QR_CODE ? (
    <StyledIconButton onClick={openNativeCameraWithPayload}>
      <QrCodeIcon color="black" size="2.5rem" />
      <small>
        <FormattedMessage id={Header_QrCode.id} defaultMessage={Header_QrCode.defaultMessage} />
      </small>
    </StyledIconButton>
  ) : null
}
