import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFetcher } from 'react-router-dom'
import { Button, Loading } from '../../shared'
import { authenticatePayPalUser } from '../../../services'
import {
  Common_Continue,
  PayoutSettings_PayPal_Description,
  PayoutSettings_VoucherDisclaimer,
  Common_EditPayout,
  Common_UseSavedAccount,
  Common_DeleteSaved
} from '../../../translations/messages'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
}

export const PayPal = ({ savedConfiguration, active }: Props) => {
  const { Form, state } = useFetcher()
  const isLoading = state === 'submitting' || state === 'loading'

  return (
    <>
      <h1>
        <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
      </h1>

      <span>
        <FormattedMessage
          id={PayoutSettings_PayPal_Description.id}
          defaultMessage={PayoutSettings_PayPal_Description.defaultMessage}
        />
      </span>

      <span>
        <FormattedMessage
          id={PayoutSettings_VoucherDisclaimer.id}
          defaultMessage={PayoutSettings_VoucherDisclaimer.defaultMessage}
          values={{ payoutMethod: 'PayPal' }}
        />
      </span>

      {savedConfiguration ? (
        <Form method={active ? 'delete' : 'put'}>
          <Button aria-disabled={isLoading} disabled={isLoading} name="payoutType" value="PAYPAL" border>
            {isLoading ? (
              <Loading />
            ) : active ? (
              <FormattedMessage id={Common_DeleteSaved.id} defaultMessage={Common_DeleteSaved.defaultMessage} />
            ) : (
              <span>
                <FormattedMessage
                  id={Common_UseSavedAccount.id}
                  defaultMessage={Common_UseSavedAccount.defaultMessage}
                />
                <p>
                  <small>({savedConfiguration.email})</small>
                </p>
              </span>
            )}
          </Button>
        </Form>
      ) : (
        <Button onClick={authenticatePayPalUser} background="colored">
          <FormattedMessage id={Common_Continue.id} defaultMessage={Common_Continue.defaultMessage} />
        </Button>
      )}
    </>
  )
}
