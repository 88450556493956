import { createIntl, createIntlCache, IntlShape } from 'react-intl'
import arabicLebaneseMessages from '../translations/locales/ar-LB.json'
import germanMessages from '../translations/locales/de.json'
import englishAustralianMessages from '../translations/locales/en-AU.json'
import englishUnitedStatesMessages from '../translations/locales/en-US.json'
import englishBritishMessages from '../translations/locales/en-GB.json'
import spanishMessages from '../translations/locales/es.json'
import canadianFrenchMessages from '../translations/locales/fr-CA.json'
import portugueseMessages from '../translations/locales/pt.json'
import chineseSimplifiedMessages from '../translations/locales/zh-Hans.json'
import chineseHongKongMessages from '../translations/locales/zh-HK.json'
import vietnameseMessages from '../translations/locales/vi.json'
import {
  LanguagePicker_AmericanEnglish,
  LanguagePicker_AustralianEnglish,
  LanguagePicker_BritishEnglish,
  LanguagePicker_CanadianFrench,
  LanguagePicker_Chinese,
  LanguagePicker_HongKongChinese,
  LanguagePicker_German,
  LanguagePicker_LebaneseArabic,
  LanguagePicker_Portuguese,
  LanguagePicker_Spanish,
  LanguagePicker_Vietnamese
} from '../translations/messages'

const defaultLocale = 'en-GB'

export const languages = {
  'ar-LB': {
    nameTranslation: LanguagePicker_LebaneseArabic,
    messages: arabicLebaneseMessages
  },
  de: {
    nameTranslation: LanguagePicker_German,
    messages: germanMessages
  },
  'en-AU': {
    nameTranslation: LanguagePicker_AustralianEnglish,
    messages: englishAustralianMessages
  },
  'en-US': {
    nameTranslation: LanguagePicker_AmericanEnglish,
    messages: englishUnitedStatesMessages
  },
  'en-GB': {
    nameTranslation: LanguagePicker_BritishEnglish,
    messages: englishBritishMessages
  },
  es: {
    nameTranslation: LanguagePicker_Spanish,
    messages: spanishMessages
  },
  'fr-CA': {
    nameTranslation: LanguagePicker_CanadianFrench,
    messages: canadianFrenchMessages
  },
  pt: {
    nameTranslation: LanguagePicker_Portuguese,
    messages: portugueseMessages
  },
  'zh-Hans': {
    nameTranslation: LanguagePicker_Chinese,
    messages: chineseSimplifiedMessages
  },
  'zh-HK': {
    nameTranslation: LanguagePicker_HongKongChinese,
    messages: chineseHongKongMessages
  },
  vi: {
    nameTranslation: LanguagePicker_Vietnamese,
    messages: vietnameseMessages
  }
}

export const langHasIntlSupport = (lang: string) => {
  const dateTimeFormatSupportedLangs = Intl?.DateTimeFormat?.supportedLocalesOf?.(lang) || []
  const numberFormatSupportedLangs = Intl?.NumberFormat?.supportedLocalesOf?.(lang) || []
  const pluralRulesSupportedLangs = Intl?.PluralRules?.supportedLocalesOf?.(lang) || []
  const relativeTimeFormatSupportedLangs = Intl?.RelativeTimeFormat?.supportedLocalesOf?.(lang) || []

  const intlSupportedLang = [
    dateTimeFormatSupportedLangs,
    numberFormatSupportedLangs,
    pluralRulesSupportedLangs,
    relativeTimeFormatSupportedLangs
  ].sort()[0][0] // if lang is not supported by one of the Intl api's, then the array will be empty

  return intlSupportedLang === lang
}

function getActiveLanguage() {
  const activeLanguage =
    window.localStorage.getItem('activeLanguage') ||
    (window.Cypress && defaultLocale) ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language

  const hasTranslations = Object.keys(languages).some(l => activeLanguage === l)
  const hasIntlSupport = langHasIntlSupport(activeLanguage)

  return hasTranslations && hasIntlSupport ? activeLanguage : defaultLocale
}

const activeLanguage = getActiveLanguage()

if (document.documentElement) {
  document.documentElement.lang = activeLanguage
}

export const intl: IntlShape = createIntl(
  {
    locale: activeLanguage,
    defaultLocale,
    messages: languages[activeLanguage].messages
  },
  createIntlCache()
)
