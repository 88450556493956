import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useFetcher } from 'react-router-dom'
import { Button, Loading } from '../../shared'
import {
  Common_BrowseCharities,
  PayoutSettings_Donation_Description,
  Common_EditPayout,
  Common_UsePrevious,
  Common_CharityCampaign_PayoutDisclaimer
} from '../../../translations/messages'

type Props = {
  active: PayoutMethodType['active']
  savedConfiguration: PayoutMethodType['savedConfiguration']
}

export const Donation = ({ active, savedConfiguration }: Props) => {
  const navigate = useNavigate()
  const { Form, state } = useFetcher()
  const isLoading = state === 'submitting' || state === 'loading'

  return (
    <>
      <h1>
        <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
      </h1>

      <span>
        <FormattedMessage
          id={PayoutSettings_Donation_Description.id}
          defaultMessage={PayoutSettings_Donation_Description.defaultMessage}
        />
      </span>

      <span>
        <FormattedMessage
          id={Common_CharityCampaign_PayoutDisclaimer.id}
          defaultMessage={Common_CharityCampaign_PayoutDisclaimer.defaultMessage}
        />
      </span>

      {savedConfiguration && !active && (
        <Form method="put">
          <Button
            background="colored"
            aria-disabled={isLoading}
            disabled={isLoading}
            name="payoutType"
            value="DONATION"
          >
            {isLoading ? (
              <Loading />
            ) : (
              <span>
                <FormattedMessage id={Common_UsePrevious.id} defaultMessage={Common_UsePrevious.defaultMessage} />
                <p>
                  <small>{`(${savedConfiguration.charityName} - ${savedConfiguration.campaignName})`}</small>
                </p>
              </span>
            )}
          </Button>
        </Form>
      )}

      <Button onClick={() => navigate('/charities')} border>
        <FormattedMessage id={Common_BrowseCharities.id} defaultMessage={Common_BrowseCharities.defaultMessage} />
      </Button>
    </>
  )
}
