import gtag, { install } from 'ga-gtag'
import { v4 as uuidv4 } from 'uuid'
import { theme, APP_VERSION } from '../lib'
import { getNativeAppVersion } from './react-native-service'

export function setupGtag() {
  const isLocalhost = !!window.location.href.match(/localhost/g)
  const webAppVersion = APP_VERSION.substring(0, 7)
  const nativeAppVersion = getNativeAppVersion()
  const completeAppVersion = nativeAppVersion ? `v/${nativeAppVersion} (${webAppVersion})` : `v/${webAppVersion}`
  let clientId = window.localStorage.getItem('ga_client_id') || ''

  if (!clientId) {
    clientId = uuidv4()
    window.localStorage.setItem('ga_client_id', clientId)
  }

  if (!isLocalhost) {
    install(theme.gtagId, {
      client_storage: 'none',
      anonymize_ip: true,
      client_id: clientId,
      app_name: theme.name,
      app_version: completeAppVersion,
      send_page_view: false,
      custom_map: {
        dimension1: 'detail',
        dimension2: 'pathname'
      }
    })
  }
}

export function sendGtagEvent(type: 'ui_click' | 'ui_other', detail: string, pathname = window.location.pathname) {
  if (!!window.dataLayer) {
    gtag('event', type, {
      detail,
      pathname
    })
  }
}

export function sendGtagPageView(pathname: string) {
  if (!!window.dataLayer) {
    gtag('event', 'page_view', {
      page_title: document.title,
      page_location: window.location.origin,
      page_path: pathname
    })
  }
}
