import React, { useState, useEffect } from 'react'
import { useLoaderData } from 'react-router-dom'
import { pushMessage } from '../AppMessages'
import { ActivitiesPage } from './ActivitiesPage'
import { BackButton } from '../shared'
import { logError } from '../../lib'
import { ActivitiesPage_LoadingActivitiesFailed } from '../../translations/messages'
import { useOnScrolledToBottom } from '../../hooks/useOnScrolledToBottom'
import { fetchMoreActivities, openRateUsDialog } from '../../services'

export const ActivitiesPageContainer = () => {
  const { activities } = useLoaderData() as { activities: RefundsRequestType }
  const { paginatedActivities: initialActivities, refunds } = activities
  const [currentPageEnd, setCurrentPageEnd] = useState<string>(initialActivities.pageEnd)
  const [compoundedActivities, setCompoundedActivities] = useState(initialActivities.activities)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    let timeoutRef

    // If the user has successfully received some refunds, we want to trigger a native app rating dialog
    if (refunds.some(refundsForCurrency => refundsForCurrency.payoutStatistics.totalAmount > 10)) {
      // Just to wait a bit before opening the rating dialog
      timeoutRef = setTimeout(openRateUsDialog, 2000)
    }

    return () => {
      clearTimeout(timeoutRef)
    }
  }, [refunds])

  useOnScrolledToBottom(async () => {
    if (!isLoadingMore && currentPageEnd !== null) {
      setIsLoadingMore(true)

      try {
        const { activities, pageEnd } = await fetchMoreActivities(currentPageEnd)
        setCompoundedActivities([...compoundedActivities, ...activities])
        setCurrentPageEnd(pageEnd)
      } catch (error: any) {
        logError(new Error('Failed to fetch more campaigns'), error)
        pushMessage({
          formattedMessage: ActivitiesPage_LoadingActivitiesFailed,
          type: 'danger',
          ttl: 5000
        })
      }

      setIsLoadingMore(false)
    }
  })

  return (
    <>
      <BackButton to="/" />
      <ActivitiesPage activities={compoundedActivities} isLoadingMore={isLoadingMore} refunds={refunds} />
    </>
  )
}
