import { countryStateMap } from './countryStateMap'
import { getQueryParameterByName } from './getQueryParameterByName'

const resolveDeprecatedEnvCountryState = () => {
  const env = window.location.host.match(/\w{2}(?=\.test|\.prod)/gi)?.[0] || ''
  const activeCountryStateQueryParam = getQueryParameterByName('activeCountryState')

  if (activeCountryStateQueryParam && Object.keys(countryStateMap).some(key => activeCountryStateQueryParam === key)) {
    return [env, activeCountryStateQueryParam]
  }
  if (env === 'au') {
    return [env, 'AU-NSW']
  }
  if (env === 'eu') {
    return [env, 'PT']
  }
  if (env === 'us') {
    return [env, 'US-NY']
  }

  return ['au', 'AU-NSW']
}

// These two can be removed when we no longer deploy to AWS (in which case remember to add default fallback country, state, and env to default setEnv.js in /public)
const [DEPRECATED_ENV, DEPRECATED_COUNTRY_STATE] = resolveDeprecatedEnvCountryState()

const {
  APP_NAME,
  APP_VERSION,
  GOOGLE_MAPS_API_KEY,
  ENV: NEW_ENV,
  STAGE,
  VIEWER_COUNTRY,
  VIEWER_STATE,
  CONFIG_BY_STAGE
} = window.__ENVIRONMENT__

// to be removed when we no longer deploy to AWS
const ENV = NEW_ENV || DEPRECATED_ENV

const { PAYPAL_CLIENT_ID, PAYPAL_AUTH_ENDPOINT, ZEPTO_AUTH_ENDPOINT, CONFIG_BY_ENV } = CONFIG_BY_STAGE[STAGE]
const { API_HOST, ACHIEVEMENTS_API_HOST, KEYCLOAK_HOST } = CONFIG_BY_ENV[ENV]
const VIEWER_COUNTRY_STATE =
  (VIEWER_STATE ? `${VIEWER_COUNTRY}-${VIEWER_STATE}` : VIEWER_COUNTRY) || DEPRECATED_COUNTRY_STATE

export {
  APP_NAME,
  APP_VERSION,
  GOOGLE_MAPS_API_KEY,
  ENV,
  STAGE,
  VIEWER_COUNTRY_STATE,
  API_HOST,
  ACHIEVEMENTS_API_HOST,
  KEYCLOAK_HOST,
  PAYPAL_CLIENT_ID,
  PAYPAL_AUTH_ENDPOINT,
  ZEPTO_AUTH_ENDPOINT
}
