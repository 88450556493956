import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRouteLoaderData, useSubmit, useNavigation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ContentLoader from 'react-content-loader'
import { getQueryParameterByName, theme } from '../../../lib'
import { PayoutQuickSelect } from '../../payout/PayoutQuickSelect'
import { Button, Drawer } from '../../shared'
import { ArrowRightIcon, HeartIcon, VoucherIcon, SavingBankIcon, SettingsIcon } from '../../icons'
import {
  ActivePayout_Title,
  ActivePayout_SetupPayout,
  Common_DigitalVoucher,
  Common_Donation,
  Common_PayPal,
  Common_BankTransfer
} from '../../../translations/messages'

const Container = styled.div`
  margin-top: var(--spacing-lg);

  > small {
    display: block;
    font-weight: 500;
    margin-left: var(--spacing-md);
    margin-bottom: var(--spacing-sm);
  }
`

const payoutNameAndIconMap = (payoutMethod: PayoutMethodType | undefined) => {
  const { type, recipient, logoUrl } = payoutMethod || {}

  switch (type) {
    case 'PAYPAL':
      return {
        name: <FormattedMessage id={Common_PayPal.id} defaultMessage={Common_PayPal.defaultMessage} />,
        icon: <img src={theme.images.paypalLogo} alt="paypal" height="24px" />
      }
    case 'E_VOUCHER':
      return {
        name: <FormattedMessage id={Common_DigitalVoucher.id} defaultMessage={Common_DigitalVoucher.defaultMessage} />,
        icon: <VoucherIcon />
      }
    case 'DONATION':
      return {
        name: recipient ? (
          recipient
        ) : (
          <FormattedMessage id={Common_Donation.id} defaultMessage={Common_Donation.defaultMessage} />
        ),
        icon: logoUrl ? (
          <img src={logoUrl} alt="donation" height="30px" width="30px" style={{ borderRadius: 'var(--radius-lg)' }} />
        ) : (
          <HeartIcon />
        )
      }
    case 'BANK_TRANSFER':
      return {
        name: <FormattedMessage id={Common_BankTransfer.id} defaultMessage={Common_BankTransfer.defaultMessage} />,
        icon: <SavingBankIcon />
      }
    default:
      return {
        name: (
          <FormattedMessage id={ActivePayout_SetupPayout.id} defaultMessage={ActivePayout_SetupPayout.defaultMessage} />
        ),
        icon: <SettingsIcon />
      }
  }
}

const LoadingPlaceholder = () => {
  return (
    <ContentLoader
      height="3.5rem"
      width="100%"
      viewBox="0 0 21rem 3.5rem"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="2rem" cy="1.8rem" r="1.2rem" />
      <rect x="25%" y="1rem" rx="3" ry="3" width="12rem" height="1.4rem" />
    </ContentLoader>
  )
}

type LoaderData = {
  payoutMethods: PayoutMethodsRequestType
}

export const ActivePayout = () => {
  const submit = useSubmit()
  const { state } = useNavigation()
  const navigate = useNavigate()
  const { payoutMethods } = useRouteLoaderData('home-root') as LoaderData
  const [showQuickSelectDrawer, setShowQuickSelectDrawer] = useState(false)
  const activePayoutMethod = payoutMethods?.find(payoutMethod => payoutMethod.active === true)
  const hasPreConfiguredPayoutMethod = payoutMethods?.find(payoutMethod => payoutMethod.savedConfiguration)
  const { name, icon } = payoutNameAndIconMap(activePayoutMethod)
  const isLoading = state === 'submitting' || state === 'loading'

  useEffect(() => {
    const encodedpayload = getQueryParameterByName('encodedpayload')

    if (encodedpayload) {
      const { type, recipient } = JSON.parse(atob(encodedpayload))
      const formData = new FormData()
      formData.append('payoutType', type)
      formData.append('recipient', recipient)
      submit(formData, { method: 'post', action: '/payoutsettings' })
    }
  }, [submit])

  return (
    <Container>
      <small>
        <FormattedMessage id={ActivePayout_Title.id} defaultMessage={ActivePayout_Title.defaultMessage} />
      </small>

      <Button
        style={{ justifyContent: 'space-between' }}
        onClick={
          hasPreConfiguredPayoutMethod ? () => setShowQuickSelectDrawer(true) : () => navigate('/payoutsettings')
        }
      >
        {isLoading ? (
          <LoadingPlaceholder />
        ) : (
          <>
            {icon}
            <span>{name}</span>
            <ArrowRightIcon />
          </>
        )}
      </Button>

      <Drawer
        style={{ padding: 'var(--spacing-lg) var(--spacing-md)' }}
        onClose={() => setShowQuickSelectDrawer(false)}
        visible={showQuickSelectDrawer}
      >
        <PayoutQuickSelect />
      </Drawer>
    </Container>
  )
}
