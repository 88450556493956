import appStoreLogo from './appStores/appStore.png'
import playStoreLogo from './appStores/playStore.png'
import australiaCampaign from './misc/australiaCampaign.png'
import noActivity from './misc/noActivity.svg'
import mapFavorite from './map/mapFavorite.svg'
import paypalLogo from './logos/paypal.svg'
import tomraTextLogo from './logos/tomraTextLogo.svg'
import colesLogo from './logos/coles.png'
import woolworthsLogo from './logos/woolworths.png'
import temporaryEuroshopVoucherLogo from './logos/temporaryEuroshopVoucherLogo.svg'
import poweredByTomraLogo from './logos/poweredByTomra.svg'
import donationHeart from './charity/donationHeart.svg'
import error from './feedback/error.svg'
import success from './feedback/success.svg'

export const sharedImages = {
  appStoreLogo,
  playStoreLogo,
  australiaCampaign,
  noActivity,
  tomraTextLogo,
  mapFavorite,
  paypalLogo,
  colesLogo,
  woolworthsLogo,
  temporaryEuroshopVoucherLogo,
  poweredByTomraLogo,
  donationHeart,
  error,
  success
}
