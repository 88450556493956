import { fetch as _fetch, fetchData as _fetchData } from '@tomra/client-side-http-client'
import { ActionFunctionArgs } from 'react-router-dom'
import { getNativeNotificationToken, getNativeDeviceInfo } from './react-native-service'
import { pushMessage } from '../components/AppMessages'
import { API_HOST, authentication, logError, getQueryParameterByName, APP_NAME, VIEWER_COUNTRY_STATE } from '../lib'
import { Common_UpdatePayoutFailure, PayoutSettings_Locked, Common_UpdatePayoutSuccess } from '../translations/messages'

const authenticatedFetchRaw = (url: string, options?: RequestInit) => _fetch(url, options, authentication)

const authenticatedFetchData = (url: string, options?: RequestInit) => _fetchData(url, options, authentication)

export function fetchProfile() {
  const params = new URLSearchParams(window.location.search)
  params.set('nativeAppName', APP_NAME)
  params.set('activeCountryState', VIEWER_COUNTRY_STATE)

  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/profile?${params.toString()}`).run()
}

export function fetchVouchers() {
  return authenticatedFetchData(`${API_HOST}/mytomra/v2.0/vouchers`).run()
}

export function fetchPayoutMethods() {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/accounting/payout`)
    .run()
    .then(response => response[0]?.payoutMethods || [])
}

export function fetchRefundsAndActivities() {
  return (
    authenticatedFetchData(`${API_HOST}/mytomra/v1.0/refunds`)
      .run()
      // initially fetch one more set of paginated activities by default
      .then((result: RefundsRequestType) =>
        result.paginatedActivities.pageEnd
          ? fetchMoreActivities(result.paginatedActivities.pageEnd).then(
              (moreResult: RefundsRequestType['paginatedActivities']) => ({
                ...result,
                paginatedActivities: {
                  activities: [...result.paginatedActivities.activities, ...moreResult.activities],
                  pageEnd: moreResult.pageEnd
                }
              })
            )
          : result
      )
  )
}

export function fetchMoreActivities(pageStart) {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/refunds/activities?pageStart=${pageStart}`).run()
}

export function fetchAchievements() {
  return authenticatedFetchData(`${API_HOST}/mytomra-achievements/achievements`).run()
}

export async function updatePayout({ request }: ActionFunctionArgs) {
  const method = request.method.toUpperCase()
  const text = await request.text() // Would use formData() but isn't supported in iOS <=13
  const type = getQueryParameterByName('payoutType', `?${text}`)
  const recipient = getQueryParameterByName('recipient', `?${text}`)
  const options = recipient ? { method, body: JSON.stringify({ recipient, type }) } : { method }
  const url = recipient
    ? `${API_HOST}/mytomra/v1.0/accounting/payout`
    : `${API_HOST}/mytomra/v1.0/accounting/payout/${type}`

  return authenticatedFetchData(url, options)
    .run()
    .then(
      () => {
        pushMessage({
          formattedMessage: Common_UpdatePayoutSuccess,
          type: 'success',
          ttl: 5000
        })

        return null
      },
      error => {
        pushMessage({
          formattedMessage: error.status === 409 ? PayoutSettings_Locked : Common_UpdatePayoutFailure,
          type: 'danger',
          ttl: 5000
        })

        return error
      }
    )
}

export function retryPayment() {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/activities/retry`, {
    method: 'POST'
  })
}

export function updateMarketingsubscription(subscribeStatus: 'SUBSCRIBED' | 'UNSUBSCRIBED') {
  return authenticatedFetchData(
    `${API_HOST}/mytomra/v1.0/profile/marketingsubscription?subscribeStatus=${subscribeStatus}`,
    {
      method: 'POST'
    }
  )
}

export function fetchMarketingsubscription() {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/profile/marketingsubscription`)
}

export function updateUser(input: {
  email?: string
  firstName?: string
  lastName?: string
  activeCountryState?: string
}) {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/user`, {
    method: 'PATCH',
    body: JSON.stringify(input)
  })
}

export function updatePassword(password: string) {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/user/credentials`, {
    method: 'PUT',
    body: JSON.stringify({ password })
  })
}

export function deleteUser() {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/user`, {
    method: 'DELETE'
  })
}

export function syncNotificationTokenWithBackend() {
  const notificationToken = getNativeNotificationToken()
  const OS = getNativeDeviceInfo()?.OS

  if (notificationToken && OS) {
    const checkExistingUrl = `${API_HOST}/user-notifications/v2.0/mytomra/device/${notificationToken}`
    const registerUrl = `${API_HOST}/user-notifications/v2.0/mytomra/device/${notificationToken}/register-${OS.toLowerCase()}-device?nativeAppName=${APP_NAME}`

    authenticatedFetchRaw(checkExistingUrl)
      .run()
      .catch(authenticatedFetchRaw(registerUrl, { method: 'POST' }).run)
      .catch(error => {
        logError(new Error('Failed to register notification device'), error)
      })
  }
}

export function unregisterPushNotifications(notificationToken = getNativeNotificationToken()) {
  if (notificationToken) {
    return authenticatedFetchData(
      `${API_HOST}/user-notifications/v2.0/mytomra/device/${notificationToken}/unregister-device`,
      {
        method: 'DELETE'
      }
    )
      .run()
      .catch(error => {
        logError(new Error('Failed to unregister notification device'), error)
      })
  }
}

export function acceptTermsAndConditions() {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/user/terms?nativeAppName=${APP_NAME}`, {
    method: 'POST'
  }).run()
}

export function registerBagtag(bagtag: string) {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/bag`, {
    method: 'POST',
    body: JSON.stringify({
      bagtag
    })
  }).run()
}

export function registerQrCode(code: string) {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/internal/qrcode`, {
    method: 'POST',
    body: JSON.stringify({
      code
    })
  }).run()
}

export function checkContainerEligibility(barcode: string, activeCountryState: string, isRetryAttempt = false) {
  return authenticatedFetchData(`${API_HOST}/mytomra/v1.0/${activeCountryState.toLowerCase()}/eligibility/${barcode}`, {
    method: 'GET'
  })
    .run()
    .catch(error => {
      // try again with 0 prefix if missing and without if not
      // see https://support.honeywellaidc.com/s/article/Can-EAN13-symbols-start-with-a-leading-zero
      return isRetryAttempt
        ? Promise.reject(error)
        : checkContainerEligibility(
            barcode.startsWith('0') ? barcode.substring(1) : `0${barcode}`,
            activeCountryState,
            true
          )
    })
}
