import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ZeptoBrokerPage_AuthErrorMsg } from '../../translations/messages'
import { Alert, Loading } from '../shared'
import { getQueryParameterByName, APP_NAME } from '../../lib'

type ResultType = 'success' | 'failure' | 'cancel'

export const ZeptoBrokerPage = () => {
  const { result } = useParams<{ result: ResultType }>()

  const redirectWithContactId = useCallback(contactId => {
    const encodedpayload = btoa(JSON.stringify({ type: 'BANK_TRANSFER', recipient: contactId }))
    // if window.opener exists, we are not on native
    if (window.opener) {
      window.opener.location = `${window.location.origin}?encodedpayload=${encodedpayload}`
      window.close()
    } else {
      window.location.href = `${APP_NAME.toLowerCase()}://brokers?encodedpayload=${encodedpayload}`
    }
  }, [])

  const redirect = useCallback(() => {
    // if window.opener exists, we are not on native
    if (window.opener) {
      window.opener.location = `${window.location.origin}`
      window.close()
    } else {
      window.location.href = `${APP_NAME.toLowerCase()}://brokers`
    }
  }, [])

  useEffect(() => {
    if (result === 'success') {
      const contactId = getQueryParameterByName('contact_id')
      redirectWithContactId(contactId)
    }
    if (result === 'cancel') {
      redirect()
    }
  }, [result, redirectWithContactId, redirect])

  if (result === 'failure') {
    return (
      <React.Fragment>
        <Alert className="centerAbsolute">
          <FormattedMessage
            id={ZeptoBrokerPage_AuthErrorMsg.id}
            defaultMessage={ZeptoBrokerPage_AuthErrorMsg.defaultMessage}
          />
        </Alert>
      </React.Fragment>
    )
  }

  return (
    <div>
      <Loading className="centerAbsolute" />
    </div>
  )
}
