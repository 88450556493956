import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'core-js/actual/array/entries'
import 'normalize.css'
import 'animate.css'
import './styles/content.css'
import './lib/barcodeDetectorPolyfill'
import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { RawIntlProvider } from 'react-intl'
import { intl, theme, authentication } from './lib'
import { App } from './App'
import { Loading } from './components/shared'

const noOp = () => {}

document.title = theme.name
document.getElementById('favicon')?.setAttribute('href', theme.faviconPath)

/*
if (STAGE === 'test' && !window.Cypress && 'serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/serviceWorker.js')
    .catch(error => logError(new Error('Failed to register service worker'), error))
}
*/

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

const Index = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    authentication
      .init()
      .catch(noOp)
      .finally(() => setIsLoading(false))
  }, [])

  return isLoading ? <Loading className="centerAbsolute" /> : <App />
}

root.render(
  <RawIntlProvider value={intl}>
    <Index />
  </RawIntlProvider>
)
