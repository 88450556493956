import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { IconButton } from '../../shared/common'
import { intl } from '../../../lib'
import { ActivitiesPage_SendTaxReceipts } from '../../../translations/messages'
import { ExportIcon } from '../../icons'
import { TaxReceiptDrawer } from './TaxReceiptDrawer'

export const SendDonationTaxReceipts = () => {
  const { payoutMethods } = useLoaderData() as { payoutMethods: PayoutMethodsRequestType }
  const [showReceiptDrawer, setShowReceiptDrawer] = useState<boolean>(false)
  const donationPayoutAvailable = payoutMethods?.find(method => method.type === 'DONATION')

  return donationPayoutAvailable ? (
    <>
      <IconButton
        aria-label={intl.formatMessage(ActivitiesPage_SendTaxReceipts)}
        onClick={() => setShowReceiptDrawer(true)}
      >
        <ExportIcon />
      </IconButton>

      <TaxReceiptDrawer show={showReceiptDrawer} onClose={() => setShowReceiptDrawer(false)} />
    </>
  ) : null
}
