import React from 'react'
import { useLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { PayoutOptionCard } from './PayoutOptionCard'
import { BackButton, FeedbackMessage } from '../shared'
import {
  PayoutSettings_NoPayoutOptions,
  Common_EditPayout,
  PayoutSettings_AddOrEdit
} from '../../translations/messages'

const Container = styled.div`
  padding: var(--spacing-md);
  max-width: var(--max-page-width);
  margin: 0 auto;

  > button {
    margin-top: var(--spacing-md);
  }
`

const Header = styled.div`
  margin: 3rem 0 var(--spacing-md) var(--spacing-md);

  > h1 {
    margin-bottom: var(--spacing-lg);
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin-bottom: var(--spacing-md);
  }
`

export const PayoutSettingsPage = () => {
  const payoutMethods = useLoaderData() as PayoutMethodsRequestType

  return (
    <Container>
      <BackButton to=".." />

      {payoutMethods.length === 0 ? (
        <FeedbackMessage className="centerAbsolute animated fadeIn medium">
          <FormattedMessage
            id={PayoutSettings_NoPayoutOptions.id}
            defaultMessage={PayoutSettings_NoPayoutOptions.defaultMessage}
          />
        </FeedbackMessage>
      ) : (
        <>
          <Header>
            <h1>
              <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
            </h1>

            <b>
              <FormattedMessage
                id={PayoutSettings_AddOrEdit.id}
                defaultMessage={PayoutSettings_AddOrEdit.defaultMessage}
              />
            </b>
          </Header>

          <InnerContainer>
            {payoutMethods.map((payoutMethod, index) => (
              <PayoutOptionCard {...payoutMethod} key={index} />
            ))}
          </InnerContainer>
        </>
      )}
    </Container>
  )
}
