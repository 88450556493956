import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Button } from '../shared'
import {
  Common_Ok,
  VerifyEmail_FirstDescription,
  VerifyEmail_SecondDescription,
  VerifyEmail_Title
} from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
`

type Props = {
  closeDrawer: () => void
  newEmail: string
}

export const ConfirmEditEmail = ({ closeDrawer, newEmail }: Props) => {
  return (
    <Container>
      <h1>
        <FormattedMessage id={VerifyEmail_Title.id} defaultMessage={VerifyEmail_Title.defaultMessage} />
      </h1>

      <p>
        <FormattedMessage
          id={VerifyEmail_FirstDescription.id}
          defaultMessage={VerifyEmail_FirstDescription.defaultMessage}
        />
      </p>

      <p style={{ fontWeight: 'bold' }}>{newEmail}</p>

      <p>
        <FormattedMessage
          id={VerifyEmail_SecondDescription.id}
          defaultMessage={VerifyEmail_SecondDescription.defaultMessage}
        />
      </p>

      <Button onClick={closeDrawer}>
        <FormattedMessage id={Common_Ok.id} defaultMessage={Common_Ok.defaultMessage} />
      </Button>
    </Container>
  )
}
