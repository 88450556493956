import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Loading } from '../shared'
import { PasswordStrength } from './PasswordStrength'
import { TextField, Button } from '../shared'
import { getUserInfoFromJwt, logError, intl, authentication } from '../../lib'
import {
  Auth_ConfirmNewPassword,
  Auth_NewPassword,
  Auth_OldPassword,
  Auth_PasswordConditionsNotMet,
  Auth_PasswordsDontMatch,
  Common_TechnicalIssues,
  Common_Great,
  Common_ChangePassword,
  Common_Update,
  Common_Uhoh,
  ChangePasswordDrawer_Feedback_Success_Description,
  ChangePasswordDrawer_PasswordVerifyFailed
} from '../../translations/messages'
import { updatePassword } from '../../services'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > h1,
  > span {
    margin-bottom: var(--spacing-lg);
  }

  > button {
    margin-top: var(--spacing-lg);
  }
`

const Feedback = styled.div`
  padding: var(--spacing-xl) 0;
  text-align: center;

  > h1,
  > span {
    margin-bottom: var(--spacing-lg);
  }
`

const ErrorText = styled.div`
  color: var(--colors-red);
`

type State = {
  oldPassword: string
  oldPasswordValid: boolean
  newPassword: string
  newPasswordValid: boolean
  confirmedPassword: string
  confirmedPasswordValid: boolean
  requestStatus: RequestStatusType | 'verifyPasswordFailed'
}

export class ChangePassword extends React.Component<{}, State> {
  _abortChangePassword = () => {}

  state: State = {
    oldPassword: '',
    oldPasswordValid: false,
    newPassword: '',
    newPasswordValid: false,
    confirmedPassword: '',
    confirmedPasswordValid: false,
    requestStatus: 'initial'
  }

  _performUpdateRequest = async () => {
    const { oldPassword, newPassword } = this.state

    try {
      const { email } = getUserInfoFromJwt()
      this.setState({ requestStatus: 'loading' })

      await authentication.checkExistingKCPasswordValidity(email, oldPassword)
      const { run, abort } = updatePassword(newPassword)
      this._abortChangePassword = abort
      await run()

      this.setState({ requestStatus: 'success' })
    } catch (error: any) {
      if (error.name === 'InvalidPasswordError') {
        this.setState({ requestStatus: 'verifyPasswordFailed' })
      } else if (error.name !== 'AbortError') {
        this.setState({ requestStatus: 'failed' })
        logError(new Error('Failed to update user password'), error)
      }
    }
  }

  componentWillUnmount() {
    this._abortChangePassword()
  }

  render() {
    const {
      oldPassword,
      oldPasswordValid,
      newPassword,
      newPasswordValid,
      confirmedPassword,
      confirmedPasswordValid,
      requestStatus
    } = this.state

    const updateButtonDisabled =
      requestStatus === 'loading' || !oldPasswordValid || !newPasswordValid || !confirmedPasswordValid

    return requestStatus === 'success' ? (
      <Feedback className="animated fadeIn medium">
        <h1>
          <FormattedMessage id={Common_Great.id} defaultMessage={Common_Great.defaultMessage} />
        </h1>
        <span>
          <FormattedMessage
            id={ChangePasswordDrawer_Feedback_Success_Description.id}
            defaultMessage={ChangePasswordDrawer_Feedback_Success_Description.defaultMessage}
          />
        </span>
      </Feedback>
    ) : requestStatus === 'failed' ? (
      <Feedback className="animated fadeIn medium">
        <h1>
          <FormattedMessage id={Common_Uhoh.id} defaultMessage={Common_Uhoh.defaultMessage} />
        </h1>
        <span>
          <FormattedMessage id={Common_TechnicalIssues.id} defaultMessage={Common_TechnicalIssues.defaultMessage} />
        </span>
      </Feedback>
    ) : (
      <Container className="animated fadeIn medium">
        <h1>
          <FormattedMessage id={Common_ChangePassword.id} defaultMessage={Common_ChangePassword.defaultMessage} />
        </h1>

        <TextField
          ariaLabel={intl.formatMessage(Auth_OldPassword)}
          onChange={(oldPassword, oldPasswordValid) => {
            this.setState({ oldPassword, oldPasswordValid })

            if (requestStatus === 'verifyPasswordFailed') {
              this.setState({ requestStatus: 'initial' })
            }
          }}
          value={oldPassword}
          valid={oldPasswordValid}
          label={intl.formatMessage(Auth_OldPassword)}
          type="password"
          autocomplete="current-password"
          required
        />

        <TextField
          ariaLabel={intl.formatMessage(Auth_NewPassword)}
          onChange={(newPassword, newPasswordValid) => {
            this.setState({
              newPassword,
              newPasswordValid,
              confirmedPasswordValid: confirmedPassword === newPassword
            })
          }}
          value={newPassword}
          valid={newPasswordValid}
          label={intl.formatMessage(Auth_NewPassword)}
          errorMsg={intl.formatMessage(Auth_PasswordConditionsNotMet)}
          type="password"
          pattern="^.{5,}$"
          autocomplete="new-password"
        >
          {newPasswordValid && <PasswordStrength passwordValue={newPassword} />}
        </TextField>

        <TextField
          ariaLabel={intl.formatMessage(Auth_ConfirmNewPassword)}
          onChange={confirmedPassword => {
            this.setState({
              confirmedPassword,
              confirmedPasswordValid: confirmedPassword === newPassword
            })
          }}
          value={confirmedPassword}
          valid={confirmedPasswordValid}
          label={intl.formatMessage(Auth_ConfirmNewPassword)}
          errorMsg={intl.formatMessage(Auth_PasswordsDontMatch)}
          type="password"
          autocomplete="new-password"
        />

        {requestStatus === 'verifyPasswordFailed' && (
          <ErrorText className="animated fadeIn medium">
            <FormattedMessage
              id={ChangePasswordDrawer_PasswordVerifyFailed.id}
              defaultMessage={ChangePasswordDrawer_PasswordVerifyFailed.defaultMessage}
            />
          </ErrorText>
        )}

        <Button onClick={this._performUpdateRequest} disabled={updateButtonDisabled} background="colored">
          {requestStatus === 'loading' ? (
            <Loading />
          ) : (
            <FormattedMessage id={Common_Update.id} defaultMessage={Common_Update.defaultMessage} />
          )}
        </Button>
      </Container>
    )
  }
}
