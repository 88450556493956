import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Button, Loading } from '../common'
import { ArrowDownIcon, ArrowRightIcon } from '../../icons'
import { countryStateMap, VIEWER_COUNTRY_STATE } from '../../../lib'
import {
  CountryStatePicker_Title,
  CountryStatePicker_Description,
  CountryStatePicker_Location,
  Common_Confirm
} from '../../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    margin-bottom: var(--spacing-xl);
  }
`

const SelectContainer = styled.div`
  position: relative;
  margin-top: var(--spacing-lg);

  > label {
    position: absolute;
    top: -60%;
    font-weight: bold;
    left: var(--spacing-md);
  }

  > svg {
    position: absolute;
    top: 50%;
    right: var(--spacing-md);
    transform: translateY(-50%);
    pointer-events: none;
  }
`

const StyledSelect = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  appearance: none;
  border-radius: 100px;
  background-color: white;
  box-shadow: var(--shadow-sm);
  padding: var(--spacing-md) var(--spacing-xl) var(--spacing-md) var(--spacing-lg);
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: black;
  :focus {
    outline: none;
  }
`

export const CountryStatePicker = () => {
  const [loading, setLoading] = useState(false)
  const [selectedCountryStateCode, setSelectedCountryStateCode] = useState(VIEWER_COUNTRY_STATE)

  const onClick = () => {
    setLoading(true)
    const [country, state] = selectedCountryStateCode.split('-')

    window.localStorage.setItem('VIEWER_COUNTRY_OVERRIDE', country)
    state
      ? window.localStorage.setItem('VIEWER_STATE_OVERRIDE', state)
      : window.localStorage.removeItem('VIEWER_STATE_OVERRIDE')
    window.localStorage.setItem('ENV_OVERRIDE', countryStateMap[selectedCountryStateCode].env)

    window.location.href = '/'
  }

  return (
    <Container>
      <h1>
        <FormattedMessage id={CountryStatePicker_Title.id} defaultMessage={CountryStatePicker_Title.defaultMessage} />
      </h1>

      <p>
        <FormattedMessage
          id={CountryStatePicker_Description.id}
          defaultMessage={CountryStatePicker_Description.defaultMessage}
        />
      </p>

      <SelectContainer>
        <label htmlFor="country">
          <FormattedMessage
            id={CountryStatePicker_Location.id}
            defaultMessage={CountryStatePicker_Location.defaultMessage}
          />
        </label>

        <StyledSelect
          id="country"
          value={selectedCountryStateCode}
          onChange={evt => setSelectedCountryStateCode(evt.target.value)}
        >
          {Object.keys(countryStateMap)
            .sort()
            .map(countryStateCode => (
              <option key={countryStateCode} value={countryStateCode}>
                {countryStateMap[countryStateCode].name}
              </option>
            ))}
        </StyledSelect>

        <ArrowDownIcon size="0.87rem" color="black" />
      </SelectContainer>

      <Button onClick={onClick} background="colored" disabled={loading} aria-disabled={loading}>
        <div />
        <span>
          {loading ? (
            <Loading />
          ) : (
            <FormattedMessage id={Common_Confirm.id} defaultMessage={Common_Confirm.defaultMessage} />
          )}
        </span>
        <ArrowRightIcon />
      </Button>
    </Container>
  )
}
