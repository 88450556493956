import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { BagdropButton } from './BagdropButton'
import { BagdropButtonNative } from './BagdropButtonNative'
import { QRCodeButtonNative } from './QRCodeButtonNative'
import { QRCodeButton } from './QRCodeButton'
import { BarcodeButton } from './BarcodeButton'
import { ActivePayout } from './ActivePayout'
import { Settings } from './Settings'
import { IconButton, Drawer } from '../../shared'
import { hasWebCameraScannerSupport } from '../../../services'
import { MenuIcon } from '../../icons'
import { getUserInfoFromJwt, countryStateMap, VIEWER_COUNTRY_STATE } from '../../../lib'
import { Header_Greeting, Header_ReadyToRecycle } from '../../../translations/messages'

const Container = styled.div`
  padding: var(--spacing-md);
  width: 100%;
  background-color: var(--colors-primary);
`

const InnerContainer = styled.div`
  position: relative;
  max-width: var(--max-page-width);
  margin: 0 auto;
`

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: var(--spacing-xl);
`

const Greeting = styled.div`
  padding: var(--spacing-lg) 0 var(--spacing-xl) var(--spacing-md);
`

const MenuButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

type Props = {
  barcode: string
}

export const Header = ({ barcode }: Props) => {
  const [showSettings, setShowSettings] = useState(false)
  const jwtName = getUserInfoFromJwt().firstName
  const { features } = countryStateMap[VIEWER_COUNTRY_STATE]

  return (
    <>
      <Container className="animated fadeIn medium">
        <InnerContainer>
          <MenuButton title="settings" onClick={() => setShowSettings(!showSettings)}>
            <MenuIcon size="2.33rem" />
          </MenuButton>

          <Greeting>
            <h1>
              <FormattedMessage
                id={Header_Greeting.id}
                defaultMessage={Header_Greeting.defaultMessage}
                values={{ name: jwtName === 'Anonymous' ? '' : jwtName }}
              />
            </h1>

            <span>
              <FormattedMessage id={Header_ReadyToRecycle.id} defaultMessage={Header_ReadyToRecycle.defaultMessage} />
            </span>
          </Greeting>

          <ButtonContainer>
            <BarcodeButton barcodeValue={barcode} countryStateFeatures={features} />

            {hasWebCameraScannerSupport() ? (
              <BagdropButton countryStateFeatures={features} />
            ) : (
              <BagdropButtonNative countryStateFeatures={features} />
            )}
            {hasWebCameraScannerSupport() ? (
              <QRCodeButton countryStateFeatures={features} />
            ) : (
              <QRCodeButtonNative countryStateFeatures={features} />
            )}
          </ButtonContainer>

          <ActivePayout />
        </InnerContainer>
      </Container>

      <Drawer side="right" onClose={() => setShowSettings(!showSettings)} visible={showSettings}>
        <Settings />
      </Drawer>
    </>
  )
}
