import React from 'react'
import styled from 'styled-components'
import { intl } from '../../lib'
import { commonPasswords } from '../shared/common-passwords'
import {
  PasswordStrength_Good,
  PasswordStrength_Medium,
  PasswordStrength_Title,
  PasswordStrength_Weak,
  Common_Great
} from '../../translations/messages'

const messages = {
  '1': PasswordStrength_Weak,
  '2': PasswordStrength_Medium,
  '3': PasswordStrength_Good,
  '4': Common_Great
}

const Container = styled.b`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Strength = styled.b`
  color: ${props => props.color};
`

Strength.displayName = 'Strength'

type Props = {
  passwordValue: string
}

export const PasswordStrength = ({ passwordValue }: Props) => {
  function determinePasswordStrength(passwordValue) {
    const hasLowercase = !!passwordValue.match(/[a-z]+/)
    const hasUppercase = !!passwordValue.match(/[A-Z]+/)
    const hasDigit = !!passwordValue.match(/\d+/)
    const hasSpecialCharacter = !!passwordValue.match(/[".,:;'!@#$%^&*_+=|(){}[?\-\]/\\]/)
    const hasComplexity = hasDigit || hasSpecialCharacter
    const hasGoodLength = passwordValue.length >= 10
    const hasExcellentLength = passwordValue.length >= 12
    const isCommon = commonPasswords.indexOf(passwordValue) !== -1

    // Common or short passwords are weak
    if (isCommon || !hasGoodLength) {
      return 1
    }

    if (hasExcellentLength) {
      return 4
    }

    if ((hasLowercase || hasUppercase) && hasComplexity) {
      return 4
    }

    if (hasLowercase && hasUppercase) {
      return 3
    }

    return 2
  }

  const strength = determinePasswordStrength(passwordValue)

  let color

  if (strength === 1) {
    color = 'var(--colors-red)'
  } else if (strength === 2) {
    color = '#f26522'
  } else if (strength === 3 || strength === 4) {
    color = '#006b55'
  }

  return (
    <Container color={color} className="animated fadeIn medium">
      <small>{intl.formatMessage(PasswordStrength_Title)}</small>
      <Strength color={color}>{messages[strength] && intl.formatMessage(messages[strength])}</Strength>
    </Container>
  )
}
