import { useCallback, useEffect, useState } from 'react'

export const useOnScrolledToBottom = (scrolledToBottomCallback: () => void) => {
  const [prevPosition, setPrevPosition] = useState<number>(window.scrollY + window.innerHeight)
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | undefined>()

  const onScroll = useCallback(() => {
    setScrollDirection(prevPosition > window.scrollY + window.innerHeight ? 'up' : 'down')

    if (window.scrollY + window.innerHeight >= document.body.offsetHeight) {
      if (scrollDirection === 'down') {
        scrolledToBottomCallback()
        setScrollDirection(undefined)
      }
    }

    setPrevPosition(window.scrollY + window.innerHeight)
  }, [prevPosition, setScrollDirection, scrolledToBottomCallback, scrollDirection])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll, prevPosition])
}
