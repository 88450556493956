import React from 'react'
import { FormattedMessage } from 'react-intl'
import { LinkButton } from '../../shared'
import { LocationIcon, ArrowRightIcon } from '../../icons'
import { FindLocation_ButtonText } from '../../../translations/messages'

export const FindLocation = () => (
  <div>
    <LinkButton to="/locations">
      <LocationIcon />
      <FormattedMessage id={FindLocation_ButtonText.id} defaultMessage={FindLocation_ButtonText.defaultMessage} />
      <ArrowRightIcon />
    </LinkButton>
  </div>
)
