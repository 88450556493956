import React, { useEffect } from 'react'
import { AppMessages, pushMessage, removeMessage } from './components/AppMessages'
import { AppUpdatedDrawer } from './components/AppUpdatedDrawer'
import { NativeAppUpdatedDrawer } from './components/NativeAppUpdatedDrawer'
import { ConnectionDownIcon } from './components/icons'
import { preloadAppImages, theme, authentication, getUserInfoFromJwt, userMustAcceptTerms } from './lib'
import { App_ConnectionDown } from './translations/messages'
import { setupGtag, sendGtagPageView, syncNotificationTokenWithBackend, acceptTermsAndConditions } from './services'
import { AuthenticatedRouter, UnAuthenticatedRouter } from './routes'

const setConnectionOnline = () => {
  removeMessage()
}

const setConnectionOffline = () => {
  pushMessage({
    formattedMessage: App_ConnectionDown,
    icon: <ConnectionDownIcon color="black" size="1.67rem" />,
    type: 'danger'
  })
}

const handleNativeMessage = (nativeAction: any) => {
  try {
    const { type } = typeof nativeAction.data === 'object' ? nativeAction.data : JSON.parse(nativeAction.data)

    if (type === 'NAVIGATE_BACK' && !window.location.pathname.includes('/')) {
      window.history.back()
    }
  } catch (error) {
    // dont care
  }
}

export const App = () => {
  const pathname = window.location.pathname

  if (!document.title.includes(pathname)) {
    document.title = `${theme.name} - ${pathname}`
    sendGtagPageView(pathname)
  }

  useEffect(() => {
    if (authentication.getToken()) {
      syncNotificationTokenWithBackend()
      userMustAcceptTerms() && acceptTermsAndConditions()
      window.DD_RUM && window.DD_RUM.setUser({ id: getUserInfoFromJwt().userId })
    }

    preloadAppImages()
    setupGtag()

    window.addEventListener('online', setConnectionOnline)
    window.addEventListener('offline', setConnectionOffline)

    // need to use window for iOS and document for android
    window.addEventListener('message', handleNativeMessage)
    document.addEventListener('message', handleNativeMessage)

    return () => {
      window.removeEventListener('online', setConnectionOnline)
      window.removeEventListener('offline', setConnectionOffline)

      // need to use window for iOS and document for android
      window.removeEventListener('message', handleNativeMessage)
      document.removeEventListener('message', handleNativeMessage)
    }
  }, [])

  return (
    <>
      <AppMessages />
      <AppUpdatedDrawer />
      <NativeAppUpdatedDrawer />

      {authentication.getToken() ? <AuthenticatedRouter /> : <UnAuthenticatedRouter />}
    </>
  )
}
