import React, { useEffect, useState } from 'react'
import { Alert, Loading } from '../shared'
import { FormattedMessage } from 'react-intl'
import { getQueryParameterByName, logError, APP_NAME } from '../../lib'
import { PayPalBrokerPage_AuthErrorMsg } from '../../translations/messages'

export const PayPalBrokerPage = () => {
  const [hasError, setHasError] = useState<boolean>(false)

  const redirectWithOAuthCode = code => {
    const encodedpayload = btoa(JSON.stringify({ type: 'PAYPAL', recipient: code }))
    // if window.opener exists, we are not on native
    if (window.opener) {
      window.opener.location = `${window.location.origin}?encodedpayload=${encodedpayload}`
      window.close()
    } else {
      window.location.href = `${APP_NAME.toLowerCase()}://brokers?encodedpayload=${encodedpayload}`
    }
  }

  const redirect = () => {
    // if window.opener exists, we are not on native
    if (window.opener) {
      window.opener.location = `${window.location.origin}`
      window.close()
    } else {
      window.location.href = `${APP_NAME.toLowerCase()}://brokers`
    }
  }

  useEffect(() => {
    const code = getQueryParameterByName('code')
    const error_description = getQueryParameterByName('error_description')

    try {
      if (error_description === 'Authentication failed' || error_description === 'Consent denied') redirect()
      if (error_description) throw new Error(error_description)
      if (!code) throw new Error('code not present in url')

      redirectWithOAuthCode(code)
    } catch (error: any) {
      logError(new Error('PayPal brokering failed'), error)
      setHasError(true)
    }
  }, [])

  if (hasError) {
    return (
      <React.Fragment>
        <Alert className="centerAbsolute animated zoomIn fast">
          <FormattedMessage
            id={PayPalBrokerPage_AuthErrorMsg.id}
            defaultMessage={PayPalBrokerPage_AuthErrorMsg.defaultMessage}
          />
        </Alert>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Loading className="centerAbsolute" />
    </React.Fragment>
  )
}
