import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ListButton } from '../shared'
import { openNativeCamera, hasContainerScannerSupport } from '../../services'
import { API_HOST, intl, authentication, VIEWER_COUNTRY_STATE } from '../../lib'
import { BarcodeScannerIcon, ArrowRightIcon } from '../icons'
import {
  ContainerScanner_Title,
  NativeTranslations_ContainerScanner_Description,
  NativeTranslations_ContainerScanner_Success,
  NativeTranslations_ContainerScanner_Failed,
  Common_GotIt,
  Common_SessionExpired,
  Common_TechnicalIssues,
  Common_Done
} from '../../translations/messages'

export const ContainerScannerButtonNative = () => {
  const shouldBeVisible = VIEWER_COUNTRY_STATE === 'AU-NSW' && hasContainerScannerSupport()

  useEffect(() => {
    const handleLogoutMessage = (nativeAction: any) => {
      try {
        const { type } = typeof nativeAction.data === 'object' ? nativeAction.data : JSON.parse(nativeAction.data)

        if (type === 'LOGIN_REQUIRED') {
          authentication.logout()
        }
      } catch (error) {
        // Don't care
      }
    }

    // need to use window.addEventListener on iOS and document.addEventListener for android
    window.addEventListener('message', handleLogoutMessage)
    document.addEventListener('message', handleLogoutMessage)

    return () => {
      // need to use window.addEventListener on iOS and document.addEventListener for android
      window.removeEventListener('message', handleLogoutMessage)
      document.removeEventListener('message', handleLogoutMessage)
    }
  }, [])

  const openNativeCameraWithPayload = () => {
    const fetchConfig = {
      apiHost: `${API_HOST}/mytomra/v1.0/${VIEWER_COUNTRY_STATE?.toLowerCase()}/eligibility`,
      accessToken: authentication.getToken()
    }

    const messages = {
      doneButton: intl.formatMessage(Common_Done),
      errorButton: intl.formatMessage(Common_GotIt),
      description: intl.formatMessage(NativeTranslations_ContainerScanner_Description),
      registerResult: {
        '204': intl.formatMessage(NativeTranslations_ContainerScanner_Success),
        '404': intl.formatMessage(NativeTranslations_ContainerScanner_Failed),
        '401': intl.formatMessage(Common_SessionExpired),
        genericError: intl.formatMessage(Common_TechnicalIssues)
      }
    }

    openNativeCamera({ fetchConfig, messages, cameraType: 'CONTAINER_SCANNER' })
  }

  return shouldBeVisible ? (
    <ListButton borderBottom onClick={openNativeCameraWithPayload}>
      <div>
        <BarcodeScannerIcon color="black" />
        <span>
          <FormattedMessage id={ContainerScanner_Title.id} defaultMessage={ContainerScanner_Title.defaultMessage} />
        </span>
      </div>
      <ArrowRightIcon />
    </ListButton>
  ) : null
}
