import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { isOnNative, openEmbeddedBrowserInNativeWrapper } from '../../../services'
import { CharityInfoDrawer_VisitWebsite } from '../../../translations/messages'
import { Drawer } from '../../shared/common'

const Abn = styled.div`
  > * {
    display: inline;
  }
`

const CharityLink = styled.b`
  text-decoration: underline;
  font-size: var(--font-size-lg);
  margin-top: var(--spacing-md);
  display: block;
  cursor: pointer;
`

type Props = {
  charityDisplayName: string
  charityABN: string
  charityDescription: string
  charityWebsiteUrl: string | null
  onClose: () => void
  visible: boolean
}

export const CharityInfoDrawer = ({
  charityDisplayName,
  charityABN,
  charityDescription,
  charityWebsiteUrl,
  onClose,
  visible
}: Props) => {
  const onCharityLinkClick = () => {
    const normalizedCharityUrl = !!charityWebsiteUrl?.match(/^(http|https)/g)
      ? charityWebsiteUrl
      : `https://${charityWebsiteUrl}`

    isOnNative() ? openEmbeddedBrowserInNativeWrapper(normalizedCharityUrl) : window.open(normalizedCharityUrl)
  }

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      style={{ backgroundColor: 'var(--colors-tertiary-light)', textAlign: 'center' }}
    >
      <h1>{charityDisplayName}</h1>

      <Abn>
        <b style={{ marginRight: 'var(--spacing-xs)' }}>ABN</b>
        <p>{charityABN}</p>
      </Abn>

      <p style={{ marginTop: 'var(--spacing-md)' }}>{charityDescription}</p>

      {charityWebsiteUrl && (
        <CharityLink onClick={onCharityLinkClick}>
          <FormattedMessage
            id={CharityInfoDrawer_VisitWebsite.id}
            defaultMessage={CharityInfoDrawer_VisitWebsite.defaultMessage}
          />
        </CharityLink>
      )}
    </Drawer>
  )
}
