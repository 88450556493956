import { isOnNative, openEmbeddedBrowserInNativeWrapper } from './react-native-service'
import { getUserInfoFromJwt, ZEPTO_AUTH_ENDPOINT } from '../lib'

export function authenticateZeptoPaymentUser() {
  const { firstName, lastName, email } = getUserInfoFromJwt()
  const encodedEmail = encodeURIComponent(email)
  const name = encodeURIComponent(`${firstName} ${lastName}`)
  const successUrl = encodeURIComponent(`${window.location.origin}/broker/split/success`)
  const failureUrl = encodeURIComponent(`${window.location.origin}/broker/split/failure`)
  const cancelUrl = encodeURIComponent(`${window.location.origin}/broker/split/cancel`)
  const headerTitle = encodeURIComponent('Payment setup')
  const url = `${ZEPTO_AUTH_ENDPOINT}/TOMRA?name=${name}&email=${encodedEmail}&success_url=${successUrl}&failure_url=${failureUrl}&cancel_url=${cancelUrl}&header_title=${headerTitle}&whitelabel=1`

  isOnNative() ? openEmbeddedBrowserInNativeWrapper(url) : window.open(url)
}
