import { logError, APP_NAME } from '../lib'

type ActionType =
  | 'SET_BARCODE'
  | 'OPEN_URL'
  | 'GET_USER_LOCATION'
  | 'OPEN_CAMERA'
  | 'REGISTER_BAGTAG_RESULT'
  | 'OPEN_APP_URL'
  | 'SET_WEBVIEW_URL_ROOT'
  | 'OPEN_RATE_US_DIALOG'
  | 'OPEN_COUNTRY_PICKER'
  | 'SET_AND_SAVE_NEW_APP_URL'

type Action = {
  type: ActionType
  payload?: any
  error?: boolean
}

function createAction(type: ActionType, payload?: any, error: boolean = false): Action {
  return {
    type,
    payload,
    error
  }
}

function dispatchToNativeWrapper(action: Action): void {
  if (!isOnNative()) {
    logError(new Error('React Native wrapper postMessage is not available'))
  }

  /*
  Would just rebind like window.ReactNativeWebView.postMessage || window.postMessage
  but that changes the functions "this" which causes Android webview to get real mad
  */
  // @ts-ignore
  if (window.ReactNativeWebView?.postMessage) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(action))
  } else {
    // normal window.postMessage expects two arguments
    // as opposed to window.ReactNativeWebView.postMessage
    window.postMessage(JSON.stringify(action), '*')
  }
}

export function getNativeAppVersion() {
  return window.Tomra?.nativeAppVersion
}

export function getNativeDeviceInfo() {
  return window.Tomra?.nativeDeviceInfo
}

export function getNativeNotificationToken() {
  return window.Tomra?.notificationToken || ''
}

export function isOnNative() {
  return !!getNativeAppVersion()
}

export function hasEmbeddedBrowserSupport() {
  if (APP_NAME === 'MYTOMRA') {
    return satisfiesNativeAppVersion({ ios: '4.5', android: '4.7' })
  } else if (APP_NAME === 'RETURNANDEARN') {
    return satisfiesNativeAppVersion({ ios: '1.2', android: '1.2' })
  } else {
    return true
  }
}

export function hasContainerScannerSupport() {
  if (APP_NAME === 'MYTOMRA') {
    return satisfiesNativeAppVersion({ ios: '4.5', android: '4.7' })
  } else if (APP_NAME === 'RETURNANDEARN') {
    return satisfiesNativeAppVersion({ ios: '1.3', android: '1.3' })
  } else {
    return true
  }
}

export function hasWebCameraScannerSupport() {
  if (!isOnNative()) {
    return true
  } else if (APP_NAME === 'MYTOMRA') {
    return satisfiesNativeAppVersion({ ios: '4.6', android: '4.8' })
  } else if (APP_NAME === 'RETURNANDEARN') {
    return satisfiesNativeAppVersion({ ios: '1.4', android: '1.4' })
  } else {
    return true
  }
}

export function satisfiesNativeAppVersion({ ios, android }: { ios: string; android: string }) {
  const OS = getNativeDeviceInfo()?.OS
  const actualVersion = getNativeAppVersion()

  if (OS && actualVersion) {
    const actualVersionParts = actualVersion.split('.')
    const checkVersionParts = (OS === 'ANDROID' ? android : ios).split('.')
    const actualMajorVersion = parseInt(actualVersionParts[0], 10)
    const checkMajorVersion = parseInt(checkVersionParts[0], 10)
    const actualMinorVersion = parseInt(actualVersionParts[1], 10)
    const checkMinorVersion = parseInt(checkVersionParts[1], 10)

    return (
      actualMajorVersion > checkMajorVersion ||
      (actualMajorVersion === checkMajorVersion && actualMinorVersion >= checkMinorVersion)
    )
  }
}

export function pushBarcodeToNativeWrapper(barcodeValue: string) {
  dispatchToNativeWrapper(createAction('SET_BARCODE', barcodeValue))
}

export function openEmbeddedBrowserInNativeWrapper(url: string) {
  dispatchToNativeWrapper(createAction('OPEN_URL', url))
}

export function askForUserLocationNative() {
  dispatchToNativeWrapper(createAction('GET_USER_LOCATION'))
}

export function openNativeCamera(payload: Object) {
  dispatchToNativeWrapper(createAction('OPEN_CAMERA', payload))
}

export function openAppUrl(url: string) {
  dispatchToNativeWrapper(createAction('OPEN_APP_URL', url))
}

export function openRateUsDialog() {
  dispatchToNativeWrapper(createAction('OPEN_RATE_US_DIALOG'))
}

export function setAndSaveAppUrl(url: string) {
  dispatchToNativeWrapper(createAction('SET_AND_SAVE_NEW_APP_URL', url))
}
