import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const LockIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <rect x="3.75" y="9.75" width="16.5" height="13.5" rx="1.5" ry="1.5" />
    <path d="M6.75,9.75V6a5.25,5.25,0,0,1,10.5,0V9.75" />
    <circle cx="12" cy="16.5" r="2.25" />
  </svg>
)
