import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'

const variableWidthAndColorOne = keyframes`
  from {
    width: 0vw;
    background-color: black;
  }

  to {
    width: 100vw;
    background-color: var(--colors-primary);
  }
`

const variableWidthAndColorTwo = keyframes`
  from {
    width: 100vw;
    background-color: var(--colors-primary);
  }

  to {
    width: 0vw;
    background-color: black;
  }
`

const LoadingBarOne = styled.span`
  height: 0.5rem;
  animation: ${variableWidthAndColorOne} 1s cubic-bezier(0.68, 0.31, 0.28, 0.63) 0s infinite;
`

const LoadingBarTwo = styled.span`
  height: 0.5rem;
  animation: ${variableWidthAndColorTwo} 1s cubic-bezier(0.68, 0.31, 0.28, 0.63) 0s infinite;
`

const Container = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
`

export const NavigateLoading = () =>
  ReactDOM.createPortal(
    <Container className="animated fadeIn medium">
      <LoadingBarOne />
      <LoadingBarTwo />
    </Container>,
    document.getElementById('root') as HTMLDivElement
  )
