import React from 'react'
import styled from 'styled-components'
import { logError } from '../../lib'
import { ErrorIcon, CameraBorderIcon } from '../icons'

const Video = styled.video`
  object-fit: cover;
  height: 80vh;
  width: 100%;
`

const ErrorContainer = styled.div`
  height: 80vh;
  width: 100%;
  background-color: var(--colors-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;

  > * {
    margin: var(--spacing-lg) 0;
  }
`

const Description = styled.p`
  background-color: var(--colors-primary);
  text-align: center;
  padding: var(--spacing-md) var(--spacing-xl);
`

type Props = {
  description: string
  onRead: (value: string) => void
}

type State = {
  errorMsg: string
}

export class CameraScanner extends React.Component<Props, State> {
  _intervalRef = 0
  _stream?: MediaStream
  _reader?: any
  _videoElement?: HTMLVideoElement

  state: State = {
    errorMsg: ''
  }

  _attemptReadBarcode = async () => {
    try {
      const result = await this._reader.detect(this._videoElement)
      const maybeBarcode = result?.[0]?.rawValue

      // Perform basic saniation check (no special characters except dash)
      if (maybeBarcode && !!maybeBarcode.match(/^[a-zA-Z0-9-]{2,}$/gi)) {
        this.props.onRead(maybeBarcode)
      }
    } catch (error: any) {
      this.setState({ errorMsg: error.message })
      logError(new Error('Failed to read barcode'), error)
    }
  }

  _setupReader = () => {
    this._reader = new window.BarcodeDetector()

    this._intervalRef = window.setInterval(() => {
      this._attemptReadBarcode()
    }, 500)
  }

  _setupCamera = async () => {
    try {
      if (!window.BarcodeDetector || !navigator.mediaDevices.getUserMedia) {
        throw new Error("So sorry, your device doesn't support camera scanning.")
      }

      this._stream = await navigator.mediaDevices.getUserMedia({
        // @ts-ignore
        video: { facingMode: 'environment', focusMode: 'continuous' },
        audio: false
      })

      const videoElement = document.getElementById('cameraScanner') as HTMLVideoElement

      if (videoElement) {
        this._videoElement = videoElement
        this._videoElement.srcObject = this._stream
        this._videoElement.addEventListener('canplay', this._setupReader)
      } else {
        // Stop tracks if we unmount before stream is ready
        this._stream?.getTracks()?.forEach(track => track?.stop())
      }
    } catch (error: any) {
      this.setState({ errorMsg: error.message })
      logError(new Error('Failed to setup camera'), error)
    }
  }

  componentDidMount() {
    this._setupCamera()
  }

  componentWillUnmount() {
    clearInterval(this._intervalRef)
    this._videoElement?.removeEventListener('canplay', this._setupReader)
    this._stream?.getTracks()?.forEach(track => track?.stop())
  }

  render() {
    return this.state.errorMsg ? (
      <ErrorContainer>
        <ErrorIcon size="100" />
        <p>{this.state.errorMsg}</p>
      </ErrorContainer>
    ) : (
      <>
        <Description>{this.props.description}</Description>
        <Video autoPlay muted playsInline id="cameraScanner" />
        <CameraBorderIcon
          size="100vw"
          style={{ maxHeight: '70vh', opacity: 0.7 }}
          color="white"
          className="centerAbsolute"
        />
      </>
    )
  }
}
