import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme, APP_NAME } from '../../../lib'

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--radius-sm);
  cursor: pointer;
  box-shadow: var(--shadow-sm);
`

export const AustraliaCampaignCard = () =>
  APP_NAME === 'RETURNANDEARN' ? (
    <Link to="/charities">
      <Image
        className="animated zoomIn fast"
        src={theme.images.australiaCampaign}
        alt="Click here to help our wildlife"
      />
    </Link>
  ) : null
