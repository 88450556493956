import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { fetchMarketingsubscription, updateMarketingsubscription } from '../../services'
import { pushMessage } from '../AppMessages'
import { logError } from '../../lib'
import { Checkbox } from '../shared'
import {
  MorePage_ButtonList_SubscribeMarketing,
  NewsLetterSetting_Subscribed,
  NewsLetterSetting_Unsubscribed,
  NewsLetterSetting_UpdateFailed
} from '../../translations/messages'

const Container = styled.div<{ disabled }>`
  display: flex;
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  > label {
    margin-left: var(--spacing-md);
    cursor: pointer;
  }
`

export const NewsLetterSetting = () => {
  const [subscribed, setSubscribed] = useState<'SUBSCRIBED' | 'UNSUBSCRIBED'>()

  useEffect(() => {
    const f = fetchMarketingsubscription()
    f.run()
      .then(({ status }) => setSubscribed(status))
      .catch(() => setSubscribed('UNSUBSCRIBED'))

    return () => {
      f.abort()
    }
  }, [])

  const setMarketingSubscription = () => {
    const oldStatus = subscribed
    const newStatus = subscribed === 'SUBSCRIBED' ? 'UNSUBSCRIBED' : 'SUBSCRIBED'

    setSubscribed(newStatus)

    updateMarketingsubscription(newStatus)
      .run()
      .then(
        () => {
          pushMessage({
            formattedMessage:
              newStatus === 'SUBSCRIBED' ? NewsLetterSetting_Subscribed : NewsLetterSetting_Unsubscribed,
            type: 'success',
            ttl: 5000
          })
        },
        error => {
          setSubscribed(oldStatus)
          pushMessage({
            formattedMessage: NewsLetterSetting_UpdateFailed,
            type: 'danger',
            ttl: 5000
          })
          logError(new Error('Failed to update marketingsubscription'), error)
        }
      )
  }

  return (
    <Container disabled={!subscribed}>
      <Checkbox
        id="newsletterCheckbox"
        aria-label="Subscribe to newsletter"
        aria-disabled={!subscribed}
        onChange={setMarketingSubscription}
        checked={subscribed === 'SUBSCRIBED'}
      />

      <label htmlFor="newsletterCheckbox">
        <FormattedMessage
          id={MorePage_ButtonList_SubscribeMarketing.id}
          defaultMessage={MorePage_ButtonList_SubscribeMarketing.defaultMessage}
        />
      </label>
    </Container>
  )
}
