import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { pushMessage } from '../../AppMessages'
import {
  BrowseCharitiesPage_FailedToLoadCampaigns,
  BrowseCharitiesPage_NoMatch,
  BrowseCharitiesPage_ShowingCampaigns,
  BrowseCharitiesPage_Filter,
  BrowseCharitiesPage_SortBy,
  BrowseCharitiesPage_ClearSorting,
  BrowseCharitiesPage_ClearFilter
} from '../../../translations/messages'
import { loadMoreCharityCampaigns, searchCharityCampaigns } from '../../../services'
import { logError } from '../../../lib'
import { useOnScrolledToBottom } from '../../../hooks/useOnScrolledToBottom'
import { benefitOptions, orderOptions } from '../common/filterOptions'
import { BrowseCampaignsLoader } from '../common/BrowseCampaignsLoader'
import { CharityCampaignCard, Filter, SearchField } from '../common'
import { FeedbackMessage } from '../../shared/common'

const Container = styled.div`
  padding: var(--spacing-md) var(--spacing-lg);
  display: flex;
  flex-direction: column;
  max-width: var(--max-page-width);
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
`

const SearchContainer = styled.div`
  margin-bottom: var(--spacing-lg);
`

const SearchResults = styled.div`
  margin-top: var(--spacing-md);
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: var(--spacing-sm);
`

const NumResults = styled.small`
  display: block;
  margin-bottom: var(--spacing-sm);
  font-weight: bold;
`

const FilterWrapper = styled.div`
  margin-top: var(--spacing-lg);
  display: flex;
`

export const BrowseCharitiesPage = () => {
  const {
    campaigns: initialCampaigns,
    pageEnd: initialPageEnd,
    totalCampaigns: initialTotalCampaigns
  } = useLoaderData() as CharityRequestType
  const [currentPageEnd, setCurrentPageEnd] = useState<number | null>(initialPageEnd)

  const [totalCampaigns, setTotalCampaigns] = useState<number>(initialTotalCampaigns)
  const [searchResult, setSearchResult] = useState<CharityCampaignType[]>(initialCampaigns)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<CharityBenefitType | null>(null)
  const [searchOrder, setSearchOrder] = useState<CharitySearchOrderType | null>(null)
  const [status, setStatus] = useState<RequestStatusType>('initial')

  const hasResults = searchResult.length > 0
  const noResults = status !== 'loading' && searchResult.length === 0

  useOnScrolledToBottom(() => {
    if (status !== 'loading' && currentPageEnd !== null) {
      setStatus('loading')

      loadMoreCharityCampaigns(searchTerm, searchFilter, searchOrder, currentPageEnd)
        .run()
        .then(({ totalCampaigns, campaigns, pageEnd }) => {
          setStatus('initial')
          setSearchResult([...searchResult, ...campaigns])
          setTotalCampaigns(totalCampaigns)
          setCurrentPageEnd(pageEnd)
        })
        .catch(error => {
          logError(new Error('Failed to fetch more campaigns'), error)
          setStatus('failed')
        })
    }
  })

  useEffect(() => {
    setStatus('loading')
    setSearchResult([])
    const request = searchCharityCampaigns(searchTerm, searchFilter, searchOrder)

    const timer = setTimeout(() => {
      request
        .run()
        .then(({ totalCampaigns, campaigns, pageEnd }) => {
          setSearchResult(campaigns)
          setTotalCampaigns(totalCampaigns)
          setCurrentPageEnd(pageEnd)
        })
        .catch(error => {
          if (error.name !== 'AbortError') {
            logError(new Error('Failed to search for charities'), error)

            pushMessage({
              formattedMessage: BrowseCharitiesPage_FailedToLoadCampaigns,
              type: 'danger',
              ttl: 5000
            })
          }
        })
        .finally(() => setStatus('initial'))
    }, 500)

    return () => {
      clearTimeout(timer)
      request.abort()
    }
  }, [searchTerm, searchFilter, searchOrder])

  return (
    <Container>
      <SearchContainer>
        <SearchField
          value={searchTerm}
          onChange={value => {
            setCurrentPageEnd(0)
            setSearchTerm(value)
          }}
        />
        <FilterWrapper>
          <Filter
            label={
              searchFilter ? BrowseCharitiesPage_ClearFilter.defaultMessage : BrowseCharitiesPage_Filter.defaultMessage
            }
            options={benefitOptions}
            defaultValue={searchFilter ?? ''}
            onChange={newValue => {
              setCurrentPageEnd(0)
              setSearchFilter(newValue as CharityBenefitType)
            }}
          />
          <Filter
            label={
              searchOrder ? BrowseCharitiesPage_ClearSorting.defaultMessage : BrowseCharitiesPage_SortBy.defaultMessage
            }
            options={orderOptions}
            defaultValue={searchOrder ?? ''}
            onChange={newValue => {
              setCurrentPageEnd(0)
              setSearchOrder(newValue as CharitySearchOrderType)
            }}
          />
        </FilterWrapper>
      </SearchContainer>

      {hasResults && (
        <SearchResults>
          <NumResults>
            <FormattedMessage
              id={BrowseCharitiesPage_ShowingCampaigns.id}
              defaultMessage={BrowseCharitiesPage_ShowingCampaigns.defaultMessage}
              values={{ num: totalCampaigns ?? 0 }}
            />
          </NumResults>

          <List>
            {searchResult!.map(campaign => (
              <ListItem key={campaign.id}>
                <CharityCampaignCard campaign={campaign} />
              </ListItem>
            ))}
          </List>
        </SearchResults>
      )}

      {noResults && status !== 'failed' && (
        <FeedbackMessage>
          <FormattedMessage
            id={BrowseCharitiesPage_NoMatch.id}
            defaultMessage={BrowseCharitiesPage_NoMatch.defaultMessage}
          />
        </FeedbackMessage>
      )}

      {status === 'failed' && (
        <FeedbackMessage>
          <FormattedMessage
            id={BrowseCharitiesPage_FailedToLoadCampaigns.id}
            defaultMessage={BrowseCharitiesPage_FailedToLoadCampaigns.defaultMessage}
          />
        </FeedbackMessage>
      )}

      {status === 'loading' && <BrowseCampaignsLoader count={1} />}
    </Container>
  )
}
