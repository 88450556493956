import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import { intl } from '../../lib'
import { BackButton, Button, Checkbox, Drawer } from '../shared'
import { useNavigate } from 'react-router-dom'
import { ConfirmDelete } from './ConfirmDelete'
import { FormattedMessage } from 'react-intl'
import {
  Common_Cancel,
  Common_DeleteAccount,
  DeleteAccount_ListOne_ItemOne,
  DeleteAccount_ListOne_ItemThree,
  DeleteAccount_ListOne_ItemTwo,
  DeleteAccount_ListOne_Title,
  DeleteAccount_ListTwo_ItemOne,
  DeleteAccount_ListTwo_Title,
  DeleteAccount_PermanenceDisclaimerLabel,
  DeleteAccount_RefundsDisclaimerLabel,
  DeleteAccount_RemainingRefundsWarning
} from '../../translations/messages'
import { ErrorIcon } from '../icons'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  margin: 0 auto;
  max-width: var(--max-page-width);
`

const Header = styled.div`
  margin: 3rem 0 var(--spacing-md) var(--spacing-md);
`

const Warning = styled.div`
  background-color: white;
  border-left: solid red;
  box-shadow: var(--shadow-sm);
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: var(--spacing-md) var(--spacing-sm) var(--spacing-md) var(--spacing-md);
  font-weight: bold;
  > div:not(:first-of-type) {
    margin-left: var(--spacing-md);
  }
`

const InnerContainer = styled.div`
  padding: var(--spacing-md) var(--spacing-sm) var(--spacing-md) var(--spacing-md);
  > div,
  p {
    margin-bottom: var(--spacing-md);
  }
`

const List = styled.ul`
  list-style-position: inside;
`

const CheckboxContainer = styled.div`
  display: flex;
  font-weight: bold;

  > label {
    margin-left: var(--spacing-md);
    cursor: pointer;
  }
`

const ButtonRow = styled.span`
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;

  > button {
    margin: var(--spacing-sm);
  }
`

export const DeleteAccount = () => {
  const { refunds } = useLoaderData() as RefundsRequestType
  const navigate = useNavigate()
  const [checkboxAccepted, setCheckboxAccepted] = useState(false)
  const [showConfirmDeleteDrawer, setShowConfirmDeleteDrawer] = useState(false)

  const balanceSum = refunds[0]?.balanceStatistics.totalAmount || 0
  const hasRefunds = balanceSum !== 0
  const currency = refunds[0]?.currency

  return (
    <>
      {showConfirmDeleteDrawer && (
        <Drawer visible={showConfirmDeleteDrawer} onClose={() => setShowConfirmDeleteDrawer(false)}>
          <ConfirmDelete onClose={() => setShowConfirmDeleteDrawer(false)} />
        </Drawer>
      )}

      <Container>
        <BackButton />

        <Header>
          <h1>
            <FormattedMessage id={Common_DeleteAccount.id} defaultMessage={Common_DeleteAccount.defaultMessage} />
          </h1>
        </Header>

        {hasRefunds && (
          <Warning>
            <div>
              <ErrorIcon size="2rem" color="var(--colors-red)" />
            </div>
            <div>
              <FormattedMessage
                id={DeleteAccount_RemainingRefundsWarning.id}
                defaultMessage={DeleteAccount_RemainingRefundsWarning.defaultMessage}
                values={{
                  outstandingBalance: intl
                    .formatNumber(balanceSum, {
                      style: 'currency',
                      currencyDisplay: 'symbol',
                      currency: currency || 'AUD'
                    })
                    .replace(/^.*(?=(\$))/, '')
                }}
              />
            </div>
          </Warning>
        )}
        <InnerContainer>
          <div>
            <b>
              <FormattedMessage
                id={DeleteAccount_ListOne_Title.id}
                defaultMessage={DeleteAccount_ListOne_Title.defaultMessage}
              />
            </b>
            <List>
              <li>
                <FormattedMessage
                  id={DeleteAccount_ListOne_ItemOne.id}
                  defaultMessage={DeleteAccount_ListOne_ItemOne.defaultMessage}
                />
              </li>
              <li>
                <FormattedMessage
                  id={DeleteAccount_ListOne_ItemTwo.id}
                  defaultMessage={DeleteAccount_ListOne_ItemTwo.defaultMessage}
                />
              </li>
              <li>
                <FormattedMessage
                  id={DeleteAccount_ListOne_ItemThree.id}
                  defaultMessage={DeleteAccount_ListOne_ItemThree.defaultMessage}
                />
              </li>
            </List>
          </div>
          <div>
            <b>
              <FormattedMessage
                id={DeleteAccount_ListTwo_Title.id}
                defaultMessage={DeleteAccount_ListTwo_Title.defaultMessage}
              />
            </b>
            <List>
              <li>
                <FormattedMessage
                  id={DeleteAccount_ListTwo_ItemOne.id}
                  defaultMessage={DeleteAccount_ListTwo_ItemOne.defaultMessage}
                />
              </li>
            </List>
          </div>

          <CheckboxContainer>
            <Checkbox
              id="delete-account-disclaimer"
              onChange={() => setCheckboxAccepted(!checkboxAccepted)}
              checked={checkboxAccepted}
            />
            {hasRefunds ? (
              <label htmlFor="delete-account-disclaimer">
                <FormattedMessage
                  id={DeleteAccount_RefundsDisclaimerLabel.id}
                  defaultMessage={DeleteAccount_RefundsDisclaimerLabel.defaultMessage}
                />
              </label>
            ) : (
              <label htmlFor="delete-account-disclaimer">
                <FormattedMessage
                  id={DeleteAccount_PermanenceDisclaimerLabel.id}
                  defaultMessage={DeleteAccount_PermanenceDisclaimerLabel.defaultMessage}
                />
              </label>
            )}
          </CheckboxContainer>
        </InnerContainer>

        <ButtonRow>
          <Button border onClick={() => navigate(-1)}>
            <FormattedMessage id={Common_Cancel.id} defaultMessage={Common_Cancel.defaultMessage} />
          </Button>
          <Button background="danger" onClick={() => setShowConfirmDeleteDrawer(true)} disabled={!checkboxAccepted}>
            <FormattedMessage id={Common_DeleteAccount.id} defaultMessage={Common_DeleteAccount.defaultMessage} />
          </Button>
        </ButtonRow>
      </Container>
    </>
  )
}
