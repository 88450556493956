import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Card, Currency } from '../../shared/common'
import { Logo } from './Logo'
import { calculateDaysBetween } from '../../../lib'
import { FormattedMessage } from 'react-intl'
import {
  Common_CampaignEnded,
  CampaignInfo_DaysLeft,
  CampaignInfo_EndingToday,
  Common_By,
  MyDonationsPage_YourContribution
} from '../../../translations/messages'
import { benefitToTranslationMap } from './charityTranslationMap'

const Campaign = styled.div`
  padding: var(--spacing-sm) var(--spacing-md);
  display: grid;
  grid-template-areas:
    'image category'
    'image content';
  grid-template-columns: auto 1fr;
  align-items: center;
`

const CardBody = styled.div`
  grid-area: content;
  padding-bottom: var(--spacing-md);
`

const Title = styled.h2`
  font-weight: bold;
`

const Meta = styled.div`
  margin-top: var(--spacing-md);
`

const Contribution = styled.div`
  border-top: 2px solid var(--colors-tertiary);
  padding: var(--spacing-sm) var(--spacing-md);
  text-align: center;
  background: var(--colors-tertiary-light);
`

const Benefit = styled.small`
  grid-area: category;
  margin-bottom: var(--spacing-sm);
  font-weight: bold;
  text-align: right;
`

type Props = {
  donation: Donation
}

export const CharityDonationCard: FunctionComponent<Props> = ({ donation }) => {
  const { campaignLogoUrl, campaignName, charityDisplayName, contributionSum, benefit, endTime, campaignId, status } =
    donation
  const daysLeft = calculateDaysBetween(new Date().getTime(), endTime)
  const campaignHasEnded = status === 'STOPPED' || daysLeft < 0

  return (
    <Link to={`../${campaignId}`}>
      <Card style={{ color: 'initial' }}>
        <Campaign>
          <Benefit>
            <FormattedMessage
              id={benefitToTranslationMap[benefit].id}
              defaultMessage={benefitToTranslationMap[benefit].defaultMessage}
            />
          </Benefit>

          <Logo
            style={{ gridArea: 'image' }}
            className="animated fadeIn medium"
            src={campaignLogoUrl}
            alt={`${campaignName} logo`}
          />

          <CardBody>
            <Title>{campaignName}</Title>
            <FormattedMessage id={Common_By.id} defaultMessage={Common_By.defaultMessage} /> {charityDisplayName}
            {campaignHasEnded ? (
              <Meta style={{ color: 'var(--colors-red)' }}>
                <FormattedMessage id={Common_CampaignEnded.id} defaultMessage={Common_CampaignEnded.defaultMessage} />
              </Meta>
            ) : daysLeft === 0 ? (
              <Meta>
                <FormattedMessage
                  id={CampaignInfo_EndingToday.id}
                  defaultMessage={CampaignInfo_EndingToday.defaultMessage}
                />
              </Meta>
            ) : (
              <Meta>
                <FormattedMessage
                  id={CampaignInfo_DaysLeft.id}
                  defaultMessage={CampaignInfo_DaysLeft.defaultMessage}
                  values={{ daysLeft }}
                />
              </Meta>
            )}
          </CardBody>
        </Campaign>

        <Contribution>
          <FormattedMessage
            id={MyDonationsPage_YourContribution.id}
            defaultMessage={MyDonationsPage_YourContribution.defaultMessage}
          />

          <h3>
            <Currency amount={contributionSum || 0} currency={'AUD'} />
          </h3>
        </Contribution>
      </Card>
    </Link>
  )
}
