import React from 'react'
import styled from 'styled-components'
import { useNavigation, useRouteLoaderData, Form } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button, LinkButton, Loading } from '../shared'
import { payoutLogoAndTranslationMap } from './payoutLogoAndTranslationMap'
import {
  Common_EditPayout,
  PayoutSettings_QuickSelect_Title,
  PayoutSettings_Selected
} from '../../translations/messages'

const StyledButton = styled(Button)`
  margin-top: var(--spacing-md);
  font-weight: normal;
`

const Radio = styled.div<{ selected: boolean }>`
  position: relative;
  border: 2px solid black;
  border-radius: var(--radius-lg);
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;

  :after {
    display: ${props => (props.selected ? 'initial' : 'none')};
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: var(--radius-lg);
    background-color: black;
  }
`

type LoaderData = {
  profile: ProfileRequestType
  payoutMethods: PayoutMethodsRequestType
}

export const PayoutQuickSelect = () => {
  const { state, formData } = useNavigation()
  const { payoutMethods } = useRouteLoaderData('home-root') as LoaderData
  const updatingType = formData?.get('payoutType')
  const isLoading = formData && (state === 'submitting' || state === 'loading')

  const applicableMethods = payoutMethods?.filter(
    ({ type, savedConfiguration }) => savedConfiguration !== null || type === 'E_VOUCHER'
  )

  return (
    <Form method="put" action="/payoutsettings">
      <b style={{ marginLeft: 'var(--spacing-md)' }}>
        <FormattedMessage
          id={PayoutSettings_QuickSelect_Title.id}
          defaultMessage={PayoutSettings_QuickSelect_Title.defaultMessage}
        />
      </b>

      {applicableMethods?.map(({ type, active, savedConfiguration, logoUrl }: PayoutMethodType) => {
        const { logo, name } = payoutLogoAndTranslationMap[type]
        const isDisabled = (updatingType === type || active) && isLoading

        return (
          <StyledButton aria-disabled={isDisabled} disabled={isDisabled} name="payoutType" value={type} key={type}>
            {logoUrl ? <img src={logoUrl} alt="charity logo" height="28px" /> : logo}

            <span>
              {name}
              {savedConfiguration && (
                <p>
                  <small>
                    {savedConfiguration?.email ||
                      savedConfiguration?.maskedAccountNumber ||
                      savedConfiguration?.campaignName}
                  </small>
                </p>
              )}
            </span>

            {updatingType === type ? <Loading /> : <Radio selected={active} />}

            {active && (
              <span className="visually-hidden">
                <FormattedMessage
                  id={PayoutSettings_Selected.id}
                  defaultMessage={PayoutSettings_Selected.defaultMessage}
                />
              </span>
            )}
          </StyledButton>
        )
      })}

      <LinkButton to="../payoutsettings" border style={{ marginTop: '4rem' }}>
        <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
      </LinkButton>
    </Form>
  )
}
