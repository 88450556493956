import defaultBadge from './achievements/badges/default.png'
import unknownBadge from './achievements/badges/unknown.svg'
import trophy1 from './achievements/badges/trophy1.svg'
import trophy2 from './achievements/badges/trophy2.svg'
import trophy3 from './achievements/badges/trophy3.svg'
import trophy4 from './achievements/badges/trophy4.svg'
import digitalPayout1 from './achievements/badges/digitalPayout1.svg'
import digitalPayout2 from './achievements/badges/digitalPayout2.svg'
import digitalPayout3 from './achievements/badges/digitalPayout3.svg'
import digitalPayout4 from './achievements/badges/digitalPayout4.svg'
import locations1 from './achievements/badges/locations1.svg'
import locations2 from './achievements/badges/locations2.svg'
import locations3 from './achievements/badges/locations3.svg'
import locations4 from './achievements/badges/locations4.svg'
import tree1 from './achievements/badges/tree1.svg'
import tree2 from './achievements/badges/tree2.svg'
import tree3 from './achievements/badges/tree3.svg'
import tree4 from './achievements/badges/tree4.svg'
import timesDonated1 from './achievements/badges/timesDonated1.svg'
import timesDonated2 from './achievements/badges/timesDonated2.svg'
import timesDonated3 from './achievements/badges/timesDonated3.svg'
import timesDonated4 from './achievements/badges/timesDonated4.svg'
import achievementContainers from './achievements/achievementContainers.svg'
import achievementReward from './achievements/achievementReward.svg'
import welcomePageBrandingElement from './logos/returnAndEarnWhite.svg'
import welcomePageLogo from './misc/greenBackground.png'
import acpMapPin from './map/acpMapPin.svg'
import mcpMapPin from './map/mcpMapPin.svg'
import apsMapPin from './map/apsMapPin.svg'
import dcpMapPin from './map/dcpMapPin.svg'
import acpMapIcon from './map/acpMapIcon.svg'
import mcpMapIcon from './map/mcpMapIcon.svg'
import apsMapIcon from './map/apsMapIcon.svg'
import dcpMapIcon from './map/dcpMapIcon.svg'

export const returnAndEarnImages = {
  welcomePageBrandingElement,
  welcomePageLogo,
  achievementContainers,
  achievementReward,
  cpTypes: {
    ACP: {
      pin: acpMapPin,
      icon: acpMapIcon
    },
    MCP: {
      pin: mcpMapPin,
      icon: mcpMapIcon
    },
    APS: {
      pin: apsMapPin,
      icon: apsMapIcon
    },
    DCP: {
      pin: dcpMapPin,
      icon: dcpMapIcon
    }
  },
  achievements: {
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-1': trophy1,
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-2': trophy2,
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-3': trophy3,
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-4': trophy4,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-1': digitalPayout1,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-2': digitalPayout2,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-3': digitalPayout3,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-4': digitalPayout4,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-1': locations1,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-2': locations2,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-3': locations3,
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-4': locations4,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-1': tree1,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-2': tree2,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-3': tree3,
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-4': tree4,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-1': timesDonated1,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-2': timesDonated2,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-3': timesDonated3,
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-4': timesDonated4,
    defaultBadge,
    unknownBadge
  }
}
