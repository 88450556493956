import React, { useState } from 'react'
import { useLoaderData, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { BackButton, ButtonLinkStyle } from '../../shared'
import { calculateDaysBetween } from '../../../lib'
import { CharityInfoDrawer } from './CharityInfoDrawer'
import { CampaignProgressBar } from './CampaignProgressBar'
import { benefitToTranslationMap } from '../common'
import {
  CampaignInfo_DaysLeft,
  CampaignInfo_GoalMet,
  Common_CampaignEnded,
  Common_By,
  Common_Description
} from '../../../translations/messages'
import { SetupCampaignPayout } from './SetupCampaignPayout'

const HeaderContainer = styled.div`
  width: 100%;
  background-color: var(--colors-tertiary-light);
`

const HeaderInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--max-page-width);
  padding: var(--spacing-lg);
  margin: 0 auto;

  > h1 {
    margin: var(--spacing-sm) 0;
  }
`

const Benefit = styled.small`
  font-weight: bold;
  position: absolute;
  right: var(--spacing-lg);
  top: var(--spacing-md);
`

const Logo = styled.img`
  width: 9rem;
  height: 9rem;
  margin: var(--spacing-xl) 0;
  border-radius: 50%;
  border: 3px solid var(--colors-tertiary);
  border-radius: 50%;
  background-color: white;
  object-fit: cover;
  cursor: pointer;
`

const CharityNameLink = styled.small`
  > p {
    display: inline;
  }

  > b {
    margin-left: var(--spacing-xs);
    text-decoration: underline;
    cursor: pointer;
  }
`

const InnerContainer = styled.div`
  max-width: var(--max-page-width);
  margin: 0 auto;
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: var(--spacing-lg);
  }
`

const GoalMet = styled.div`
  border: 2px solid var(--colors-primary);
  color: black;
  padding: var(--spacing-md);
  text-align: center;
  border-radius: var(--radius-sm);
  margin-bottom: var(--spacing-lg);
`

export const CampaignInfo = () => {
  const [showCharityInfoDrawer, setShowCharityInfoDrawer] = useState(false)
  const { campaignId } = useParams()
  const { campaign } = useLoaderData() as { campaign: CharityCampaignType }
  const {
    charityBenefit,
    logoUrl,
    endTime,
    name,
    charityDisplayName,
    charityWebsiteUrl,
    description,
    charityABN,
    donatedTotal,
    financialGoal,
    charityDescription,
    status
  } = campaign

  const completionPercentage = Math.floor((donatedTotal / financialGoal) * 100)
  const financialGoalIsMet = completionPercentage >= 100
  const daysLeft = calculateDaysBetween(Date.now(), endTime)
  const campaignHasEnded = daysLeft < 1 || status === 'STOPPED'

  return (
    <>
      <BackButton to=".." />

      <CharityInfoDrawer
        onClose={() => setShowCharityInfoDrawer(false)}
        visible={showCharityInfoDrawer}
        charityDisplayName={charityDisplayName}
        charityABN={charityABN}
        charityDescription={charityDescription}
        charityWebsiteUrl={charityWebsiteUrl}
      />

      <HeaderContainer className="animated fadeIn medium">
        <HeaderInnerContainer>
          <Benefit>
            <FormattedMessage
              id={benefitToTranslationMap[charityBenefit].id}
              defaultMessage={benefitToTranslationMap[charityBenefit].defaultMessage}
            />
          </Benefit>

          <Logo src={logoUrl} alt="charity campaign logo" onClick={() => setShowCharityInfoDrawer(true)} />

          {campaignHasEnded ? (
            <p style={{ color: 'var(--colors-red)' }}>
              <FormattedMessage id={Common_CampaignEnded.id} defaultMessage={Common_CampaignEnded.defaultMessage} />
            </p>
          ) : (
            <p>
              <FormattedMessage
                id={CampaignInfo_DaysLeft.id}
                defaultMessage={CampaignInfo_DaysLeft.defaultMessage}
                values={{ daysLeft }}
              />
            </p>
          )}

          <h1>{name}</h1>

          <CharityNameLink>
            <p>
              <FormattedMessage id={Common_By.id} defaultMessage={Common_By.defaultMessage} />
            </p>
            <ButtonLinkStyle style={{ marginLeft: 'var(--spacing-xs)' }} onClick={() => setShowCharityInfoDrawer(true)}>
              {charityDisplayName}
            </ButtonLinkStyle>
          </CharityNameLink>

          <CampaignProgressBar
            percentage={completionPercentage}
            donatedTotal={donatedTotal}
            financialGoal={financialGoal}
          />
        </HeaderInnerContainer>
      </HeaderContainer>

      <InnerContainer className="animated fadeIn medium">
        {financialGoalIsMet && (
          <GoalMet>
            <p>
              <FormattedMessage id={CampaignInfo_GoalMet.id} defaultMessage={CampaignInfo_GoalMet.defaultMessage} />
            </p>
          </GoalMet>
        )}

        <h2>
          <FormattedMessage id={Common_Description.id} defaultMessage={Common_Description.defaultMessage} />
        </h2>

        <p>{description}</p>

        {!campaignHasEnded && campaignId && (
          <SetupCampaignPayout charityDisplayName={charityDisplayName} campaignName={name} />
        )}
      </InnerContainer>
    </>
  )
}
