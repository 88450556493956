import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { CPType } from './CPType'
import { LocationStatus } from './LocationStatus'
import { getItemFromWebStorage, logError, setItemInWebStorage } from '../../lib'
import { StarIcon, LocationIcon, TimeIcon, InfoIcon } from '../icons'
import { Button, FeedbackMessage, Loading, Drawer } from '../shared'
import { fetchLocation, isOnNative, openAppUrl, sendGtagEvent } from '../../services'
import {
  LocationDetails_Directions,
  LocationDetails_FailedToFetch,
  LocationDetails_MakeFavorite
} from '../../translations/messages'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 20rem;
`

const InnerContainer = styled.div`
  padding: var(--spacing-md);

  > *:not(:first-child) {
    margin-top: var(--spacing-lg);
  }
`

const LocationInfoItem = styled.div`
  display: flex;
  align-items: center;

  > svg {
    min-width: 1.6rem;
    margin-right: var(--spacing-sm);
  }

  :nth-child(even) {
    margin-bottom: var(--spacing-sm);
    margin-top: var(--spacing-sm);
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button:first-child {
    margin-right: var(--spacing-sm);
  }

  > button:first-child {
    margin-left: var(--spacing-sm);
  }
`

const LocationName = styled.div`
  background-color: white;
  padding: var(--spacing-lg) var(--spacing-sm);
  font-weight: bold;
  text-align: center;
`

export const LocationDetails = () => {
  const navigate = useNavigate()
  const { openedLocationId } = useParams()
  const [favoriteLocationId, setFavoriteLocationId] = useState('')
  const [location, setLocation] = useState<MapLocationType | null>(null)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')

  useEffect(() => {
    getItemFromWebStorage('favoriteLocationId').then(setFavoriteLocationId)
  }, [])

  useEffect(() => {
    if (openedLocationId) {
      setRequestStatus('loading')
      const { run, abort } = fetchLocation(openedLocationId)
      run()
        .then(location => {
          setLocation(location)
          setRequestStatus('initial')
        })
        .catch(error => {
          if (error.name !== 'AbortError') {
            setRequestStatus('failed')
            logError(new Error('Failed to fetch location info'), error)
          }
        })
      return () => abort()
    }
  }, [openedLocationId])

  const _openDirections = () => {
    const { latitude, longitude } = location || {}
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`

    if (isOnNative()) {
      openAppUrl(url)
    } else {
      window.open(url)
    }
  }

  const _toggleFavoriteLocation = () => {
    const isAlreadyFavoriteLocation = favoriteLocationId === openedLocationId

    isAlreadyFavoriteLocation ? _updateFavoriteLocation('') : _updateFavoriteLocation(openedLocationId)

    sendGtagEvent('ui_click', 'added/update favorite location button')
  }

  const _updateFavoriteLocation = locationId => {
    setItemInWebStorage('favoriteLocationId', locationId)
    setFavoriteLocationId(locationId)
  }

  const isFavoriteLocation = openedLocationId === favoriteLocationId

  return (
    <Drawer onClose={() => navigate('..')} visible style={{ padding: 0 }}>
      <Container>
        {requestStatus === 'loading' ? (
          <Loading className="centerAbsolute" />
        ) : requestStatus === 'failed' ? (
          <FeedbackMessage className="centerAbsolute animated fadeIn medium">
            <FormattedMessage
              id={LocationDetails_FailedToFetch.id}
              defaultMessage={LocationDetails_FailedToFetch.defaultMessage}
            />
          </FeedbackMessage>
        ) : location ? (
          <React.Fragment>
            <LocationName>
              <span>{location.name}</span>
            </LocationName>

            <InnerContainer>
              <InnerContainer>
                <div>
                  <LocationInfoItem>
                    <LocationIcon />
                    <p>{location.address}</p>
                  </LocationInfoItem>

                  {location.readableOpeningHours && (
                    <LocationInfoItem>
                      <TimeIcon />
                      <p>{location.readableOpeningHours}</p>
                    </LocationInfoItem>
                  )}

                  {location.info && (
                    <LocationInfoItem>
                      <InfoIcon />
                      <p>{location.info}</p>
                    </LocationInfoItem>
                  )}
                </div>

                <CPType locationType={location.locationType} />
              </InnerContainer>

              {location.recyclingOptions?.some(option => option === 'RVM') && (
                <LocationStatus locationStatuses={location.status} />
              )}

              <ButtonContainer>
                <Button onClick={_toggleFavoriteLocation} border>
                  <StarIcon fillColor={isFavoriteLocation ? 'var(--colors-yellow)' : ''} />
                  <FormattedMessage
                    id={LocationDetails_MakeFavorite.id}
                    defaultMessage={LocationDetails_MakeFavorite.defaultMessage}
                  />
                  <div />
                </Button>

                <Button onClick={_openDirections} background="colored">
                  <FormattedMessage
                    id={LocationDetails_Directions.id}
                    defaultMessage={LocationDetails_Directions.defaultMessage}
                  />
                </Button>
              </ButtonContainer>
            </InnerContainer>
          </React.Fragment>
        ) : null}
      </Container>
    </Drawer>
  )
}
