/* eslint no-console: 0 */

export function logError(error: Error, cause?: Error | null, metadata?: any) {
  if (cause) {
    error.message += `, Caused by: ${cause.toString()}`
    error.stack += '\nCaused by: ' + cause.stack
  }

  const context = { metadata, explicitlyLogged: true }

  if (window.DD_RUM) {
    window.DD_RUM.addError(error, context)
  } else {
    console.error(error)
  }
}
