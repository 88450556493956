import React, { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../lib'
import * as locationService from '../../services/location-service'
import { sendGtagEvent } from '../../services/google-analytics-service'

type MapInstanceType = google.maps.Map
type MapSdkType = typeof google.maps

export const LocationsContext = React.createContext<{
  markers: google.maps.Marker[]
  activeFilters: CPTypes[]
  locations: MapLocationType[]
  mapInstance?: MapInstanceType
  mapSdk?: MapSdkType
  initMapMarkers: (map: google.maps.Map, maps: typeof google.maps) => Promise<void>
  getLocationsInViewport: () => MapLocationType[]
  filterMarkers: (type: CPTypes) => void
}>({
  markers: [],
  activeFilters: [],
  locations: [],
  mapInstance: undefined,
  mapSdk: undefined,
  initMapMarkers: () => Promise.reject('Function not implemented'),
  getLocationsInViewport: () => [],
  filterMarkers: () => Promise.reject('Function not implemented')
})

export const LocationsProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const [markers, setMarkers] = useState<google.maps.Marker[]>([])
  const [activeFilters, setActiveFilters] = useState<CPTypes[]>(['ACP', 'APS', 'MCP', 'DCP'])
  const [mapInstance, setMapInstance] = useState<MapInstanceType>()
  const [mapSdk, setMapSdk] = useState<MapSdkType>()
  const [locations, setLocations] = useState<MapLocationType[]>([])

  const getLocationsInViewport = () => {
    return locations.filter(({ id, latitude, longitude }) => {
      const isVisible = markers.find(marker => marker.getTitle()?.includes(id))?.getVisible()
      const isInViewport = mapInstance?.getBounds()?.contains({ lat: latitude, lng: longitude })
      return isInViewport && isVisible
    })
  }

  const initMapMarkers = async (map: MapInstanceType, maps: MapSdkType) => {
    let fetchedLocations = locations

    if (locations.length === 0) {
      fetchedLocations = await locationService.fetchAllLocations().run()
    }

    const markers = fetchedLocations.map(location => {
      const locationCoordinates = { lat: location.latitude, lng: location.longitude }

      const marker = new maps.Marker({
        map,
        position: locationCoordinates,
        title: `${location.id}+${location.locationType}`,
        icon: theme.images.cpTypes[location.locationType]?.pin || theme.images.cpTypes.ACP.pin
      })

      marker.getPosition()

      marker.addListener('click', () => {
        navigate(location.id)

        map.panTo(locationCoordinates)
        map.panBy(0, 200)

        sendGtagEvent('ui_click', 'map location')
      })

      return marker
    })

    setLocations(fetchedLocations)
    setMarkers(markers)
    setMapInstance(map)
    setMapSdk(maps)
  }

  const filterMarkers = (type: CPTypes) => {
    let newActiveFilters

    if (activeFilters.includes(type)) {
      newActiveFilters = activeFilters.filter(existing => existing !== type)
    } else {
      newActiveFilters = [...activeFilters, type]
    }

    markers.forEach(marker => {
      if (newActiveFilters.some(filter => marker.getTitle()?.includes(filter))) {
        marker.setVisible(true)
      } else {
        marker.setVisible(false)
      }
    })

    setActiveFilters(newActiveFilters)
  }

  return (
    <LocationsContext.Provider
      value={{
        markers,
        activeFilters,
        locations,
        mapInstance,
        mapSdk,
        initMapMarkers,
        filterMarkers,
        getLocationsInViewport
      }}
    >
      {children}
    </LocationsContext.Provider>
  )
}
