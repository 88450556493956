import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Currency } from '../shared'
import {
  RefundStatisticsSwiper_AvailableBalance,
  RefundStatisticsSwiper_TotalEarnings,
  RefundStatisticsSwiper_TotalPaidOut
} from '../../translations/messages'

const OuterContainer = styled.div`
  margin: var(--spacing-xl) 0;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  > div:nth-child(2) {
    margin: 0 var(--spacing-md);
  }
`

const InnerContainer = styled.div`
  text-align: center;

  > small {
    font-weight: bold;
  }
`

type Props = {
  refunds: RefundsRequestType['refunds']
}

export const RefundStatistics = ({ refunds }: Props) => (
  <OuterContainer>
    <Container>
      <InnerContainer>
        <small>
          <FormattedMessage
            id={RefundStatisticsSwiper_AvailableBalance.id}
            defaultMessage={RefundStatisticsSwiper_AvailableBalance.defaultMessage}
          />
        </small>
        <h2>
          <Currency amount={refunds[0].balanceStatistics.totalAmount} currency={refunds[0].currency} />
        </h2>
      </InnerContainer>

      <InnerContainer>
        <small>
          <FormattedMessage
            id={RefundStatisticsSwiper_TotalPaidOut.id}
            defaultMessage={RefundStatisticsSwiper_TotalPaidOut.defaultMessage}
          />
        </small>
        <h2>
          <Currency amount={refunds[0].payoutStatistics.totalAmount} currency={refunds[0].currency} />
        </h2>
      </InnerContainer>

      <InnerContainer>
        <small>
          <FormattedMessage
            id={RefundStatisticsSwiper_TotalEarnings.id}
            defaultMessage={RefundStatisticsSwiper_TotalEarnings.defaultMessage}
          />
        </small>
        <h2>
          <Currency amount={refunds[0].recycledStatistics.totalAmount} currency={refunds[0].currency} />
        </h2>
      </InnerContainer>
    </Container>
  </OuterContainer>
)
