import { fetchData as _fetchData } from '@tomra/client-side-http-client'
import { API_HOST, authentication } from '../lib'

const authenticatedFetchData = (url: string, options?: RequestInit) => _fetchData(url, options, authentication)

export function fetchCharityCampaign(campaignId) {
  return authenticatedFetchData(`${API_HOST}/charities/browse/v1/campaign/${campaignId}`).run()
}

export function fetchCharityUserData() {
  return authenticatedFetchData(`${API_HOST}/charities/v1/user-data`).run()
}

export function fetchMoreCharityUserData(pageEnd: number) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/user-data/donations?pageStart=${pageEnd}`)
}

export function searchCharityCampaigns(
  text: string,
  benefit: CharityBenefitType | null,
  order: CharitySearchOrderType | null
) {
  let url = `${API_HOST}/charities/browse/v1/search?text=${text}`

  if (order) {
    url += `&order=${order}`
  }

  if (benefit) {
    url += `&benefit=${benefit}`
  }

  return authenticatedFetchData(url)
}

export function loadMoreCharityCampaigns(
  text: string,
  benefit: CharityBenefitType | null,
  order: CharitySearchOrderType | null,
  pageEnd: number
) {
  let url = `${API_HOST}/charities/browse/v1/search?text=${text}`

  if (order) {
    url += `&order=${order}`
  }

  if (benefit) {
    url += `&benefit=${benefit}`
  }

  return authenticatedFetchData(`${url}&pageStart=${pageEnd}`)
}

export function fetchCharityCampaigns() {
  return authenticatedFetchData(`${API_HOST}/charities/browse/v1/search?text=`).run()
}

export function sendTaxReceipts(from: string, to: string, email: string) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Australia/Sydney'

  // Make sure time is set to start and end of day UTC
  const fromDate = new Date(`${from}T00:00:00`).toISOString()
  const toDate = new Date(`${to}T23:59:59`).toISOString()

  return authenticatedFetchData(
    `${API_HOST}/charities/v1/user-data/tax-receipt?from=${fromDate}&to=${toDate}&email=${email}&timezone=${timezone}`
  )
}
