import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { AppVersion } from './AppVersion'
import { isOnNative, openEmbeddedBrowserInNativeWrapper } from '../../../services'
import { isAnonymousUser, authentication, theme } from '../../../lib'
import { ListButton, ListItem, Loading } from '../../shared'
import {
  EditIcon,
  PiggyBankIcon,
  HelpIcon,
  InfoIcon,
  LockIcon,
  EmailIcon,
  LogoutIcon,
  ArrowRightIcon
} from '../../icons'
import {
  Common_ContactUs,
  Common_EditProfile,
  Common_PrivacyPolicy,
  Common_EditPayout,
  Settings_Faq,
  Common_Logout,
  Settings_Title,
  Settings_Tos
} from '../../../translations/messages'

const Container = styled.div`
  height: 100%;
  padding: var(--spacing-md);

  > *:not(:last-child) {
    margin: calc(var(--spacing-lg) * 2) 0;
  }
`

export const Settings = () => {
  const [loadingLogout, setLoadingLogout] = useState(false)

  const _logout = async () => {
    setLoadingLogout(true)
    authentication.logout()
  }

  const openDocLink = (docName: 'termsOfService' | 'privacyPolicy' | 'faq') => {
    isOnNative() ? openEmbeddedBrowserInNativeWrapper(theme[docName]) : window.open(theme[docName])
  }

  return (
    <Container>
      <h1>
        <FormattedMessage id={Settings_Title.id} defaultMessage={Settings_Title.defaultMessage} />
      </h1>

      <Link to="/account">
        <ListItem borderBottom>
          <div>
            <EditIcon />
            <span>
              <FormattedMessage id={Common_EditProfile.id} defaultMessage={Common_EditProfile.defaultMessage} />
            </span>
          </div>
          <ArrowRightIcon />
        </ListItem>
      </Link>

      <div>
        {!isAnonymousUser() && (
          <Link to="/payoutsettings">
            <ListItem>
              <div>
                <PiggyBankIcon />
                <span>
                  <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
                </span>
              </div>
              <ArrowRightIcon />
            </ListItem>
          </Link>
        )}

        <ListButton onClick={() => openDocLink('faq')}>
          <div>
            <HelpIcon />
            <span>
              <FormattedMessage id={Settings_Faq.id} defaultMessage={Settings_Faq.defaultMessage} />
            </span>
          </div>
          <ArrowRightIcon />
        </ListButton>

        <ListButton onClick={() => openDocLink('termsOfService')}>
          <div>
            <InfoIcon />
            <span>
              <FormattedMessage id={Settings_Tos.id} defaultMessage={Settings_Tos.defaultMessage} />
            </span>
          </div>
          <ArrowRightIcon />
        </ListButton>

        <ListButton onClick={() => openDocLink('privacyPolicy')}>
          <div>
            <LockIcon />
            <span>
              <FormattedMessage id={Common_PrivacyPolicy.id} defaultMessage={Common_PrivacyPolicy.defaultMessage} />
            </span>
          </div>
          <ArrowRightIcon />
        </ListButton>

        <Link to="/contact">
          <ListItem borderBottom>
            <div>
              <EmailIcon />
              <span>
                <FormattedMessage id={Common_ContactUs.id} defaultMessage={Common_ContactUs.defaultMessage} />
              </span>
            </div>
            <ArrowRightIcon />
          </ListItem>
        </Link>
      </div>

      <ListButton onClick={_logout} borderBottom>
        {loadingLogout ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div>
              <LogoutIcon />

              <span>
                <FormattedMessage id={Common_Logout.id} defaultMessage={Common_Logout.defaultMessage} />
              </span>
            </div>
            <ArrowRightIcon />
          </React.Fragment>
        )}
      </ListButton>

      <AppVersion />
    </Container>
  )
}
