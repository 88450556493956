import { PAYPAL_AUTH_ENDPOINT, PAYPAL_CLIENT_ID } from '../lib'
import { isOnNative, openEmbeddedBrowserInNativeWrapper } from '../services'

export function authenticatePayPalUser() {
  const scopes = 'openid+email+profile+https://uri.paypal.com/services/paypalattributes'
  const redirect_uri = encodeURIComponent(`${window.location.origin}/broker/paypal`)
  const url = `${PAYPAL_AUTH_ENDPOINT}?client_id=${PAYPAL_CLIENT_ID}&response_type=code&scope=${scopes}&redirect_uri=${redirect_uri}`

  isOnNative() ? openEmbeddedBrowserInNativeWrapper(url) : window.open(url)
}
