import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Button, Drawer, Loading } from './shared'
import { theme } from '../lib'
import { AppUpdatedDialog_Body, AppUpdatedDialog_Button, AppUpdatedDialog_Title } from '../translations/messages'

const everyTenMinutes = 1000 * 60 * 10 // 1 second -> 1 minute -> 10 minutes

const Container = styled.div`
  > p {
    margin: var(--spacing-lg) 0;
  }

  > button {
    width: 100%;
  }
`

export const AppUpdatedDrawer = () => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const [previousLastModifiedOrEtag, setPreviousLastModifiedOrEtag] = useState<string | null | undefined>()

  const requestAndCompareIndexHtml = useCallback(() => {
    fetch('/index.html', { cache: 'no-store' }).then(
      response => {
        const lastModifiedOrEtag = response.headers.get('last-modified') || response.headers.get('etag')

        if (previousLastModifiedOrEtag === undefined) {
          setPreviousLastModifiedOrEtag(lastModifiedOrEtag)
        } else if (previousLastModifiedOrEtag !== lastModifiedOrEtag) {
          setVisible(true)
        }
      },
      error => {
        // errors raised when the end-user couldn't get a hold of the index.html
        // because of connection problems isn't interresting to us
      }
    )
  }, [previousLastModifiedOrEtag])

  useEffect(() => {
    if (previousLastModifiedOrEtag === undefined) {
      requestAndCompareIndexHtml()
    }
  }, [previousLastModifiedOrEtag, requestAndCompareIndexHtml])

  useEffect(() => {
    const fetchInterval = window.setInterval(requestAndCompareIndexHtml, everyTenMinutes)
    return () => window.clearInterval(fetchInterval)
  }, [requestAndCompareIndexHtml])

  const closeDrawer = () => {
    setVisible(false)
  }

  const forceReload = () => {
    setLoading(true)
    window.location.reload()
  }

  return (
    <Drawer onClose={closeDrawer} visible={visible}>
      <Container>
        <h1>
          <FormattedMessage id={AppUpdatedDialog_Title.id} defaultMessage={AppUpdatedDialog_Title.defaultMessage} />
        </h1>

        <p>
          <FormattedMessage
            id={AppUpdatedDialog_Body.id}
            defaultMessage={AppUpdatedDialog_Body.defaultMessage}
            values={{ appName: theme.name }}
          />
        </p>

        <Button onClick={forceReload}>
          {loading ? (
            <Loading />
          ) : (
            <FormattedMessage id={AppUpdatedDialog_Button.id} defaultMessage={AppUpdatedDialog_Button.defaultMessage} />
          )}
        </Button>
      </Container>
    </Drawer>
  )
}
