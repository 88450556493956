import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFetcher } from 'react-router-dom'
import { Button, Loading } from '../../shared'
import {
  PayoutSettings_Voucher_Description,
  PayoutSettings_Voucher_Disclaimer,
  Common_EditPayout,
  Common_Enable
} from '../../../translations/messages'

export const Voucher = () => {
  const { Form, state } = useFetcher()
  const isLoading = state === 'submitting' || state === 'loading'

  return (
    <>
      <h1>
        <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
      </h1>

      <span>
        <FormattedMessage
          id={PayoutSettings_Voucher_Description.id}
          defaultMessage={PayoutSettings_Voucher_Description.defaultMessage}
        />
      </span>

      <span>
        <FormattedMessage
          id={PayoutSettings_Voucher_Disclaimer.id}
          defaultMessage={PayoutSettings_Voucher_Disclaimer.defaultMessage}
        />
      </span>

      <Form method="put">
        <Button background="colored" disabled={isLoading} name="payoutType" value="E_VOUCHER">
          {isLoading ? (
            <Loading />
          ) : (
            <FormattedMessage id={Common_Enable.id} defaultMessage={Common_Enable.defaultMessage} />
          )}
        </Button>
      </Form>
    </>
  )
}
