import React from 'react'
import { createBrowserRouter, RouterProvider, Outlet, useNavigation } from 'react-router-dom'
import { ContactUs } from './components/contact/ContactUs'
import { LocationsPage, LocationDetails } from './components/locations'
import { WelcomePage } from './components/login/WelcomePage'
import { PayPalBrokerPage } from './components/brokers/PayPalBrokerPage'
import { ZeptoBrokerPage } from './components/brokers/ZeptoBrokerPage'
import { VerifyEmailBrokerPage } from './components/brokers/VerifyEmailBrokerPage'
import { ActivitiesPageContainer } from './components/activities/ActivitiesPageContainer'
import { HomePage } from './components/home/HomePage'
import { PayoutSettingsPage } from './components/payout/PayoutSettingsPage'
import { VoucherPage } from './components/vouchers/VoucherPage'
import { AchievementsPage, AllAchievements, AchievementDetailsPage, MyAchievements } from './components/achievements'
import { AccountPage } from './components/account/AccountPage'
import { CharitiesPage, CampaignInfo, MyDonationsPage, BrowseCharitiesPage } from './components/charities'
import { DeleteAccount } from './components/delete/DeleteAccount'
import { Loading, NavigateLoading } from './components/shared'
import { ErrorPage } from './components/ErrorPage'
import {
  fetchAchievements,
  fetchCharityCampaign,
  fetchCharityUserData,
  fetchCharityCampaigns,
  fetchProfile,
  fetchRefundsAndActivities,
  fetchPayoutMethods,
  fetchVouchers,
  updatePayout,
  fetchLocation
} from './services'

const NavigateLoadingContainer = () => {
  const { state } = useNavigation()
  const isLoading = state === 'submitting' || state === 'loading'

  return (
    <>
      {isLoading && <NavigateLoading />}
      <Outlet />
    </>
  )
}

const routes = [
  {
    path: 'contact',
    element: <ContactUs />
  },
  {
    path: 'broker',
    children: [
      {
        path: 'verify-email',
        element: <VerifyEmailBrokerPage />
      },
      {
        path: 'paypal',
        element: <PayPalBrokerPage />
      },
      {
        path: 'split/:result',
        element: <ZeptoBrokerPage />
      }
    ]
  },
  {
    path: 'locations',
    element: <LocationsPage />,
    children: [
      {
        path: ':openedLocationId',
        element: <LocationDetails />,
        loader: ({ params }) => fetchLocation(params.openedLocationId as string)
      }
    ]
  }
]

const authRoutes = [
  {
    path: 'activity',
    element: <ActivitiesPageContainer />,
    loader: () =>
      Promise.all([fetchRefundsAndActivities(), fetchPayoutMethods()]).then(([activities, payoutMethods]) => ({
        activities,
        payoutMethods
      }))
  },
  {
    path: 'payoutsettings',
    element: <PayoutSettingsPage />,
    loader: fetchPayoutMethods,
    action: updatePayout
  },
  {
    path: 'vouchers',
    element: <VoucherPage />,
    loader: fetchVouchers
  },
  {
    path: 'account',
    element: <AccountPage />
  },
  {
    path: 'delete',
    element: <DeleteAccount />,
    loader: fetchRefundsAndActivities
  },
  {
    path: 'charities',
    element: <CharitiesPage />,
    children: [
      {
        path: ':campaignId',
        element: <CampaignInfo />,
        loader: ({ params }) =>
          Promise.all([fetchCharityCampaign(params.campaignId), fetchPayoutMethods()]).then(
            ([campaign, payoutMethods]) => ({
              campaign,
              payoutMethods
            })
          )
      },
      {
        path: 'donations',
        element: <MyDonationsPage />,
        loader: fetchCharityUserData
      },
      {
        index: true,
        element: <BrowseCharitiesPage />,
        loader: fetchCharityCampaigns
      }
    ]
  },
  {
    path: 'achievements',
    element: <AchievementsPage />,
    loader: fetchAchievements,
    id: 'achievements-root',
    children: [
      {
        path: 'all',
        element: <AllAchievements />
      },
      {
        path: ':achievementName',
        element: <AchievementDetailsPage />
      },
      {
        index: true,
        element: <MyAchievements />
      }
    ]
  }
]

export const UnAuthenticatedRouter = () => (
  <RouterProvider
    router={createBrowserRouter([
      {
        element: <NavigateLoadingContainer />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: '*',
            element: <WelcomePage />
          },
          ...routes
        ]
      }
    ])}
    fallbackElement={<Loading className="centerAbsolute" />}
  />
)

export const AuthenticatedRouter = () => (
  <RouterProvider
    router={createBrowserRouter([
      {
        element: <NavigateLoadingContainer />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: '*',
            element: <HomePage />,
            id: 'home-root',
            loader: () =>
              Promise.all([fetchProfile(), fetchPayoutMethods()]).then(([profile, payoutMethods]) => ({
                profile,
                payoutMethods
              }))
          },
          ...routes,
          ...authRoutes
        ]
      }
    ])}
    fallbackElement={<Loading className="centerAbsolute" />}
  />
)
