import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { APP_NAME } from '../../lib'
import { Loading } from '../shared'
import { FormattedMessage } from 'react-intl'
import {
  Common_ContactUs,
  Common_TryAgain,
  VerifyEmail_Error_General,
  VerifyEmail_Error_NotFound,
  VerifyEmail_Error_Title
} from '../../translations/messages'
import { Button } from '../shared'

const Container = styled.div`
  height: 100vh;
  padding: var(--spacing-lg);
  background-color: var(--colors-background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: var(--spacing-lg) 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonLink = styled.button`
  margin-top: var(--spacing-md);
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
  height: 3rem;
  border: none;
  font-weight: bold;
  background: none;
  align-self: center;
  justify-self: center;
  border-radius: var(--radius-lg);

  span {
    color: black;
    text-decoration: underline;
  }

  &:active span {
    color: rgba(31, 31, 31, 0.5);
  }
`

export const VerifyEmailBrokerPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const error = params.get('error_description')

  useEffect(() => {
    if (error) {
      return
    }

    const encodedpayload = btoa(JSON.stringify({ type: 'CHANGE_EMAIL_SUCCESS' }))

    // if window.opener exists, we are not on native
    if (window.opener) {
      window.location.href = `${window.location.origin}/account?type=CHANGE_EMAIL_SUCCESS`
      window.close()
    } else {
      window.location.href = `${APP_NAME.toLowerCase()}://brokers?encodedpayload=${encodedpayload}`
    }
  }, [error])

  const tryAgain = () => {
    if (window.opener) {
      window.location.href = `${window.location.origin}/account?type=CHANGE_EMAIL_ERROR`
      window.close()
    } else {
      const encodedpayload = btoa(JSON.stringify({ type: 'CHANGE_EMAIL_ERROR' }))
      window.location.href = `${APP_NAME.toLowerCase()}://brokers?encodedpayload=${encodedpayload}`
    }
  }

  const contactUs = () => {
    navigate('/contact')
  }

  return error ? (
    <Container>
      <div>
        <h1>
          <FormattedMessage id={VerifyEmail_Error_Title.id} defaultMessage={VerifyEmail_Error_Title.defaultMessage} />
        </h1>
        <p>
          <FormattedMessage
            id={error === 'NoMatchFound' ? VerifyEmail_Error_NotFound.id : VerifyEmail_Error_General.id}
            defaultMessage={
              error === 'NoMatchFound'
                ? VerifyEmail_Error_NotFound.defaultMessage
                : VerifyEmail_Error_General.defaultMessage
            }
          />
        </p>
      </div>

      <ButtonWrapper>
        <Button onClick={tryAgain}>
          <FormattedMessage id={Common_TryAgain.id} defaultMessage={Common_TryAgain.defaultMessage} />
        </Button>

        <ButtonLink onClick={contactUs}>
          <span>
            <FormattedMessage id={Common_ContactUs.id} defaultMessage={Common_ContactUs.defaultMessage} />
          </span>
        </ButtonLink>
      </ButtonWrapper>
    </Container>
  ) : (
    <React.Fragment>
      <Loading className="centerAbsolute" />
    </React.Fragment>
  )
}
