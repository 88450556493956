import { v4 as uuidv4 } from 'uuid'
import { KEYCLOAK_HOST } from './environment'
import {
  sendGtagEvent,
  unregisterPushNotifications,
  isOnNative,
  hasEmbeddedBrowserSupport,
  openEmbeddedBrowserInNativeWrapper,
  openAppUrl
} from '../services'
import {
  getItemFromWebStorage,
  setItemInWebStorage,
  removeItemFromWebStorage,
  getQueryParameterByName,
  logError,
  getInfoFromRefreshToken,
  APP_NAME,
  intl
} from '../lib'

class HttpError extends Error {
  status: number
  statusText: string
  body: string | Record<string, unknown>

  constructor(statusCode: number, statusText: string, body: string | Record<string, unknown>) {
    super(`Unsuccessful HTTP response: ${statusCode} ${statusText}`)
    this.name = 'HttpError'
    this.status = statusCode
    this.statusText = statusText
    this.body = body
  }
}

class InvalidPasswordError extends Error {
  constructor() {
    super()
    this.name = 'InvalidPasswordError'
  }
}

// When doing the _performCodeRequest(), the redirect_uri passed in that request
// must be identical to the redirect_uri query param used when doing PayPal login (see loginWithProvider() method)
// or impersonation through KC (see redirectUponImpersonate() method in KC account theme scripts)
// Made into a function since APP_NAME env variable isn't available until runtime
function getRedirectUrl() {
  return isOnNative() ? `${APP_NAME.toLowerCase()}://brokers` : window.location.origin
}

function openBrowserWindow(url: string) {
  if (hasEmbeddedBrowserSupport()) {
    openEmbeddedBrowserInNativeWrapper(url)
  } else if (isOnNative()) {
    openAppUrl(url)
  } else {
    window.location.href = url
  }
}

export class AuthStore {
  _accessToken: string = ''
  _refreshToken: string = ''
  _realm: string
  _clientId: string
  _keycloakHost: string
  _supportedRequiredActions = ['VERIFY_EMAIL', 'UPDATE_PASSWORD', 'TERMS_AND_CONDITIONS']

  constructor(realm: string, clientId: string, keycloakHost: string) {
    this._realm = realm
    this._clientId = clientId
    this._keycloakHost = keycloakHost
  }

  _performCodeRequest = code => {
    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `code=${code}&client_id=${this._clientId}&redirect_uri=${getRedirectUrl()}&grant_type=authorization_code`
    }).then(response => {
      if (response.ok) return response.json()
      else throw new HttpError(response.status, response.statusText, 'Failed to exchange auth code for tokens')
    })
  }

  _performTokenRequest = (refreshToken = this._refreshToken) => {
    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `refresh_token=${refreshToken}&client_id=${this._clientId}&grant_type=refresh_token`
    }).then(response => {
      if (response.ok) return response.json()
      else throw new HttpError(response.status, response.statusText, 'Failed to refresh token')
    })
  }

  _performLoginRequest = (username: string, password: string) => {
    const credentials = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`

    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `${credentials}&client_id=${this._clientId}&grant_type=password`
    }).then(response => {
      if (response.ok) return response.json()
      else throw new HttpError(response.status, response.statusText, 'Failed to log in')
    })
  }

  _performLogoutRequest = (refreshToken = this._refreshToken) => {
    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/logout`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `refresh_token=${refreshToken}&client_id=${this._clientId}`
    }).then(response => {
      if (!response.ok) {
        throw new HttpError(response.status, response.statusText, 'Failed to remove user session from Keycloak')
      }
    })
  }

  initWithCode = async (code: string) => {
    try {
      const { access_token, refresh_token } = await this._performCodeRequest(code)

      this._accessToken = access_token
      this._refreshToken = refresh_token

      setItemInWebStorage('refreshToken', refresh_token)

      window.history.replaceState({}, document.title, window.location.pathname)
    } catch (error: any) {
      logError(new Error('Failed to initialise auth with code'), error)
      // Throw so that init() properly rejects
      throw error
    }
  }

  initWithRefreshToken = async (refreshToken: string = this._refreshToken) => {
    try {
      const { access_token } = await this._performTokenRequest(refreshToken)

      this._accessToken = access_token
      this._refreshToken = refreshToken
    } catch (error: any) {
      if (error.status === 400) {
        const refreshTokenInfo = getInfoFromRefreshToken(refreshToken)

        if (refreshTokenInfo.refreshTokenIsValid) {
          logError(new Error('KC session has expired/dropped before refresh token'), error, refreshTokenInfo)
          sendGtagEvent('ui_other', 'KC session has expired/dropped before refresh token')
        }

        return Promise.reject('Unauthenticated')
      } else {
        logError(new Error('Failed to initialise auth with refresh token'), error)
        throw error
      }
    }
  }

  init = async () => {
    // Already authenticated
    if (this._accessToken && this._refreshToken) {
      return Promise.resolve()
    }

    const error_description = getQueryParameterByName('error_description')

    if (error_description) {
      logError(new Error(`Social login failed. Error description: ${error_description}`))
    }

    const authCode = getQueryParameterByName('code')
    const refreshToken = await getItemFromWebStorage('refreshToken')

    if (authCode) {
      return this.initWithCode(authCode)
    } else if (refreshToken) {
      return this.initWithRefreshToken(refreshToken)
    } else {
      return Promise.reject('Unauthenticated')
    }
  }

  login = () => {
    const state = btoa(JSON.stringify({ activeCountryState: getQueryParameterByName('activeCountryState') }))
    const nonce = uuidv4()
    const redirectUrl = encodeURIComponent(getRedirectUrl())
    const backgroundColor = encodeURIComponent(
      getComputedStyle(document.documentElement).getPropertyValue('--colors-secondary')
    )
    const locale = intl.locale.split('-')[0] // KC locales are composed of just "en" as opposed to "en-GB" etc
    const url = `${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/auth?client_id=${this._clientId}&redirect_uri=${redirectUrl}&state=${state}&nonce=${nonce}&response_type=code&scope=openid&ui_locales=${locale}&backgroundColor=${backgroundColor}`

    openBrowserWindow(url)
  }

  logout = () => {
    const redirectUrl = encodeURIComponent(getRedirectUrl())
    const locale = intl.locale.split('-')[0] // KC locales are composed of just "en" as opposed to "en-GB" etc
    const url = `${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/logout?redirect_uri=${redirectUrl}&ui_locales=${locale}`

    removeItemFromWebStorage('refreshToken')
    unregisterPushNotifications()

    openBrowserWindow(url)
  }

  getToken = () => {
    return this._accessToken
  }

  getRefreshToken = () => {
    return this._refreshToken
  }

  getSupportedRequiredActions = () => {
    return this._supportedRequiredActions
  }

  fetchNewToken = async () => {
    try {
      const { access_token } = await this._performTokenRequest()
      this._accessToken = access_token
    } catch (error) {
      if (this._accessToken) this.logout()
      throw error
    }
  }

  checkExistingKCPasswordValidity = (email: string, password: string) => {
    return this._performLoginRequest(email, password).catch(error => {
      throw new InvalidPasswordError()
    })
  }
}

export const authentication = new AuthStore('TomraReactUsers', 'react-ui', KEYCLOAK_HOST)
