import { sendGtagEvent } from './google-analytics-service'
import { askForUserLocationNative, isOnNative } from './react-native-service'
import { API_HOST, GOOGLE_MAPS_API_KEY, ENV, APP_NAME, fetchRaw, fetchData } from '../lib'

const fallbackBounds = {
  getNorthEast: () => ({ lat: () => 0, lng: () => 0 }),
  getSouthWest: () => ({ lat: () => 0, lng: () => 0 })
}

const supportedCpTypes = ['ACP', 'APS', 'MCP', 'DCP']

type GeoLocationResult = {
  coords: {
    latitude: number
    longitude: number
  }
}

let nativeUserLocationPromise

// Extending built-in Error doesn't work as expected with instanceof
// when transpiling ES6 down to ES5, therefore these custom error classes
// are defined with function constructors, rather than ES6's class syntax
export function GeolocationError(message: string) {
  // @ts-ignore
  this.name = 'GeolocationError'
  // @ts-ignore
  this.message = message
  // @ts-ignore
  this.stack = new Error().stack
}

GeolocationError.prototype = new Error()

export function DoesNotSupportGeolocationError() {
  // @ts-ignore
  this.name = 'DoesNotSupportGeolocationError'
  // @ts-ignore
  this.stack = new Error().stack
}

DoesNotSupportGeolocationError.prototype = new Error()

// Translate PositionErrors to GeolocationError to make it easier to check for (as PositionError does not have a publicly available constructor) https://developer.mozilla.org/en-US/docs/Web/API/PositionError
function rejectWithCustomError(originalReject) {
  return err => {
    const msg = err ? `Got PositionError (code: ${err.code})` : 'Geolocation rejected without Error'
    originalReject(new GeolocationError(msg))
  }
}

function findLocationViaNativeAppWrapper() {
  const listenerCallback = (nativeAction: any) => {
    // now that we've got answer from the native app, we're ready to fulfil the Promise signalling
    // whether or not the user-location request succeeded or not
    try {
      const parsedMessage = typeof nativeAction.data === 'object' ? nativeAction.data : JSON.parse(nativeAction.data)

      if (parsedMessage.type === 'USER_LOCATION_RESULT') {
        if (parsedMessage.error === true) {
          nativeUserLocationPromise.reject()
        } else {
          nativeUserLocationPromise.resolve(parsedMessage.payload)
        }
      }
    } catch (error) {
      // Don't care
    }
  }

  // need to use window.addEventListener on iOS and document.addEventListener for android
  window.addEventListener('message', listenerCallback)
  document.addEventListener('message', listenerCallback)

  askForUserLocationNative()

  return new Promise<GeoLocationResult>((resolve, reject) => {
    // save resolve() and reject() for later so that other methods in this component
    // are able to fulfill this Promise when appropriate
    nativeUserLocationPromise = { resolve, reject }
  })
}

function findLocationWithBrowserApi() {
  return new Promise<GeoLocationResult>((resolve, reject) => {
    if ('geolocation' in navigator) {
      window.navigator.geolocation.getCurrentPosition(resolve, rejectWithCustomError(reject))
    } else {
      reject(new DoesNotSupportGeolocationError())
    }
  })
}

export function findUserLocation() {
  const locationRequest = isOnNative() ? findLocationViaNativeAppWrapper() : findLocationWithBrowserApi()

  return locationRequest.then(
    ({ coords }) => {
      sendGtagEvent('ui_click', 'accept geolocation')
      return { lat: coords.latitude, lng: coords.longitude }
    },
    err => {
      if (!(err instanceof DoesNotSupportGeolocationError)) {
        sendGtagEvent('ui_click', 'reject geolocation')
      }

      throw err
    }
  )
}

export function fetchAllLocations() {
  const f = fetchData(
    `${API_HOST}/mytomra/v1.0/locations${APP_NAME === 'RETURNANDEARN' ? '?includeExternal=true' : ''}`,
    {
      cache: 'no-store',
      headers: {
        accept: 'application/vnd.api+json'
      }
    }
  )

  return {
    run: () =>
      f.run().then(locations =>
        locations.map(({ id, attributes }) => {
          attributes.locationType = supportedCpTypes.includes(attributes.locationType) ? attributes.locationType : 'ACP'

          return {
            id,
            ...attributes
          }
        })
      ),
    abort: f.abort
  }
}

export function findCoordinatesForKeyword(searchTerm: string, bounds: google.maps.LatLngBounds) {
  const { lat: naLat, lng: naLng } = bounds?.getNorthEast() || fallbackBounds.getNorthEast()
  const { lat: swLat, lng: swLng } = bounds?.getSouthWest() || fallbackBounds.getNorthEast()

  return fetchRaw(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      searchTerm
    )}&bounds=${swLat()},${swLng()}|${naLat()},${naLng()}&region=${ENV}&key=${GOOGLE_MAPS_API_KEY}`
  ).run()
}

export function fetchLocation(locationId: string) {
  const f = fetchRaw(`${API_HOST}/mytomra/v1.0/locations/details/${locationId}`, { cache: 'no-store' })

  return {
    run: () =>
      f.run().then(({ data, meta }: any) => {
        const attributes = data.attributes
        attributes.locationType = supportedCpTypes.includes(attributes.locationType) ? attributes.locationType : 'ACP'

        return {
          ...attributes,
          ...meta
        }
      }),
    abort: f.abort
  }
}
