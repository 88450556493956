import React from 'react'
import styled from 'styled-components'
import { ProgressBar } from '../../shared'
import { achievementMap } from '../lib/achievementMap'
import { intl, theme } from '../../../lib'
import {
  Achievement_Done,
  Achievement_NotStarted,
  Achievement_Remaining,
  Achievements_Name_Description_Unknown
} from '../../../translations/messages'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Badge = styled.img`
  flex: 1;
  max-width: 6.5rem;
`

const LargeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: var(--spacing-sm);
  }
`

const LargeBadge = styled.img`
  max-width: 8.5rem;
`

const InnerContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-lg);
  color: black;

  > small {
    margin: var(--spacing-sm) 0 var(--spacing-md) var(--spacing-md);
  }

  > h2 {
    margin-left: var(--spacing-md);
  }
`

type Props = {
  achievement: AchievementType
  large?: boolean
  showUnknownBadge?: boolean
}

const unknownAchievement = {
  badge: '',
  messages: { title: 'Unknown achievement', description: Achievements_Name_Description_Unknown }
}

export const Achievement = ({ achievement, large, showUnknownBadge }: Props) => {
  const { achievementName, progress, currentLevel, levels, currency } = achievement
  const achievementConfig = achievementMap[achievementName] || {}

  const { badge, messages } =
    achievementMap[achievementName] === undefined
      ? unknownAchievement
      : achievementConfig[currentLevel] || achievementConfig[0]

  const _getTitleAndDescription = () => {
    return {
      title: messages.title,
      description: intl.formatMessage(messages.description, {
        levelRequirement: levels[currentLevel],
        currency
      })
    }
  }

  const _renderProgressBar = () => {
    const levelRequirement = levels[currentLevel]
    const remaining = Math.ceil(levelRequirement - progress)
    let percentage = Math.floor((progress / levelRequirement) * 100)

    if (percentage > 100) percentage = 100

    return (
      <ProgressBar
        percentage={percentage}
        backgroundColor={
          percentage === 100 ? 'var(--colors-button)' : percentage > 30 ? 'var(--colors-yellow)' : 'var(--colors-red)'
        }
      >
        {percentage === 100
          ? intl.formatMessage(Achievement_Done)
          : percentage === 0
          ? intl.formatMessage(Achievement_NotStarted)
          : intl.formatMessage(Achievement_Remaining, {
              remainingValue: currency ? `${remaining} ${currency}` : remaining
            })}
      </ProgressBar>
    )
  }

  const { title, description } = _getTitleAndDescription()

  return large ? (
    <LargeContainer>
      <LargeBadge
        src={showUnknownBadge ? theme.images.achievements.unknownBadge : badge}
        onError={evt => {
          const target = evt.target as HTMLImageElement
          target.src = theme.images.achievements.defaultBadge
        }}
        alt={achievementName + currentLevel}
      />

      <h2>{title}</h2>
      <small>
        <b>{description}</b>
      </small>
      {_renderProgressBar()}
    </LargeContainer>
  ) : (
    <Container>
      <Badge
        src={showUnknownBadge ? theme.images.achievements.unknownBadge : badge}
        onError={evt => {
          const target = evt.target as HTMLImageElement
          target.src = theme.images.achievements.defaultBadge
        }}
        alt={achievementName + currentLevel}
      />

      <InnerContainer>
        <h2>{title}</h2>
        <small>
          <b>{description}</b>
        </small>
        {_renderProgressBar()}
      </InnerContainer>
    </Container>
  )
}
