import { doWhenIdle } from './doWhenIdle'
import { theme } from '../lib'

export function preloadAppImages(images = theme.images) {
  doWhenIdle(() => {
    for (let key in images) {
      if (images.hasOwnProperty(key)) {
        if (typeof images[key] === 'string') {
          document.createElement('img').src = images[key]
        } else if (typeof images[key] === 'object') {
          preloadAppImages(images[key])
        } else continue
      }
    }
  })
}
