import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { updateUser } from '../../services'
import { authentication, getUserInfoFromJwt, intl, isAnonymousUser, logError, theme } from '../../lib'
import { LanguagePicker } from '../login/LanguagePicker'
import { ChangePassword } from './ChangePassword'
import { BackButton, Button, CountryStatePicker, Drawer, IconButton, InfoTooltip, Loading, TextField } from '../shared'
import { EarthIcon, LockIcon, TrashcanIcon } from '../icons'
import { NewsLetterSetting } from './NewsLetterSetting'
import { ConfirmEditEmail } from './ConfirmEditEmail'
import {
  AccountPage_ChangeLocation,
  Auth_Email,
  Auth_EmailConditionsNotMet,
  Auth_FirstNameDisclaimer,
  Auth_LastName,
  Auth_Name,
  Common_ChangePassword,
  Common_DeleteAccount,
  Common_EditProfile,
  Common_Update,
  Common_UpdateFailed,
  UpdateEmail_EmailAlreadyInUse,
  VerifyEmail_Success
} from '../../translations/messages'
import { Link, useLocation } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl);
  max-width: var(--max-page-width);
  margin: 0 auto;

  > h1 {
    margin: var(--spacing-xl) 0;
  }
`

const TextFieldsContainer = styled.form`
  margin: var(--spacing-xl) 0;
  width: 100%;
`

const SubSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  > div:first-child {
    border-top: 1px solid black;
  }

  > div {
    border-bottom: 1px solid black;
  }
`

const SubSetting = styled.div`
  padding: var(--spacing-md) 0;
`

const SaveButton = styled(Button)`
  margin-top: var(--spacing-sm);
`

const StyledIconButton = styled(IconButton)`
  > svg {
    margin-right: var(--spacing-md);
  }
`

const ErrorText = styled.div`
  color: var(--colors-red);
  margin-bottom: var(--spacing-md);
`

const SuccessText = styled.div`
  color: var(--colors-green);
  margin-bottom: var(--spacing-md);
`

const InfoTooltipContainer = styled.div`
  position: absolute;
  top: 25%;
  right: 0;
`

export const AccountPage = () => {
  const { firstName, lastName } = getUserInfoFromJwt()
  const [currentEmail, setCurrentEmail] = useState(() => getUserInfoFromJwt().email)
  const [newFirstName, setNewFirstName] = useState(firstName)
  const [newFirstNameValid, setNewFirstNameValid] = useState(true)
  const [newLastName, setNewLastName] = useState(lastName)
  const [newLastNameValid, setNewLastNameValid] = useState(true)
  const [newEmail, setNewEmail] = useState(currentEmail)
  const [newEmailValid, setNewEmailValid] = useState(true)
  const [showChangePasswordDrawer, setShowChangePasswordDrawer] = useState(false)
  const [showSelectLocationDrawer, setShowSelectLocationDrawer] = useState(false)
  const [showEditEmailDrawer, setShowEditEmailDrawer] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType | 'conflict'>('initial')

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const redirectMessage = queryParams.get('type')

  useEffect(() => {
    if (!redirectMessage) {
      return
    }

    setRequestStatus('loading')

    authentication
      .initWithRefreshToken()
      .then(() => {
        setRequestStatus('success')
        const updatedEmail = getUserInfoFromJwt().email
        setCurrentEmail(updatedEmail)
        setNewEmail(updatedEmail)
      })
      .catch(() => {
        setRequestStatus('failed')
      })
  }, [redirectMessage])

  const _accountDetailsHasBeenModified = () => {
    return newFirstName !== firstName || newLastName !== lastName || newEmail !== currentEmail
  }

  const _performProfileUpdateRequest = async () => {
    const payload: {
      firstName: string
      lastName: string
      email?: string
    } = {
      firstName: newFirstName,
      lastName: newLastName,
      email: newEmail
    }

    // Don't want to include email in payload if not updated
    // as that would trigger a verify email action in KC
    if (newEmail === currentEmail) {
      delete payload.email
    }

    try {
      setRequestStatus('loading')
      await updateUser(payload).run()

      // Update access token with new values
      await authentication.initWithRefreshToken()

      setRequestStatus('initial')
    } catch (error: any) {
      if (error.status === 409) {
        return Promise.reject('Email already in use')
      } else {
        setRequestStatus('failed')
        logError(new Error('Failed to update user'), error)
      }
    }
  }

  const _onUpdateButtonClick = (evt: React.SyntheticEvent) => {
    evt.preventDefault()

    if (newEmail !== currentEmail) {
      _performProfileUpdateRequest()
        .then(() => setShowEditEmailDrawer(true))
        .catch(() => setRequestStatus('conflict'))
    } else {
      _performProfileUpdateRequest()
    }
  }

  const formValid = newFirstNameValid && newLastNameValid && newEmailValid

  return (
    <Container className="animated fadeIn medium">
      <BackButton to="/" />

      <Drawer onClose={() => setShowChangePasswordDrawer(!showChangePasswordDrawer)} visible={showChangePasswordDrawer}>
        <ChangePassword />
      </Drawer>

      <Drawer onClose={() => setShowSelectLocationDrawer(!showSelectLocationDrawer)} visible={showSelectLocationDrawer}>
        <CountryStatePicker />
      </Drawer>

      <Drawer onClose={() => setShowEditEmailDrawer(!showEditEmailDrawer)} visible={showEditEmailDrawer}>
        <ConfirmEditEmail closeDrawer={() => setShowEditEmailDrawer(false)} newEmail={newEmail} />
      </Drawer>

      <h1>
        <FormattedMessage id={Common_EditProfile.id} defaultMessage={Common_EditProfile.defaultMessage} />
      </h1>

      <TextFieldsContainer>
        <TextField
          onChange={(value, valid) => {
            setNewFirstName(value)
            setNewFirstNameValid(valid)
          }}
          value={newFirstName}
          valid={newFirstNameValid}
          label={intl.formatMessage(Auth_Name)}
          ariaLabel={intl.formatMessage(Auth_Name)}
          required
        >
          <InfoTooltipContainer>
            <InfoTooltip content={intl.formatMessage(Auth_FirstNameDisclaimer, { appName: theme.name })} />
          </InfoTooltipContainer>
        </TextField>
        <TextField
          onChange={(value, valid) => {
            setNewLastName(value)
            setNewLastNameValid(valid)
          }}
          value={newLastName}
          valid={newLastNameValid}
          label={intl.formatMessage(Auth_LastName)}
          required
        />
        <TextField
          onChange={(value, valid) => {
            setNewEmail(value)
            setNewEmailValid(valid)
          }}
          value={newEmail}
          valid={newEmailValid}
          label={intl.formatMessage(Auth_Email)}
          errorMsg={intl.formatMessage(Auth_EmailConditionsNotMet)}
          type="email"
          pattern="^[^\s@]+@[^\s@]+\.[^\s@.,]+$"
          ignoreWhitespace
        />

        {requestStatus === 'success' && (
          <SuccessText>
            <FormattedMessage id={VerifyEmail_Success.id} defaultMessage={VerifyEmail_Success.defaultMessage} />
          </SuccessText>
        )}

        {requestStatus === 'failed' && (
          <ErrorText>
            <FormattedMessage id={Common_UpdateFailed.id} defaultMessage={Common_UpdateFailed.defaultMessage} />
          </ErrorText>
        )}

        {requestStatus === 'conflict' && (
          <ErrorText>
            <FormattedMessage
              id={UpdateEmail_EmailAlreadyInUse.id}
              defaultMessage={UpdateEmail_EmailAlreadyInUse.defaultMessage}
            />
          </ErrorText>
        )}

        <SaveButton
          onClick={_onUpdateButtonClick}
          disabled={!formValid || !_accountDetailsHasBeenModified() || requestStatus === 'loading'}
        >
          {requestStatus === 'loading' ? (
            <span data-testid="loading">
              <Loading />
            </span>
          ) : (
            <FormattedMessage id={Common_Update.id} defaultMessage={Common_Update.defaultMessage} />
          )}
        </SaveButton>
      </TextFieldsContainer>

      <SubSettingsContainer>
        {!isAnonymousUser() && (
          <SubSetting>
            <StyledIconButton onClick={() => setShowChangePasswordDrawer(true)}>
              <LockIcon />
              <FormattedMessage id={Common_ChangePassword.id} defaultMessage={Common_ChangePassword.defaultMessage} />
            </StyledIconButton>
          </SubSetting>
        )}

        {theme.showCountryPicker && (
          <SubSetting>
            <StyledIconButton onClick={() => setShowSelectLocationDrawer(true)}>
              <EarthIcon />
              <FormattedMessage
                id={AccountPage_ChangeLocation.id}
                defaultMessage={AccountPage_ChangeLocation.defaultMessage}
              />
            </StyledIconButton>
          </SubSetting>
        )}

        <SubSetting>
          <LanguagePicker reverseIconAndLabelOrder />
        </SubSetting>

        {!isAnonymousUser() && (
          <SubSetting className="animated fadeIn medium">
            <NewsLetterSetting />
          </SubSetting>
        )}

        {!isAnonymousUser() && (
          <SubSetting>
            <Link to="/delete">
              <StyledIconButton>
                <TrashcanIcon />
                <FormattedMessage id={Common_DeleteAccount.id} defaultMessage={Common_DeleteAccount.defaultMessage} />
              </StyledIconButton>
            </Link>
          </SubSetting>
        )}
      </SubSettingsContainer>
    </Container>
  )
}
