import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Drawer } from './shared'
import { theme } from '../lib'
import { satisfiesNativeAppVersion, getNativeDeviceInfo, openAppUrl } from '../services'
import {
  NativeAppUpdatedDialog_FirstDescription,
  NativeAppUpdatedDialog_SecondDescription,
  NativeAppUpdatedDialog_Title
} from '../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-bottom: var(--spacing-md);
  }

  > button {
    margin-top: var(--spacing-lg);
    border: none;
    background: transparent;
    align-self: center;
  }
`

type State = {
  visible: boolean
  OS?: 'IOS' | 'ANDROID'
}

export class NativeAppUpdatedDrawer extends React.Component<{}, State> {
  state: State = {
    visible: false,
    OS: undefined
  }

  _closeDrawer = () => {
    this.setState({ visible: false })
  }

  componentDidMount() {
    const OS = getNativeDeviceInfo()?.OS

    if (OS) {
      this.setState({
        visible: !satisfiesNativeAppVersion(theme.latestNativeAppVersion),
        OS
      })
    }
  }

  _openAppOrPlayStore = () => {
    const { OS } = this.state

    if (OS === 'IOS') {
      openAppUrl(theme.appLinks.ios)
    } else {
      openAppUrl(theme.appLinks.android)
    }

    this._closeDrawer()
  }

  render() {
    const { visible, OS } = this.state

    const storeName = OS === 'IOS' ? 'App Store' : 'Google Play'

    return (
      <Drawer onClose={this._closeDrawer} visible={visible}>
        <Container>
          <h1>
            <FormattedMessage
              id={NativeAppUpdatedDialog_Title.id}
              defaultMessage={NativeAppUpdatedDialog_Title.defaultMessage}
            />
          </h1>

          <p>
            <FormattedMessage
              id={NativeAppUpdatedDialog_FirstDescription.id}
              defaultMessage={NativeAppUpdatedDialog_FirstDescription.defaultMessage}
              values={{ appName: theme.name }}
            />
          </p>

          <p>
            <FormattedMessage
              id={NativeAppUpdatedDialog_SecondDescription.id}
              defaultMessage={NativeAppUpdatedDialog_SecondDescription.defaultMessage}
            />
          </p>

          <button onClick={this._openAppOrPlayStore} aria-label={storeName}>
            <img
              src={OS === 'IOS' ? theme.images.appStoreLogo : theme.images.playStoreLogo}
              alt={storeName}
              width="170px"
            />
          </button>
        </Container>
      </Drawer>
    )
  }
}
