import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactBarcode from 'react-barcode'
import { FormattedMessage } from 'react-intl'
import { IconButton } from '../../shared'
import { CloseIcon } from '../../icons'
import { getItemFromWebStorage, setItemInWebStorage, STAGE } from '../../../lib'
import { BarcodeIcon } from '../../icons'
import { BarcodeButton_Description, Header_Barcode } from '../../../translations/messages'

const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 3px solid black;
  border-radius: 100px;
  min-width: 100px;
  min-height: 100px;
  color: black;
`

const BarcodeContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 3px solid black;
  border-radius: var(--radius-sm);
  width: 93vw;
  max-width: var(--max-page-width);
  padding: var(--spacing-xs);
  z-index: 1;

  > small {
    margin-top: -var(--spacing-xs);
  }
`

const BackButton = styled(IconButton)`
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
`

type Props = {
  barcodeValue: string | undefined
  countryStateFeatures: CountryType['features']
}

export const BarcodeButton = ({ barcodeValue, countryStateFeatures }: Props) => {
  const barcodeIsOnlyChoice = !countryStateFeatures.HAS_BAGDROP && !countryStateFeatures.HAS_QR_CODE && STAGE === 'prod'
  const [expandBarcode, setExpandBarcode] = useState(barcodeIsOnlyChoice)

  const toggleBarcodeExpansion = () => {
    setItemInWebStorage('expandBarcode', !expandBarcode)
    setExpandBarcode(!expandBarcode)
  }

  useEffect(() => {
    getItemFromWebStorage('expandBarcode').then(expanded => expanded && setExpandBarcode(true))
  }, [])

  return barcodeValue && (STAGE === 'test' || countryStateFeatures.HAS_BARCODE) ? (
    <>
      <StyledIconButton onClick={toggleBarcodeExpansion}>
        <BarcodeIcon color="black" size="3rem" />
        <small>
          <FormattedMessage id={Header_Barcode.id} defaultMessage={Header_Barcode.defaultMessage} />
        </small>
      </StyledIconButton>

      {expandBarcode && (
        <BarcodeContainer>
          <ReactBarcode value={barcodeValue} lineColor="black" displayValue={false} height={65} />

          <small>
            <FormattedMessage
              id={BarcodeButton_Description.id}
              defaultMessage={BarcodeButton_Description.defaultMessage}
            />
          </small>

          {!barcodeIsOnlyChoice && (
            <BackButton onClick={toggleBarcodeExpansion}>
              <CloseIcon />
            </BackButton>
          )}
        </BarcodeContainer>
      )}
    </>
  ) : null
}
