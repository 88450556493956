import jwtDecode from 'jwt-decode'
import { logError } from './logError'
import { authentication } from './authentication'
import { APP_NAME } from '../lib/environment'

export function getUserInfoFromJwt(_token: string = authentication.getToken()): {
  userId: string
  firstName: string
  lastName: string
  email: string
  paypalPayerId: string
  nativeAppName: string
  hasAcceptedReturnAndEarnTerms: string
  hasAcceptedMyTomraTerms: string
} {
  try {
    const {
      sub,
      given_name,
      family_name,
      email,
      paypal_payer_id,
      native_app_name,
      terms_and_conditions,
      re_terms_and_conditions
    } = jwtDecode<any>(_token)

    return {
      userId: sub,
      firstName: given_name,
      lastName: family_name,
      email,
      paypalPayerId: paypal_payer_id,
      nativeAppName: native_app_name,
      hasAcceptedReturnAndEarnTerms: re_terms_and_conditions,
      hasAcceptedMyTomraTerms: terms_and_conditions
    }
  } catch (error: any) {
    logError(new Error('Could not extract user info from JWT'), error)
    return {
      userId: 'Unknown',
      firstName: 'Unknown',
      lastName: 'Unknown',
      email: 'unknown@unknown.com',
      paypalPayerId: '',
      nativeAppName: '',
      hasAcceptedReturnAndEarnTerms: '',
      hasAcceptedMyTomraTerms: ''
    }
  }
}

export function getInfoFromRefreshToken(refreshToken: string) {
  try {
    const decoded = jwtDecode<any>(refreshToken)

    // Convert from seconds to milliseconds
    const expiryInMs = decoded.exp * 1000
    const refreshTokenIsValid = Date.now() <= expiryInMs

    return {
      refreshTokenIsValid,
      ...decoded
    }
  } catch (error: any) {
    logError(new Error('Could not extract info from refresh token'), error)
  }
}

export function isAnonymousUser() {
  const { email } = getUserInfoFromJwt()
  return email?.split('@')[1].indexOf('anonymous-mytomra-login') !== -1
}

export function userMustAcceptTerms() {
  const { hasAcceptedMyTomraTerms, hasAcceptedReturnAndEarnTerms } = getUserInfoFromJwt()
  const appNameToTermsKey = {
    MYTOMRA: hasAcceptedMyTomraTerms,
    RETURNANDEARN: hasAcceptedReturnAndEarnTerms
  }

  return !appNameToTermsKey[APP_NAME]
}
