import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useLoaderData, useFetcher, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Drawer, Loading } from '../../shared/common'
import {
  Common_CharityCampaign_PayoutDisclaimer,
  SetupCampaignPayout_SetAsPayoutConfirm,
  SetupCampaignPayout_PayoutIsActive,
  SetupCampaignPayout_SetAsPayout,
  Common_Cancel,
  Common_EditPayout
} from '../../../translations/messages'

const DrawerContainer = styled.div`
  > *:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
`

const ButtonContainer = styled.span`
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: var(--spacing-xs);
  }
  button:first-child {
    margin-left: var(--spacing-xs);
  }
`

interface Props {
  campaignName: string
  charityDisplayName: string
}

export const SetupCampaignPayout = ({ campaignName, charityDisplayName }: Props) => {
  const navigate = useNavigate()
  const { campaignId } = useParams()
  const { payoutMethods } = useLoaderData() as { payoutMethods: PayoutMethodsRequestType }
  const { Form, state } = useFetcher()
  const [showDrawer, setShowDrawer] = useState(false)
  const isActivePayout = payoutMethods?.some(method => method.active && method.recipient === campaignName)
  const isLoading = state === 'submitting' || state === 'loading'

  return (
    <>
      {payoutMethods && (
        <div className="animated fadeIn medium">
          {isActivePayout ? (
            <>
              <small>
                <b style={{ color: 'var(--colors-green)' }}>
                  <FormattedMessage
                    id={SetupCampaignPayout_PayoutIsActive.id}
                    defaultMessage={SetupCampaignPayout_PayoutIsActive.defaultMessage}
                    values={{ charityName: charityDisplayName }}
                  />
                </b>
              </small>

              <Button onClick={() => navigate('/payoutsettings')} style={{ marginTop: 'var(--spacing-lg)' }}>
                <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
              </Button>
            </>
          ) : (
            <Button onClick={() => setShowDrawer(true)} background="colored">
              <FormattedMessage
                id={SetupCampaignPayout_SetAsPayout.id}
                defaultMessage={SetupCampaignPayout_SetAsPayout.defaultMessage}
              />
            </Button>
          )}
        </div>
      )}

      <Drawer onClose={() => setShowDrawer(false)} visible={showDrawer}>
        <DrawerContainer>
          <h1>
            <FormattedMessage id={Common_EditPayout.id} defaultMessage={Common_EditPayout.defaultMessage} />
          </h1>

          <p>
            <FormattedMessage
              id={Common_CharityCampaign_PayoutDisclaimer.id}
              defaultMessage={Common_CharityCampaign_PayoutDisclaimer.defaultMessage}
            />
          </p>

          <p>
            <FormattedMessage
              id={SetupCampaignPayout_SetAsPayoutConfirm.id}
              defaultMessage={SetupCampaignPayout_SetAsPayoutConfirm.defaultMessage}
            />
          </p>

          <Form method="post" action="/payoutsettings">
            <input type="hidden" id={campaignId} name="recipient" value={campaignId} />

            <ButtonContainer>
              <Button onClick={() => setShowDrawer(false)} type="button" border>
                <FormattedMessage id={Common_Cancel.id} defaultMessage={Common_Cancel.defaultMessage} />
              </Button>

              <Button
                background="colored"
                aria-disabled={isLoading}
                disabled={isLoading}
                name="payoutType"
                value="DONATION"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <FormattedMessage
                    id={SetupCampaignPayout_SetAsPayout.id}
                    defaultMessage={SetupCampaignPayout_SetAsPayout.defaultMessage}
                  />
                )}
              </Button>
            </ButtonContainer>
          </Form>
        </DrawerContainer>
      </Drawer>
    </>
  )
}
