import React, { useState } from 'react'
import styled from 'styled-components'
import { useLoaderData } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Tab, TabContainer, BackButton } from '../shared'
import { VoucherSwiper } from './VoucherSwiper'
import {
  VoucherPage_Archived,
  VoucherPage_Available,
  VoucherPage_NoVouchers,
  Common_Vouchers
} from '../../translations/messages'

const Container = styled.div`
  max-width: var(--max-page-width);
  margin: 0 auto;
`

const HeaderContainer = styled.div`
  padding: 70px var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);

  > h1 {
    margin-bottom: var(--spacing-xl);
  }
`

const NoVouchersMessage = styled.h1`
  color: var(--colors-grey);
  text-align: center;
`

export const VoucherPage = () => {
  const { unredeemed, archived } = useLoaderData() as VouchersRequestType
  const [activeTab, setActiveTab] = useState<'unredeemed' | 'archived'>('unredeemed')
  const hasNoVouchers = activeTab === 'archived' ? archived.length === 0 : unredeemed.length === 0

  return (
    <Container className="animated fadeIn medium">
      <BackButton to="/" />

      <HeaderContainer>
        <h1>
          <FormattedMessage id={Common_Vouchers.id} defaultMessage={Common_Vouchers.defaultMessage} />
        </h1>

        <TabContainer>
          <Tab onClick={() => setActiveTab('unredeemed')} active={activeTab === 'unredeemed'}>
            <FormattedMessage
              id={VoucherPage_Available.id}
              defaultMessage={VoucherPage_Available.defaultMessage}
              values={{ numOfVouchers: unredeemed.length }}
            />
          </Tab>
          <Tab onClick={() => setActiveTab('archived')} active={activeTab === 'archived'}>
            <FormattedMessage
              id={VoucherPage_Archived.id}
              defaultMessage={VoucherPage_Archived.defaultMessage}
              values={{ numOfVouchers: archived?.length }}
            />
          </Tab>
        </TabContainer>
      </HeaderContainer>

      {hasNoVouchers ? (
        <NoVouchersMessage className="centerAbsolute">
          <FormattedMessage id={VoucherPage_NoVouchers.id} defaultMessage={VoucherPage_NoVouchers.defaultMessage} />
        </NoVouchersMessage>
      ) : (
        <VoucherSwiper vouchers={activeTab === 'archived' ? archived : unredeemed} />
      )}
    </Container>
  )
}
